import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader} from '../../../elements';
import API from '../../../config/API';
import {setls, getls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	},
	removeBtn: {
	    position: 'absolute',
		background: 'red',
		color: '#fff',
		borderRadius: '50px',
		padding: '0px 4px',
		fontSize: '11px',
		border: '1px solid black',
	}
}

class EditGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnUpload: 'Upload',
			showFileBrowser: false,
			fileList: [],
			fileView: [],
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleBtnClick = this.handleBtnClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.setState({fileList: this.props.editData});
	}
	
	handleFileBrowserClick(files){
		try{
			var d = this.state.fileList;
			files.map((item,i)=>{
				var url = item.data.path +'/'+ item.data.name;
				url= url.replace(/\/\//g, "/");
				d.push({
					url: Api.ImageUploadPath+ url,
					filepath: url,
					name: item.data.name,
					status: '1'					
				});
			});
			this.setState({fileList: d});
			
			/*var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			var d = this.state.fileList;
			d.push({
				url: Api.ImageUploadPath+ url,
				filepath: url,
				name: files[0].data.name,
				status: '1'
			});
			this.setState({fileList: d});*/
		}
		catch(error){ }
	}
	
	handleRemoveImageClick(index){
		try{			
			var d = this.state.fileList;
			d[index]['status'] = '0';
			this.setState({fileList: d});
		}
		catch(error){ }
	}
	
	handleBtnClick(){
		this.setState({showFileBrowser: true});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(Object.keys(this.state.fileList).length<1) {
				notify.show('Please select Images to update Gallery.!');
				return;
			}
			this.setState({showLoader: true, btnUpload: 'Uploading..'});
			API.post('v1/gallery/update',$("#frm_my_gal_add").serialize()).then(res=>{
				if(res['data'].status=='1') {
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnUpload: 'Upload'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else {
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnUpload: 'Upload'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnUpload: 'Upload'});
		}
	}
	
	getField(fieldName){
		try{			
			var d = this.props.editData;			
			return d[0][fieldName];
		}
		catch(error){ }
	}
	
	
	updateTitle(index,e){
		try{			
			var d = this.state.fileList;
			d[index]['filetitle'] = e.target.value;
			this.setState({fileList: d});			
		}
		catch(error){ }
	}
	
	updateImageOrder(index,e){
		try{			
			var d = this.state.fileList;
			d[index]['displayorder'] = e.target.value;
			this.setState({fileList: d});			
		}
		catch(error){ }
	}
	
	getFieldValue(fieldName, index){
		try{
			var d = this.state.fileList;
			return d[index][fieldName];
		}
		catch(error){ }
	}
	
	render() {
		
	let sno = 1;
	
	  return (
		<div>
		
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<form action="" method="post" encType="multipart/form-data" id="frm_my_gal_add" onSubmit={this.handleFormSubmit} >
				
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__refKey" value={this.getField('refkey')} />
				<input type="hidden" name="i__files" value={JSON.stringify(this.state.fileList)} />
				
				<Row>
					<Col md={9} >
						<Label>Gallery Title <span className="required">*</span> </Label>
						<Input type="text" name="i__title" size="sm" required defaultValue={this.getField('gallerytitle')} />
					</Col>
					<Col md={3} >
						<Label>Display Order<span className="required"></span> </Label>
						<Input type="number" name="i__galleryOrder" size="sm" defaultValue={this.getField('glleryorder')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<Label>Gallery Images</Label>
						<Button type="button" size="sm" color="info" onClick={this.handleBtnClick}  >
							Select Images
						</Button>
					</Col>
				</Row>
				
				{/*<Row className="mt-10" style={{maxHeight: 'calc(100vh - 200)' }} >
					{this.state.fileList.map((item,i)=>{
						return item.status=='1' ? <Col md={3} className="mt-10"  >
								<a href="javascript:;" style={styles.removeBtn} onClick={this.handleRemoveImageClick.bind(this,i)} >
									<i className="ion-android-close"></i>
								</a>
							<img src={Api.ImageUploadPath + item.filepath} style={{maxHeight: '150px', border:'1px solid grey'}}/>
						</Col> : null;
					})
					}
				</Row>*/}
				
				<Row className="mt-10" style={{height: 'calc(100vh - 260px)', overflowY: 'scroll' }} >
					<Col md={12} >
						<Table width="100%" className="tbl" >
							<thead >
								<tr>
									<th width="80">S.No</th>
									<th>Image</th>
									<th>Title</th>
									<th>Order</th>
									<th>Manage</th>
								</tr>
							</thead>
							<tbody>
							{this.state.fileList.map((item,i)=>{
								return item.status=='1' ? <tr>
									<td align="centeR" >{sno++}</td>
									<td>
										{/*<a href="javascript:;" style={styles.removeBtn} >
												<i className="ion-android-close"></i>
										</a>*/}
										<img src={Api.ImageUploadPath + item.filepath} style={{maxHeight: '150px', border:'1px solid grey'}}/>
									</td>
									<td align="center"  >
										<Input type="textarea" rows="5" onChange={this.updateTitle.bind(this,i)} value={this.getFieldValue('filetitle', i)} />
									</td>
									<td>
										<Input type="number" style={{width: '100px'}} onChange={this.updateImageOrder.bind(this,i)}   value={this.getFieldValue('displayorder', i)}  />
									</td>
									<td align="center" >
										<Button type="button" size="sm" color="danger"  onClick={this.handleRemoveImageClick.bind(this,i)}>
											<i className="ion-android-close"></i>
										</Button>
									</td>
								</tr> : null;
							})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={12} >
						<hr />
						<div className="text-center" >
							<Button type="submit" color="success" size="sm " disable={this.state.btnUpload!='Upload'} >
								{this.state.btnUpload=='Upload' ? '' : 
									<span className="ion-loading-a" ></span>} &nbsp;
								{this.state.btnUpload}
							</Button>
						</div>
					</Col>
				</Row>
				
			</form>		
			
			<ModalFileBrowser isOpen={this.state.showFileBrowser} imageOnly multiple  
				atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}}
				fileBrowserClick={this.handleFileBrowserClick} >
					
			</ModalFileBrowser>		
			
		</div>
	  );
	}

}

export default EditGallery;
