import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, numberWithCommas} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

const styles = {
	thStyle:{
		fontSize: '12px',
		borderBottom: '1px solid black',
		borderCollapse: 'collapse',
		paddingTop: '5px',
		paddingBottom: '5px',
		color: 'black',
	},
	thAmountStyle:{
		fontSize: '12px',
		borderBottom: '1px solid black',
		borderCollapse: 'collapse',
		paddingTop: '5px',
		paddingBottom: '5px',
		color: 'black',
		textAlign: 'right',
	},
	tdStyle:{
		fontSize:'12px',
		paddingTop:'5px',
		paddingBottom:'5px',
		color: 'black',
		borderBottom: '1px dashed grey',
		borderCollapse: 'collapse',
	},
	dtStyle: {
		float: 'right',
		color: 'black',
	}
}

class YearlyReportA4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
		
	}
	
	componentDidMount(){
		var d = groupByMultiple(this.props.reportData,function(obj){
			return[obj.catid];
		});
		this.setState({dataList: d});
	}
	
	getYearBySem(sem){
		try{
			if(sem=='1' || sem=='2') return '1';
			else if(sem=='3' || sem=='4') return '2';
			else if(sem=='5' || sem=='6') return '3';
			else return '';
		}
		catch(error){ return '';}
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	getCategoryData(){
		try{
			var d = [];
			d = groupByMultiple(this.props.reportData,function(obj){
				return[obj.catid];
			});
			return d;
		}
		catch(error){
			return [];
		}
	}
	
	calculateTotal(items){
		try{
			var t = 0;
			items.map((item,i)=>{
				t += parseFloat(item.amount);
			});
			return (parseFloat(t).toFixed(0));
		}
		catch(error){
			return '0';
		}
	}
	
	getGroupByDate(items){
		try{
			var d = groupByMultiple(items, function(obj){
				return[obj.vcyear, obj.vcmonth];
			});
			return d;
		}
		catch(error){
			return [];
		}
	}
	
	loadInnerTd(items){
		var rv = [];
		try{
			
			items.map((item,i)=>{
				rv.push(<tr key={i} >
					<td style={styles.tdStyle} ></td>
						{/*<td style={styles.tdStyle} width="120" align="left"  >{momentDate(item[0].voucherdate,'DD-MM-YYYY')}</td>*/}
					<td style={styles.tdStyle} width="120" align="left"  >{this.getMonthName(item[0].vcmonth)}</td>
					<td align="left" style={styles.tdStyle}  width="120"  >{Object.keys(item).length}</td>
					<td align="right" style={styles.tdStyle} width="120"  >{this.calculateInnerTdTotal(item)}</td>
				</tr>);
			});
			return rv;
		}
		catch(error){
			return rv;
		}
	}
	
	calculateInnerTdTotal(items){
		try{
			var t = 0;
			items.map((item,i)=>{
				t += parseFloat(item.amount);
			});
			return numberWithCommas(parseFloat(t).toFixed(0));
		}
		catch(error){
			return '0';
		}
	}
	
	getMonthName(monthId){
		try{
			var mList = [];
			mList.push({1 : 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'});
			
			return mList[0][monthId];
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
		var logo = getls('company','logo');
		let sno = 1;
		
		let grandTotal = 0;
		
	  return (
	  <div>
				
			<page className="A" >
			<div  id="frm_ag_Feecat_DayReportPrint" >
				<table width="98%" align="center" style={{borderCollpase:'collapse'}} >
					<tbody style={{borderCollpase:'collapse'}} >
						<tr style={{ borderCollpase:'collapse'}} >
							<td colSpan="4" align="center" style={{borderBottom:'1px solid black', borderCollpase:'collapse'}}  >
									{logo.length > 3 ? <div style={{position:'absolute', marginLeft: '30px', marginTop: '5px'}} >
									<img src={logo} style={{maxHeight:'75px'}} />
									</div> : null}
								<div style={{marginTop:'5px', fontWeight:'800', fontSize:'17px'}} >{upperCase(getls('company','companyname'))}, {upperCase(getls('company','city'))}</div>
								<div style={{fontSize:'14px', fontWeight:'600', margin:'2px'}} >(Autonomous)</div>
								<div style={{marginBottom:'0px'}} >(Under the Management of H.R & C.E Dept.)</div>
								<div style={{fontSize: '13px', marginTop: '5px', marginBottom: '5px' }} >YEARLY REPORT - {upperCase(getls('currentacbusiness','businessname'))} - {this.props.reportType} - {getls('currentacyear','value')}
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<table width="90%" style={{borderCollapse: 'collapse'}} align="center" >
									<thead style={{borderCollapse: 'collapse'}}  >
										<tr style={{borderCollapse: 'collapse'}}  >
											<th style={styles.thStyle} align="left" >Collect For</th>
											<th style={styles.thStyle} align="left" >Date</th>
											<th style={styles.thStyle}  align="left" >No.of.Bill</th>
											<th style={styles.thAmountStyle}  align="right" >Amount</th>
										</tr>
									</thead>
									<tbody >
									{this.state.dataList.map((item,i)=>{
										var inTd = this.getGroupByDate(item);
										return <React.Fragment> <tr key={i} >
											<td colSpan="4" align="left" style={{fontSize:'13px', fontWeight:'600', marginTop:'1px solid black'}} >{item[0].partyname}</td>
										</tr>
										{this.loadInnerTd(inTd)}
										</React.Fragment> ;
									})
									}
									{this.props.showCatDetails ? null : <tr>
										{this.getCategoryData().map((item,i)=>{
											 var total = this.calculateTotal(item);
											 grandTotal += parseFloat(total);
										})
										}
										<td colSpan="3" align="right" > Total : </td>
										<td style={{fontWeight:'600',  borderBottom: '1px solid black', fontSize:'15px'}}  align="right" >{numberWithCommas(parseFloat(grandTotal).toFixed(2))}</td>
									</tr>}
									</tbody>
								</table>
							</td>
						</tr>	
						{this.props.showCatDetails ? <tr>
							<td>
								<br />
								<table width="90%" align="center" style={{borderCollapse: 'collapse', border: '1px solid black'}} >
									<tbody>
										<tr>
											<td colSpan="3" align="center" style={{fontWeight:'600', borderCollapse: 'collapse', border: '1px solid black'}} > All Details</td>
										</tr>
										{this.getCategoryData().map((item,i)=>{
											 var total = this.calculateTotal(item);
											 grandTotal += parseFloat(total);
											return  <tr key={i} >
												<td style={{paddingLeft:'20px'}} height="40" >{item[0].partyname}</td>
												<td align="center" width="100"> - </td>
												<td width="200" align="right" style={{paddingRight:'50px'}} >{numberWithCommas(parseFloat(total).toFixed(0))}</td>
											</tr>;
										})
										}
										<tr>
											<td align="right">Total</td>
											<td align="center" >: </td>
											<td align="right" style={{fontWeight:'600', paddingRight:'50px', borderTop: '1px solid black', fontSize:'15px'}} >{numberWithCommas(parseFloat(grandTotal).toFixed(2))}</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr> : null}
					</tbody>
				</table>
				
			</div>
			</page>
			
			{this.props.autoPrint ? null : <div className="print_fixed_options" >
				<ul>
					<li>
						<Button type="button" size="lg" title="Print" onClick={this.printDocument.bind(this,'frm_ag_Feecat_DayReportPrint')} >
							<span className="ion-printer"></span>
						</Button>
					</li>
				</ul>
			</div>}
				
		</div>
	  );
	}

}

export default YearlyReportA4;
