import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls} from '../../../config';
import {BlockLoader, LineLoader} from '../../../elements';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';
import UPLOAD_IMG from '../img/upload.png';
import PDF_IMG from '../img/pdf.png';

import NO_IMAGE from '../../../assets/images/user_200.png';

const styles = {
	imgStyle: {
		border: '1px solid grey',
		padding: '10px',
	},
	removeIcon: {
	    position: 'absolute',
		background: '#da1010',
		color: '#fff',
		border: '1px solid black',
		fontWeight: '800',
		right: '16px',
	}
}


class AddStaff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save',
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			selectedFileUrl: '',
			showFileBrowser: false,
			showResumeBrowser: false,
			showAddModal: false,
			deptList: [],
			i__startYear: '',
			i__endYear: '',
			resumeFile: '',
			
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleResumeClick = this.handleResumeClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
	}
	
	componentDidMount(){
		this.loadDepartments();
	}
	
	loadDepartments(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/course/departments/all').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({deptList: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			console.log(files);
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				selectedFileUrl: files[0].path
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleResumeClick(files){
		try{
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				resumeFile: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}

	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/course/staffs/save', $("#frmAddStaff").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddStaff").reset();
					notify.show(res['data'].message,'success');
					this.setState({btnText: 'Save', selectedFile: '', selectedFileUrl: '', selectedUrl: '', showLoader: false});
					//this.loadFileList();
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save', showLoader: false});
					
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false, btnText: 'Save'});
		}
	}
	
	handleYearChange(e){
		this.setState({[e.target.name] : e.target.value});
	}
	
	render(){
		

		
	  return (
		<div>
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<form action="" method="post" encType="multipart/form-data" id="frmAddStaff" onSubmit={this.handleFormSubmit}  >
			
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
				<input type="hidden" name="i__resume" value={this.state.resumeFile} />
				
				<Row>
					<Col md={6} >
						<Row>
							<Col md={3} >
								<Label>Emp.Code <span className="text-danger">*</span></Label>
							</Col>
							<Col md={9} >
								<Input type="text" name="i__empCode" size="sm" required />
							</Col>
						</Row>

						<Row className="mt-10" >
							<Col md={3} >
								<Label>Staff Name<span className="text-danger">*</span></Label>
							</Col>
							<Col md={9} >
								<Input type="text" name="i__empName" size="sm" required />
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Gender<span className="text-danger">*</span></Label>
							</Col>
							<Col md={9} >
								<Input type="select" name="i__gender" size="sm" required >
									<option value="male" > Male </option>
									<option value="female" > Female </option>
								</Input>
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Date of Birth<span className="text-danger"></span></Label>
							</Col>
							<Col md={9} >
								<Input type="date" name="i__dob" size="sm" />
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Qualification<span className="text-danger"></span></Label>
							</Col>
							<Col md={9} >
								<Input type="text" name="i__qualification" size="sm" />
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Department <span className="text-danger"></span></Label>
							</Col>
							<Col md={9} >
								<Input type="select" name="i__department" size="sm"  >
									<option value=""> -Select- </option>
									{this.state.deptList.map((item,i)=>{
										return <option value={item.id} key={i} > {item.departmentname} </option>;
									})										
									}
								</Input>
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Designation<span className="text-danger"></span></Label>
							</Col>
							<Col md={9} >
								<Input type="text" name="i__designation" size="sm" />
							</Col>
						</Row>	
						
						<Row className="mt-10" >
							<Col md={3} >
								<Label>Emp.Type<span className="text-danger">*</span></Label>
							</Col>
							<Col md={9} >
								<Input type="select" name="i__empType" size="sm" required >
									<option value="aided" > Aided </option>
									<option value="un-aided" > Un-Aided </option>
									<option value="management" > Management </option>
								</Input>
							</Col>
						</Row>	
						
					</Col>
					
					<Col md={6} >
						<Row>
							<Col md={8} >
								<Row className="" >
									<Col md={3} >
										<Label>Mobile<span className="text-danger"></span></Label>
									</Col>
									<Col md={9} >
										<Input type="number" name="i__mobile" size="sm" />
									</Col>
								</Row>	
								<Row className="mt-10" >
									<Col md={3} >
										<Label>Phone<span className="text-danger"></span></Label>
									</Col>
									<Col md={9} >
										<Input type="text" name="i__phone" size="sm" />
									</Col>
								</Row>	
								<Row className="mt-10" >
									<Col md={3} >
										<Label>Email<span className="text-danger"></span></Label>
									</Col>
									<Col md={9} >
										<Input type="email" name="i__email" size="sm" />
									</Col>
								</Row>	
								<Row className="mt-10" >
									<Col md={3} >
										<Label>Address<span className="text-danger"></span></Label>
									</Col>
									<Col md={9} >
										<Input type="textarea" name="i__address" rows="5" size="sm" />
									</Col>
								</Row>	
								<Row className="mt-10" >
									<Col md={3} >
										<Label>City<span className="text-danger"></span></Label>
									</Col>
									<Col md={9} >
										<Input type="text" name="i__city" size="sm" />
									</Col>
								</Row>	
								<Row className="mt-10" >
									<Col md={3} >
										<Label>Order<span className="text-danger"></span></Label>
									</Col>
									<Col md={4} >
										<Input type="number" name="i__order" size="sm" />
									</Col>
									<Col md={5} >
										<div className="pretty p-icon p-smooth" style={{marginTop: '8px'}} >
										<input type="checkbox" name="i__showInSite" />
											<div className="state p-primary">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label> Show in site</label>
											</div>
										</div>
									</Col>
								</Row>	
							</Col>
							
							<Col md={4} >
								<Row>
									<Col md={12} >
										<ModalFileBrowser isOpen={this.state.showFileBrowser} imageOnly  
											atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}}
											fileBrowserClick={this.handleFileBrowserClick} >
								
												<img src={this.state.selectedFileUrl!='' && this.state.selectedFileUrl!=undefined ? this.state.selectedFileUrl : NO_IMAGE} style={styles.imgStyle}   onClick={()=>{this.setState({showFileBrowser : true})}} />
												
												{this.state.selectedUrl!='' && this.state.selectedUrl!=undefined ? 
												<button type="button" style={styles.removeIcon} onClick={()=>{this.setState({selectedUrl:'', selectedFileUrl: ''})}} >X</button>
												: null}
												
										</ModalFileBrowser>
									</Col>
								</Row>
								
								<Row>
									<Col md={12} className="text-center" >
										<Label>Resume</Label>
										<ModalFileBrowser isOpen={this.state.showResumeBrowser}  
											atClose={()=>{this.setState({showResumeBrowser: !this.state.showResumeBrowser})}}
											fileBrowserClick={this.handleResumeClick} >
								
												<img src={this.state.resumeFile!='' && this.state.resumeFile!=undefined ? PDF_IMG : UPLOAD_IMG} style={styles.imgStyle}   onClick={()=>{this.setState({showResumeBrowser : true})}} />
												
												{this.state.resumeFile!='' && this.state.resumeFile!=undefined ? 
												<button type="button" style={styles.removeIcon} onClick={()=>{this.setState({resumeFile:''})}} >X</button>
												: null}
												
												{this.state.resumeFile!='' && this.state.resumeFile!=undefined ? <a href={Api.ImageUploadPath + this.state.resumeFile} target="_blank">View</a> : null}
												
										</ModalFileBrowser>
									</Col>
								</Row>
							
							</Col>
							
						</Row>						
					</Col>				
				</Row>
				
				
				<Row className="mt-15" >
					<Col md={12} > <hr /> </Col>
					<Col md={6} >
						<div className="pretty p-icon p-smooth" >
							<input type="checkbox" name="i__active" />
								<div className="state p-primary">
									<i className="icon ion-ios7-checkmark-empty"></i>
									<label> &nbsp;&nbsp;Active</label>
								</div>
							</div>
					</Col>
					<Col md={6} >
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnText!='Save'} >
								<i className="ion-android-close" /> &nbsp; Reset
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
								{this.state.btnText=='Save' ? <i className="ion-android-checkmark" /> : <i className="ion-loading-a" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			
			</form>	
		</div>
	  );
	}

}

export default AddStaff;
