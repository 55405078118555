import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api } from '../../../variables';
import API from '../../../config/API';
import axios from 'axios';
import { setls, upperCase, capitalizeFirst, groupByMultiple, getls } from '../../../config';
import Select from 'react-select';
import { Container, Button as FloatButton, Link } from 'react-floating-action-button';

class EditExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveText: 'Update',
            id: getls('user', 'empid'),
            editData: this.props.editData
        }
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
    }

    handleModalSubmit(e) {
        try {
            e.preventDefault();
            this.setState({ showLoader: true, saveText: 'Updatng' });
            axios.post(Api.AcademicPath + 'staff/updateexperience', $("#frmExEditModal").serialize()).then(response => {
                if (response['data'].status == '1') {
                    this.setState({ showLoader: false, saveText: 'Update' });
                    document.getElementById('frmExEditModal').reset();
                    notify.show(response['data'].msg, 'success');
                    this.props.afterFinish();
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    getValue(field) {
        var d = this.state.editData;
        return d[field];
    }

    setValue(field, e) {
        var d = this.state.editData;
        d[field] = e.target.value;
        this.setState({ editData: d });
    }

    render() {


        let getYear = [];
        var today = new Date();
        var curyear = today.getFullYear();
        for (var i = 1980; i <= curyear; i++) {
            getYear.push(<option value={i}>{i}</option>);
        }

        return (
            <div>
                <form action="" method="post" encType="multipart/form-data" id="frmExEditModal" onSubmit={this.handleModalSubmit} >
                    <input type="hidden" name="id" value={this.getValue('id')} />
                    <input type="hidden" name="empid" value={this.state.id} />
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>College</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="college" value={this.getValue('college')} onChange={this.setValue.bind(this, 'college')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Category</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="category" value={this.getValue('category')} onChange={this.setValue.bind(this, 'category')} required>
                                    <option value="">Category</option>
                                    <option value="Aided">Aided</option>
                                    <option value="SF">SF</option>
                                    <option value="Govt">Govt</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Designation</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="designation" value={this.getValue('designation')} onChange={this.setValue.bind(this, 'designation')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Join Date</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="date" size="sm" name="joindate" value={this.getValue('joindate')} onChange={this.setValue.bind(this, 'joindate')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Relieving Date</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="date" size="sm" name="relievingdate" value={this.getValue('relievingdate')} onChange={this.setValue.bind(this, 'relievingdate')} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Experience</Label>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Input type="number" size="sm" name="years" placeholder="Years" value={this.getValue('years')} onChange={this.setValue.bind(this, 'years')} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Input type="number" size="sm" name="months" placeholder="Months" value={this.getValue('months')} onChange={this.setValue.bind(this, 'months')} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Input type="number" size="sm" name="days" placeholder="Days" value={this.getValue('days')} onChange={this.setValue.bind(this, 'days')} />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="float-right">
                                    <Button type="button" color="danger" onClick={this.props.onClose}>
                                        Cancel
                                    </Button>&nbsp;
                                    <Button type="submit" color="primary" disabled={this.state.saveText != 'Update' ? true : false}>
                                        {this.state.saveText != 'Update' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div >);
    }

}

export default EditExperience;
