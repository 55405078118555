import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {ClientName, ClientPlace} from '../../../variables';
import {capitalizeFirst, upperCase, yesorNo, isNullOrEmpty} from '../../../config';
import AVATAR_MALE from '../../../assets/images/avatar_male.png';
import AVATAR_FEMALE from '../../../assets/images/avatar_female.png';

const styles={
	trStyle:{
		borderCollapse: 'collapse',
		border: '1px solid #a5a5a5',
		fontSize: '10px',
	},
	tdStyle:{
		borderCollapse: 'collapse',
		border: '1px solid #a5a5a5',
		fontSize: '10px',
		padding:'3px 1px',
	},

}

class PrintBasic extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	getValue(field){
		try{
			
			var d = this.props.students;
			return d[field];
		}
		catch(error){
			console.log(error);
			return '';
		}
	}
	
	loadMultiFieldColum(values,fields){
		try{
			var d = '';
			
			fields.child.map((item,i)=>{
				if(isNullOrEmpty(values[item]))
					d += upperCase(values[item])+', ';
			});
			
			return d;
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
	let sno = 1;
		
	/*let PHOTO = this.props.student['gender'].toLowerCase()=='male' ? AVATAR_MALE : AVATAR_FEMALE;

	var userImage = this.getValue('profile_photo');
	if(userImage!='' && userImage!=null && userImage.length>4)
		PHOTO = userImage;*/
	
	  return (
		<div style={{display: this.props.show ? 'block' : 'none'}} >
		<Row>
			<Col md={12} >
				<div id={this.props.printId} >
					<table width="100%" align="center" id={this.props.tableId} >
						<tr>
							<td align="center" style={{fontSize:'14px',fontWeight:'600'}} >{ClientName}</td>
						</tr>
						<tr>
							<td align="center" style={{fontSize:'11px'}}  >{ClientPlace}</td>
						</tr>
						<tr> <td height="20"></td> </tr>
						<tr>
							<td style={{fontSize:'11px'}} >{this.props.header}</td>
						</tr>
						<tr>
						<td>
							<table className="tbl_print" width="100%" style={styles.trStyle}  >
								<thead>
									<tr style={{borderCollapse:'collapse'}} >
										<th style={styles.trStyle} >S.No</th>
										{this.props.columns.map((column,key)=>{
											return <th style={styles.trStyle}  >{column.value}</th>;
										})									
										}
									</tr>
								</thead>
								<tbody>
								{this.props.dataList.map((row,key)=>{
									return <tr key={key} style={{borderCollapse:'collapse'}}  >
										<td style={styles.tdStyle}  >{sno++}</td>
										{this.props.columns.map((column,i)=>{
											return column.child ? 
												<td style={styles.tdStyle}  >{this.loadMultiFieldColum(row,column)}</td> : 
												<td style={styles.tdStyle}  >{upperCase(row[column.field])}</td>;
										})}
									</tr>;
								})							
								}
								</tbody>
							</table>
						</td>
					</tr>
				</table>
				</div>
			</Col>
		</Row>
		</div>
	  );
	}

}

export default PrintBasic;
