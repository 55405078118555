import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {InputMask} from 'primereact/inputmask';

import Menu from './menu';
import DayReportA4 from './DayReportA4';

class BreakageDaily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
			toDate: '',
			fromDate: '',
		}
		this.loadVouchers = this.loadVouchers.bind(this);
	}
	
	componentDidMount() {
		var today = new Date();
		//var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
		this.setState({fromDate: momentDate(today,'DD/MM/YYYY'), toDate: momentDate(today,'DD/MM/YYYY')});
		//this.loadCategory();
	}
	
	loadVouchers(){
		try{
			
			this.setState({showLoader:true, dataList: [], dataView: []});
			var form = new FormData();
			form.append('type', 'payment');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('fromdate', this.state.fromDate);
			form.append('todate', this.state.fromDate);
			form.append('catidin', getls('currentacbusiness','breakageid'));
			
			API.post('v1/fees/vouchers', form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	render(){
		
		var logo = getls('company','logo');
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Row>
					<Col md={3}  >
						<div className="input-group">
							<span className="input-group-addon" >
								Date
							</span>
							<InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="dd/mm/yyyy"  onChange={(e)=>{this.setState({fromDate: e.value})}} className="form-control form-control-sm" value={this.state.fromDate} />
						</div>
					</Col>
					<Col md={2} >
						<Button type="button" size="sm" color="primary" onClick={this.loadVouchers} >
							VIEW
						</Button>
					</Col>
					<Col md={12}> <hr /></Col>
				</Row>
				
				<Row className="mt-20" >
					<Col md={12} >
						{Object.keys(this.state.dataList).length> 0 ? 
							<DayReportA4  reportDate={this.state.fromDate} reportData={this.state.dataList} reportType="BREAKAGE FEES" showCatDetails={false} /> 
						: null}
					</Col>
				</Row>
				
			</div>
			
			
		</div>
	  );
	}

}

export default BreakageDaily;
