import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version} from '../../../../variables';
import API from '../../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../../config';

import Add from './add';
import Edit from './edit';
import Print from './print';

const styles = {
	tdStyle: {
		borderBottom: '1px solid #f3f3f3',
		padding: '8px',
		cursor: 'pointer',
	}
}

class Oldstudentsattendancecertificate extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showAddModal: false,
		  showEditModal: false,
		  showPrint: false,
		  btnText: 'Save Template',
		  dataList: [],
		  dataView: [],
		  editData: [],
		  currentPage: 1,
		  todosPerPage: 20,
		  activePage : 1,
		}
		this.loadTemplae = this.loadTemplae.bind(this);
		this.handleNewClick = this.handleNewClick.bind(this);	
		this.handleAtClose = this.handleAtClose.bind(this);
	}
	
	componentDidMount(){		
		this.loadTemplae();
	}
	
	loadTemplae(){
		try{
			this.setState({showLoader:true, dataList:[], dataView: []});
			API.get('students/listoldattendance/').then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					
					this.setState({dataView: d, dataList: d, showLoader: false});					
				}
				else
				{
					this.setState({showLoader:false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleTrClick(val){
		try{
			console.log(this.props.msgBox);
			document.getElementById(this.props.msgBox).value = val;
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleNewClick(){
		this.setState({
			showAddModal: true
		});
	}
	
	handleAtClose(){
		this.setState({
			showAddModal: false
		});
	}
	
	handleRemove(id,index){
		try{
			
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to remove certificate?',
				buttons: {
					Yes: function(){
						$this.removeData(id,index);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	removeData(id,index){
		try{
			this.setState({showLoader:true});
			API.get('students/removeattcertificate/'+id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){ 
						return (obj.id==id);
					});
					d.splice(index,1);
					notify.show(res['data'].msg,'success');
					this.setState({dataList:d, dataView:d, showLoader:false});
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({showLoader:false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleEditClick(id,e){
		try{
			var d = this.state.dataList.filter(function(obj){
				return (obj.id==id);
			});
			this.setState({editData:d[0], showEditModal: true});
		}
		catch(error){
			
		}
	}
	
	handlePrintClick(id,e){
		try{
			var d = this.state.dataList.filter(function(obj){
				return (obj.id==id);
			});
			this.setState({editData:d[0], showPrint: true});
		}
		catch(error){
			
		}
	}
	
	handleSearch(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return ((upperCase(obj.registerno).indexOf(v)>-1) ||
						(upperCase(obj.name).indexOf(v)>-1) || 
						(upperCase(obj.mobile).indexOf(v)>-1));
			});
			this.setState({dataView:d});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		let sno=1;
		
		// Logic for displaying current todos
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
	  
			<Card>
				<CardBody >
					<CardTitle>Certificates
						<div className="text-right" style={{marginTop: '-25px' }} >
							<Button size="sm" color="primary" onClick={this.handleNewClick} >
								<span className="ion-plus"></span> New Certificate
							</Button>
							&nbsp;
							<Button size="sm" color="info" title="Refresh" onClick={this.loadTemplae} >
								<span className="ion-refresh"></span>
							</Button>
						</div>
					</CardTitle>
					<hr style={{marginBottom: '0px'}} />
				</CardBody >
				<CardBody style={{ paddingTop:'0px'}}  >
						<Row>
							<Col md={12} >
								<div className="input-group" >
									<span className="input-group-addon" >
										<span className="ion-search"></span>
									</span>
									<Input type="text" placeholder="Search by Name, Register No or Mobile.." size="sm" onChange={this.handleSearch.bind(this)} />
								</div>
							</Col>
						</Row>
						<Row className="mt-10" >
								<Col md={12} >
									<Table className="tbl" >
										<thead>
											<tr>
												<th>S.No</th>
												<th>Mobile</th>
												<th>Register No</th>
												<th>Name</th>
												<th>Course</th>
												<th>Total Days</th>
												<th>Days Atten.</th>
												<th>(%)</th>
												<th width="120" >Manage</th>
											</tr>
										</thead>
										<tbody>
										{currentTodos.map((item,i) => {
											return <tr key={i} className={item.activestatus=='0' ? "bg-red-light-5" : ""} >
												<td>{indexOfFirstTodo+i+1}</td>
												<td>{item.mobile}</td>
												<td>{item.registerno}</td>
												<td>{item.name}</td>
												<td>{item.classname}</td>
												<td>{item.totalworkingday}</td>
												<td>{item.attday}</td>
												<td>{item.percent}</td>
												<td align="center" >
													<Button size="sm" color="warning" title="Print" onClick={this.handlePrintClick.bind(this,item.id)} >
														<span className="ion-printer"></span>
													</Button>
													&nbsp;
													<Button size="sm" color="info" title="Edit" onClick={this.handleEditClick.bind(this,item.id)} >
														<span className="ion-edit"></span>
													</Button>
													&nbsp;
													<Button size="sm" color="danger" title="Delete" onClick={this.handleRemove.bind(this,item.id,i)} >
														<span className="ion-close"></span>
													</Button>
												</td>
											</tr>;
										})
										}										
										</tbody>
									</Table>
								</Col>			
							</Row>
							
							{this.state.showLoader ? <Loader type="dot" /> : null}	
							
							{Object.keys(this.state.dataList).length<1 ? 
							<Row>
								<Col md={12} >
									<div className="text-center bg-gradient-info h-100p pa-15 text-white" >
										<br />
										<h5>Certicates not found.! click the New Certicate button to create new one.!</h5>
										<Button size="sm" color="secondary" onClick={this.handleNewClick} >
											<span className="ion-plus"></span> New Certificate
										</Button>
									</div>
								</Col>
							</Row> : null}
				</CardBody>
			</Card>
			
			
			<Modal isOpen={this.state.showAddModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal:false})}} >
					<span className="ion-document"></span> &nbsp;&nbsp; New Attendance Certificate
				</ModalHeader>
				<ModalBody>
					<Add atClose={this.handleAtClose} afterFinish={this.loadTemplae} />
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditModal:false})}} >
					<span className="ion-document"></span> &nbsp;&nbsp; Edit Attendance Certificate
				</ModalHeader>
				<ModalBody>
					<Edit atClose={this.handleAtClose} editData={this.state.editData}  afterFinish={this.loadTemplae} />
				</ModalBody>
			</Modal>
			
			{this.state.showPrint ? <div style={{display: 'none'}} >
				<Print printData={this.state.editData} autoPrint={true} atClose={()=>{this.setState({showPrint: false})}}  />
			</div> : null}
			
		</div>
	  );
	}

}

export default Oldstudentsattendancecertificate;
