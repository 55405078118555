import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Progress } from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../config';

import FileBrowser from './';

class ModalFileBrowser extends Component {
	constructor(props) {
		super(props);
		this.state = {
		 
		}
		
		this.fileBrowserClick = this.fileBrowserClick.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	fileBrowserClick(files){
		try{
			
			if(this.props.fileBrowserClick)
			{
				this.props.fileBrowserClick(files);
				this.props.atClose();
			}
		}
		catch(error){
			
		}
	}
	
	render(){
		
	const FILES = this.props.files ? this.props.files : '';
		
	  return (
	  <div >
		
		{this.props.children}
		
		<Modal isOpen={this.props.isOpen} size="large" backdrop={true} >
			<ModalHeader toggle={this.props.atClose} >
				File Browser
			</ModalHeader>
			<ModalBody style={{padding:'0px'}} >
				
				<FileBrowser fileBrowserClick={this.fileBrowserClick} multiple={this.props.multiple} files={FILES} imageOnly={this.props.imageOnly} />
				
			</ModalBody>
		</Modal>
		
		</div>
	  );
	}

}

export default ModalFileBrowser;
