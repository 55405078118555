import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../config';
import {Checkbox } from 'evergreen-ui';

class AddCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		  showEditModal: false,
		  btnText: 'Save',
		}
	}
	
	componentDidMount(){
	
	
	}
	
	render(){
		
		
	  return (
	  <div>
		<form action="" method="post" encType="multipart/form-data" id="frm_addPageCategory" >	
			<Row>
				<Col md={12} >
					<Label>Category Name <span className="required">*</span></Label>
					<Input type="text" name="i__category" size="sm" required placeholder="Category Name" />
				</Col>
			</Row>
			
			<Row>
				<Col md={12} >
					<hr />
					<div className="float-left" >
						<Checkbox
						  label="Active"
						  checked
						  marginBottom={40}
						/>	
					</div>
					<div className="float-right" >
						<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Save'} >
							&emsp; {this.state.btnText} &emsp; 
						</Button>
						&nbsp;
						<Button type="reset" color="danger" size="sm" onClick={this.props.atClose} >
							Cancel 
						</Button>
						
					</div>
				</Col>
			</Row>
			
		</form>		
		</div>
	  );
	}

}

export default AddCategory;
