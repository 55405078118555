import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls} from '../../../config';
import {BlockLoader, LineLoader} from '../../../elements';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';

class EditDepartment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save',
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			selectedFileUrl: '',
			showFileBrowser: false,
			showAddModal: false,
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount(){
		var fileUrl = this.getFieldValue('logopath');
		this.setState({selectedUrl: fileUrl, selectedFileUrl: Api.ImageUploadPath + fileUrl});
	}
	
	handleFileBrowserClick(files){
		try{
			
			console.log(files);
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				selectedFileUrl: files[0].path
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}

	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/course/departments/update', $("#frmEditAcInfo").serialize()).then(res=>{
				if(res['data'].status=='1'){
					//document.getElementById("addAcDeptForm").reset();
					notify.show(res['data'].message,'success');
					this.setState({btnText: 'Save', showLoader: false});
					//this.loadFileList();
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save', showLoader: false});
					
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false, btnText: 'Save'});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		

		
	  return (
		<div>
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<form action="" method="post" encType="multipart/form-data" id="frmEditAcInfo" onSubmit={this.handleFormSubmit}  >
			
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
				<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
						
				<Row>
					<Col md={3}>
						<Label>Name of the Dept <span className="text-danger">*</span></Label>
					</Col>
					<Col md={9} >
						<Input type="text" name="i__deptName" required size="sm" defaultValue={this.getFieldValue('departmentname')} />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={3}>
						<Label>Department Type <span className="text-danger">*</span></Label>
					</Col>
					<Col md={6} >
						<Input type="select" size="sm" required name="i__deptType" >
							<option value="arts" selected={this.getFieldValue('deptype')=='arts' ? 'selected' : ''} > Arts </option>
							<option value="science" selected={this.getFieldValue('deptype')=='science' ? 'selected' : ''}  > Science </option>
							<option value="commerce" selected={this.getFieldValue('deptype')=='commerce' ? 'selected' : ''}  > Commerce </option>
						</Input>
					</Col>
					<Col md={3} >
						<Input type="select" size="sm" required name="i__batchType" >
							<option value="aided" selected={this.getFieldValue('batchtype')=='aided' ? 'selected' : ''} > Aided </option>
							<option value="un-aided" selected={this.getFieldValue('batchtype')=='un-aided' ? 'selected' : ''} > Un-Aided </option>
						</Input>
					</Col>
				</Row>
			
				<Row className="mt-15" >
					<Col md={3}>
						<Label>Display Order <span className="text-danger">*</span></Label>
					</Col>
					<Col md={9} >
						<Input type="number" name="i__sortOrder" required size="sm" defaultValue={this.getFieldValue('sortorder')} />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={3}>
						<Label>Logo <span className="text-danger"></span></Label>
					</Col>
					<Col md={9} >
						<img src={this.state.selectedFileUrl} />
						<ModalFileBrowser isOpen={this.state.showFileBrowser} imageOnly  
							atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}}
								fileBrowserClick={this.handleFileBrowserClick} >
									<Button type="button" size="sm" color="warning" onClick={()=>{this.setState({showFileBrowser : true})}} > 
										{this.state.selectedFileUrl.length < 3 ? 'Select Image' : 'Change Image'}
									</Button>
									<span>&nbsp; Size in 300 x 300</span>
						</ModalFileBrowser> 
						
						
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnText!='Save'} >
								<i className="ion-android-close" /> &nbsp; Reset
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
								{this.state.btnText=='Save' ? <i className="ion-android-checkmark" /> : <i className="ion-loading-a" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			
			</form>	
		</div>
	  );
	}

}

export default EditDepartment;
