import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {InputMask} from 'primereact/inputmask';
import {Dropdown} from 'primereact/dropdown';

import Menu from './menu';

const styles = {
	subHeadStyles: {
		fontSize: '12px',
		color: '#848484',
		fontStyle:'italic',
	}
}

class PartyWise extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			partyList: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
			fromDate: '',
			toDate: '',
			selectedPartyId: '',
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadVouchers = this.loadVouchers.bind(this);
	}
	
	componentDidMount(){
		var today = new Date();
		//var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
		this.setState({fromDate: date, toDate: date});
		//this.loadVouchers();
		this.loadPartyList();
	}
	
	loadPartyList(){
		try{
			this.setState({partyLoader: true});
			API.get('v1/accounts/partyaccounts').then(res=>{
				if(res['data'].status=='1'){
					this.setState({partyList: res['data'].data, partyLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({partyLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({partyLoader: false});
		}
	}
	
	loadVouchers(){
		try{			
			if(this.state.selectedPartyId==''){
				notify.show('Select Party Name','warning');
				return;
			}
			this.setState({showLoader:true, dataList: [], dataView: []});
			var form = new FormData();
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('fromdate', this.state.fromDate);
			form.append('todate', this.state.toDate);
			form.append('partyid', this.state.selectedPartyId);
			
			API.post('v1/accounts/vouchers', form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.categoryname).indexOf(v)>-1) 
					|| (upperCase(obj.acdebitname).indexOf(v)>-1)
					|| (upperCase(obj.accreditname).indexOf(v)>-1)
					|| (upperCase(obj.voucherno).indexOf(v)>-1));
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/accounts/partyaccount/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,i){
		this.setState({editData: item,showEditModal: true});
	}
	
	render(){
		
	let sno = 1;
	var debitTotal = 0;
	var creditTotal = 0;
	var voucherTotal = 0;
	
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody className="pb-0 mb-0" >
						<Row>
							<Col md={4} className="pr-0" >
								<div className="input-group">
									<Dropdown
										options={this.state.partyList.map((item,i)=>{
											return {label: item.partyname, value: item.partyid};
										})
										}
										placeholder="Party Name" 
										filter={true}
										value={this.state.selectedPartyId}
										onChange={(e)=>{this.setState({selectedPartyId: e.value, dataList: [], dataView: []})}}
										className="form-control-sm"
										style={{width:'100%'}}
									/>
									{this.state.partyLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
								</div>
							</Col>
							<Col md={3} >
								<div className="input-group">
									<span className="input-group-addon" >
										From
									</span>
									<InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="dd/mm/yyyy"  onChange={(e)=>{this.setState({fromDate: e.value})}} className="form-control form-control-sm" value={this.state.fromDate} />
								</div>
							</Col>
							<Col md={2} className="pl-0" >
								<div className="input-group">
									<span className="input-group-addon" >
										To
									</span>
									<InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="dd/mm/yyyy"  onChange={(e)=>{this.setState({toDate: e.value})}} className="form-control form-control-sm" value={this.state.toDate} />
								</div>
							</Col>
							<Col md={1} className="px-0" >
								<Button type="button" size="sm" color="primary" onClick={this.loadVouchers} >
									VIEw
								</Button>
							</Col>
							<Col md={2} >
								<div className="text-right" >
									<Button type="button" size="sm" color="warning" onClick={this.loadVouchers} >
										<span className="ion-ios7-reload"></span>
									</Button>
								</div>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<hr  className="mt-0" />
						{this.state.showLoader ? <Loader type="dot" /> :
						<Table className="tbl" >
							<thead>
								<tr>
									<th width="50" >S.No</th>
									<th>Category Name</th>
									<th>Vch.No</th>
									<th>Type</th>
									<th width="100">Vch.Date</th>
									<th>Particulars</th>
									<th>Debit</th>
									<th>Credit</th>
								</tr>
							</thead>
							<tbody>
							{this.state.dataView.map((item,i)=>{
								//item.transactiontype=='payment' ? debitTotal+=parseFloat(item.amount) : creditTotal+=parseFloat(item.amount);
								item.acdebit==this.state.selectedPartyId ? debitTotal+=parseFloat(item.amount) : creditTotal+=parseFloat(item.amount);
								voucherTotal = (parseFloat(creditTotal)-parseFloat(debitTotal))
								return <tr key={i} >
									<td>{sno++}</td>
									<td>{item.categoryname}</td>
									<td>{item.voucherno}</td>
									<td>{upperCase(item.transactiontype)}</td>
									<td>{momentDate(item.voucherdate,'DD-MMM-YYYY')}</td>
									<td>
									{/*item.transactiontype=='receipt' ?*/}
										{item.acdebit==this.state.selectedPartyId ?
										<div>
											Paid to {item.accreditname}
										</div>
									:
										<div>
											Received from {item.acdebitname}
										</div>
									}
									</td>
									
									<td align="right" >{item.acdebit==this.state.selectedPartyId ?  item.amount : ''}</td>
									<td align="right"  >{item.accredit==this.state.selectedPartyId ? item.amount : ''}</td>
								</tr>;
							})
							}
							<tr>
								<td align="right" colSpan="6" > Total  </td>
								<td align="right" >{parseFloat(debitTotal).toFixed(2)}</td>
								<td align="right" >{parseFloat(creditTotal).toFixed(2)}</td>
							</tr>
							{/*<tr>
								<td align="right" colSpan="6" > Balance  </td>
								<td align="right" colSpan="2" className="bg-light" style={{fontWeight:'600'}} >
										Rs. {parseFloat(voucherTotal).toFixed(2)}
											{/*voucherTotal > 0 ? 'CR' : 'DR'*/}
												{/*	</td>
							</tr>*/}
							</tbody>
						</Table>
						}
						
						
					</CardBody>
				</Card>
				
			</div>
		
		</div>
	  );
	}

}

export default PartyWise;
