import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api } from '../../../variables';
import API from '../../../config/API';
import axios from 'axios';
import { setls, upperCase, capitalizeFirst, groupByMultiple, getls } from '../../../config';
import Select from 'react-select';
import { Container, Button as FloatButton, Link } from 'react-floating-action-button';

class EditSeminar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveText: 'Update',
            id: getls('user', 'empid'),
            editData: this.props.editData
        }
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
    }

    handleModalSubmit(e) {
        try {
            e.preventDefault();
            this.setState({ showLoader: true, saveText: 'Updatng' });
            axios.post(Api.AcademicPath + 'staff/updateseminar', $("#frmSeminarEditModal").serialize()).then(response => {
                if (response['data'].status == '1') {
                    this.setState({ showLoader: false, saveText: 'Update' });
                    document.getElementById('frmSeminarEditModal').reset();
                    notify.show(response['data'].msg, 'success');
                    this.props.afterFinish();
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    getValue(field) {
        var d = this.state.editData;
        return d[field];
    }

    setValue(field, e) {
        var d = this.state.editData;
        d[field] = e.target.value;
        this.setState({ editData: d });
    }

    render() {


        let getYear = [];
        var today = new Date();
        var curyear = today.getFullYear();
        for (var i = 1980; i <= curyear; i++) {
            getYear.push(<option value={i}>{i}</option>);
        }

        return (
            <div>
                <form action="" method="post" encType="multipart/form-data" id="frmSeminarEditModal" onSubmit={this.handleModalSubmit} >
                    <input type="hidden" name="id" value={this.getValue('id')} />
                    <input type="hidden" name="empid" value={this.state.id} />
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Category</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="category" value={this.getValue('category')} onChange={this.setValue.bind(this, 'category')} required >
                                    <option value="">Category</option>
                                    <option value="Seminar">Seminar</option>
                                    <option value="Workshop">Workshop</option>
                                    <option value="Conference">Conference</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Title</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="title" value={this.getValue('title')} onChange={this.setValue.bind(this, 'title')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Level</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="level" value={this.getValue('level')} onChange={this.setValue.bind(this, 'level')} >
                                    <option value="">Level</option>
                                    <option value="State">State</option>
                                    <option value="University">University</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Type</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="type" value={this.getValue('type')} onChange={this.setValue.bind(this, 'type')} required>
                                    <option value="">Type</option>type
                                    <option value="Attended">Attended</option>
                                    <option value="Organized">Organized</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Description</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="textarea" rows="3" name="description" value={this.getValue('description')} onChange={this.setValue.bind(this, 'description')} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Place</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="place" value={this.getValue('place')} onChange={this.setValue.bind(this, 'place')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Date</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="date" size="sm" name="seminardate" value={this.getValue('seminardate')} onChange={this.setValue.bind(this, 'seminardate')} required />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="float-right">
                                    <Button type="button" color="danger" onClick={this.props.onClose}>
                                        Cancel
                                    </Button>&nbsp;
                                    <Button type="submit" color="primary" disabled={this.state.saveText != 'Update' ? true : false}>
                                        {this.state.saveText != 'Update' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div >);
    }

}

export default EditSeminar;
