import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { notify } from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import { AppName, Version, Api } from '../../../variables';
import API from '../../../config/API';
import { groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, getAscSortOrder } from '../../../config';
import './A5Print.css';

class HostelA5BillPrint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			editData: [],
			printData: [],
			categoryList: [],
		}
	}

	componentDidMount() {

		this.loadCategory();
		this.loadBillData();
	}

	loadCategory() {
		try {

			this.setState({ typeLoader: true });
			var cid = getls('currentacbusiness', 'id');
			API.get('v1/fees/category/' + cid).then(res => {
				if (res['data'].status == '1') {
					this.setState({ categoryList: res['data'].data, typeLoader: false });
				}
				else {
					this.setState({ typeLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ typeLoader: false });
		}
	}


	printDocument(printId) {
		var content = document.getElementById(printId);
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
		if (this.props.atClose)
			this.props.atClose();
	}

	getField(fieldName) {
		try {
			var d = this.state.printData;
			return d[0][fieldName];
		}
		catch (error) { }
	}

	getYearBySem(sem) {
		try {
			if (sem == '1' || sem == '2') return '1';
			else if (sem == '3' || sem == '4') return '2';
			else if (sem == '5' || sem == '6') return '3';
			else return '';
		}
		catch (error) { return ''; }
	}


	loadBillData() {
		try {
			this.setState({ showLoader: true });
			var form = new FormData();
			form.append('type', 'receipt');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('voucherid', this.props.vId);

			API.post('v1/fees/voucher/info', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ printData: res['data'].data, showLoader: false });

					if (this.props.autoPrint)
						this.printDocument('frm_ag_Feecat_Print');
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}

	getAmountByCategory(catId) {
		try {
			var d = this.state.printData.filter(function (obj) {
				return (obj.catid == catId);
			});
			return (Object.keys(d).length > 0) ? Math.round(d[0]['amount']) : '0';
		}
		catch (error) {
			return '0.00';
		}
	}

	render() {

		var logo = getls('company', 'logo');

		return (
			<div style={{ marginLeft: 'auto', marginRight: 'auto' }} >

				<div style={{ background: '#fff' }} id="frm_ag_Feecat_Print" >
					<page size="A5" >
						<table width="100%" align="center" style={{ borderCollapse: 'collapse' }} >
							<tbody style={{ borderCollapse: 'collapse' }} >
								<tr style={{ borderCollapse: 'collapse' }} >
									<td colSpan="4" align="center" style={{ border: '1px solid black', borderCollapse: 'collapse' }}  >
										<div style={{ backgroundColor: 'black', width: '60px', color: '#fff', fontWeight: '600' }} >Receipt</div>
										{logo.length > 3 ? <div>
											<img src={logo} style={{ maxHeight: '70px', position: 'absolute', left: '30px', marginTop: '-15px' }} />
										</div> : null}
										<div style={{ marginTop: '10px', fontWeight: '800', fontSize: '16px' }} >{upperCase(getls('company', 'companyname'))}, {upperCase(getls('company', 'city'))}</div>
										{/*<div style={{fontSize:'14px', fontWeight:'600', margin:'10px'}} >(Autonomous)</div>*/}
										<div style={{ marginBottom: '10px' }} >(Under the Management of H.R & C.E Dept.)</div>

									</td>
								</tr>
								<tr style={{ borderCollapse: 'collapse' }} >
									<td width="140" height="25" style={{ paddingLeft: '30px', fontWeight: '600', borderLeft: '1px solid black', borderCollapse: 'collapse' }} >Bill No</td>
									<td> : {this.getField('voucherid')} </td>
									<td width="80" style={{ fontWeight: '600' }} >Bill Date</td>
									<td width="200" style={{ borderRight: '1px solid black', borderCollapse: 'collapse' }}  > : {momentDate(this.getField('voucherdate'), 'DD-MM-YYYY')}</td>
								</tr>
								<tr style={{ borderCollapse: 'collapse' }} >
									<td width="" height="25" style={{ paddingLeft: '30px', fontWeight: '600', borderLeft: '1px solid black', borderCollapse: 'collapse' }} >Name</td>
									<td> : {this.getField('name')} </td>
									<td width="" style={{ fontWeight: '600' }} >Year</td>
									<td width="200" style={{ borderRight: '1px solid black', borderCollapse: 'collapse' }} > : {this.getYearBySem(this.getField('studysem'))} </td>
								</tr>
								<tr style={{ borderCollapse: 'collapse' }} >
									<td width="" height="25" style={{ paddingLeft: '30px', fontWeight: '600', borderBottom: '1px solid black', borderCollapse: 'collapse', borderBottom: '1px solid black', borderLeft: '1px solid black' }} >Admission No</td>
									<td style={{ borderBottom: '1px solid black', borderCollapse: 'collapse' }} > : {this.getField('admno')}</td>
									<td width="" style={{ fontWeight: '600', borderBottom: '1px solid black', borderCollapse: 'collapse' }} >Major</td>
									<td width="200" style={{ borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse' }} > : {this.getField('course')}</td>
								</tr>
								{this.state.categoryList.map((item, i) => {

									return <tr key={i} style={{ borderCollapse: 'collapse' }} >
										<td colSpan="2" height="5" align="" style={{ paddingLeft: '50px', borderLeft: '1px solid black', borderCollapse: 'collapse' }} > {item.partyname}</td>
										<td colSpan="2" height="30" style={{ borderRight: '1px solid black', borderCollapse: 'collapse' }} > : &emsp; {this.getAmountByCategory(item.partyid)} </td>
									</tr>;
								})
								}
								<tr style={{ borderCollapse: 'collapse' }} >
									<td colSpan="" height="40" align="" style={{ paddingLeft: '50px', borderTop: '1px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', borderCollapse: 'collapse' }} > Signature</td>
									<td colSpan="3" align="center" style={{ fontSize: '20px', fontWeight: '600', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse' }} >
										Total - Rs.{Math.round(this.getField('netamount'))}
									</td>
								</tr>
							</tbody>
						</table>
					</page>
				</div>

			</div>
		);
	}

}

export default HostelA5BillPrint;
