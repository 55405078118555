import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import LoginLogo from '../../assets/images/user_200.png';
import {AppName, Version, ClientName, ClientPlace} from '../../variables';
import API from '../../config/API';
import {setls, getCookie, getls} from '../../config';

const styles = {
	nameCss: {
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingTop: '4px',
	},
	placeCss: {
		lineHeight: '0px',
		color: '#b5b1b1',
	}
}

class CompanyName extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  loginText: 'Login',
		  isRedirect: false,
		}
		
	}
	
	componentDidMount(){
		
		
	}
	

	render(){
	
	  return (
			<div  style={styles.nameCss} >
				<h5 >{getls('company','companyname')}</h5>
				<div style={styles.placeCss} >{getls('company', 'city')}</div>
			</div>
	  );
	}

}

export default CompanyName;
