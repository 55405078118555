import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls, decimalKeyPress} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';

import Menu from './menu';

class ReceiptEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Save Receipt',
			paymentDate: '',
			today: '',
			categoryList: [],
			partyList: [],
			filteredParty: [],
			selectedTypeId: '',
			selectedCategoryId: '',
			selectedFromId: '',
			selectedToId: '',
			payMethod: '',
			vId: '',
			vNo: '',
		}
		this.setCategoryType = this.setCategoryType.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		var today = new Date();
		var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		this.setState({today: date, paymentDate: date});
		this.loadVoucherInfo();
		this.loadCategory();
		this.loadPartyList();
	}
	
	loadCategory(){
		try{
			this.setState({catLoader: true});
			API.get('v1/accounts/accountscategory').then(res=>{
				if(res['data'].status=='1'){
					this.setState({categoryList: res['data'].data, catLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({catLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({catLoader: false});
		}
	}
	
	loadPartyList(){
		try{
			this.setState({partyLoader: true});
			API.get('v1/accounts/partyaccounts').then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data.filter(function(obj){
						return(obj.partytype=='cash' || obj.partytype=='bank');
					});
					this.setState({partyList: res['data'].data, filteredParty: d, partyLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({partyLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({partyLoader: false});
		}
	}
	
	loadVoucherInfo(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type', 'receipt');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			
			API.post('v1/accounts/voucher/no',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({vNo: res['data'].vno, vId: res['data'].vid, showLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	setCategoryType(e){
		try{
			var d = this.state.categoryList.filter(function(obj){
				return(obj.catid==e.value);
			});
			this.setState({selectedTypeId: d[0].typeid, selectedCategoryId: e.value});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedCategoryId==''){
				notify.show('Selecte Category','warning');
				return;
			}
			else if(this.state.selectedFromId==''){
				notify.show('Selecte From account','warning');
				return;
			}
			else if(this.state.selectedToId==''){
				notify.show('Selecte To account','warning');
				return;
			}
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/accounts/voucher/save',$("#frm_add_single_Payment").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					document.getElementById("frm_add_single_Payment").reset();
					this.setState({showLoader: false, selectedCategoryId:'', selectedToId:'', selectedFromId: '', selectedTypeId: '', vId: res['data'].vid, vNo: res['data'].vno, payMethod: '', btnSave: 'Save Receipt'});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false,btnSave: 'Save Receipt' });		
				}
			});
		}
		catch(error){
			notify.show('Error','error');
			this.setState({showLoader: false,btnSave: 'Save Receipt' });
		}
	}
	
	render(){

	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody>
						<form action="" method="post" id="frm_add_single_Payment" onSubmit={this.handleFormSubmit} >
							
							<input type="hidden" name="i__userId" value={getls('user', 'id')} />
							<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
							<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
							<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
							<input type="hidden" name="i__transactionType" value="receipt" />
							
							<input type="hidden" name="i__typeId" value={this.state.selectedTypeId} />
							<input type="hidden" name="i__categoryId" value={this.state.selectedCategoryId} />
							
							<input type="hidden" name="i__acDebit" value={this.state.selectedFromId} />
							<input type="hidden" name="i__acCredit" value={this.state.selectedToId} />
							
							<Row>
								<Col md={8} >
									<Row >
										<Col md={2} className=" ">
											<Label>Type</Label>
										</Col>
										<Col md={4} >
											<Input type="select" size="sm" required name="i__paymentType" >
												<option value="cash"> Cash </option>
												<option value="bank" > Bank </option>
											</Input>
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={2} className="pr-0" >
											<Label>Received For</Label>
										</Col>
										<Col md={7} >
											<Dropdown
												options={this.state.categoryList.map((item,i)=>{
													return {label: item.categoryname, value: item.catid};
												})
												}
												filter={true}
												value={this.state.selectedCategoryId}
												onChange={this.setCategoryType}
												className="form-control-sm"
												style={{width:'100%'}}
											/>
											{this.state.catLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
										</Col>
									</Row>
								</Col>
								<Col md={4} >
									<Row>
										<Col md={5} className="px-0 text-right">
											<Label>Receipt No</Label>
										</Col>
										<Col md={7} >
											<Input type="text" size="sm" readOnly name="i__paymentNo" value={this.state.vNo} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={5} className="px-0 text-right">
											<Label>Receipt Date</Label>
										</Col>
										<Col md={7} >
											{/*<Calendar 
												onChange={(e)=>{this.setState({paymentDate: e.value})}}
												value={this.state.paymentDate} 
												inputClassName="form-control form-control-sm" 
												style={{width:'100%'}}
											/>*/}
											<Input type="date" size="sm" required name="i__paymentDate" defaultValue={this.state.paymentDate} />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="mb-80" >
								<Col md={12}> <hr /> </Col>
							</Row>
							<Row>
								<Col md={1} >
									<Label>From</Label>
								</Col>
								<Col md={6} className="pl-45" >
									
									<Dropdown
										options={this.state.partyList.map((item,i)=>{
											return {label: item.partyname, value: item.partyid};
										})
										}
										filter={true}
										value={this.state.selectedFromId}
										onChange={(e)=>{this.setState({selectedFromId: e.value})}}
										className="form-control-sm"
										style={{width:'100%'}}
									/>
									{this.state.partyLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={1} >
									<Label>To</Label>
								</Col>
								<Col md={6} className="pl-45" >
									<Dropdown
										options={this.state.filteredParty.map((item,i)=>{
											return {label: item.partyname, value: item.partyid};
										})
										}
										filter={true}
										value={this.state.selectedToId}
										onChange={(e)=>{this.setState({selectedToId: e.value})}}
										className="form-control-sm"
										style={{width:'100%'}}
									/>
									{this.state.partyLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
								</Col>
								<Col md={3} className="text-right" >
									<Label>Amount</Label>
								</Col>	
								<Col md={2} >
									<Input type="text" name="i__amount" required size="sm" style={{textAlign:'right', fontWeight:'800'}} onKeyPress={decimalKeyPress} placeholder="0.00" />
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={1} >
									<Label>Method</Label>
								</Col>
								<Col md={2} className="pl-45" >
									<Input type="select" size="sm" name="i__payMethod" onChange={(e)=>{this.setState({payMethod: e.target.value})}} >
										<option value="direct"> Direct </option>
										<option value="cheque"> Cheque </option>
										<option value="DD"> DD </option>
										<option value="online"> Online </option>
										<option value="other"> Other </option>
									</Input>
								</Col>
								{this.state.payMethod!='direct' && this.state.payMethod!='' ? <Col md={2} >
									<Input type="text" name="i__refNo" size="sm" placeholder="Cheque No (or) Ref No" />
								</Col> : null}
								{this.state.payMethod!='direct' && this.state.payMethod!='' ? <Col md={2} className="pl-0" >
									<Input type="date" name="i__refDate" size="sm" />
								</Col> : null}
							</Row>
							
							<Row className="mt-10" >
								<Col md={1} >
									<Label>Narration</Label>
								</Col>
								<Col md={6} className="pl-45" >
									<Input type="textarea" name="i__narration" size="sm" rows="3" />
								</Col>
								<Col md={5} >
									<div className="text-right mt-40 " >
										<Button type="reset" color="danger" size="sm" disabled={this.state.btnSave!='Save Receipt'} >
											Reset
										</Button>
										&nbsp;
										<Button type="submit" color="primary" size="sm" disabled={this.state.btnSave!='Save Receipt'} >
											{this.state.btnSave!='Save Receipt' ? <i className="ion-loading-a"></i> : null}
											&nbsp; {this.state.btnSave}
										</Button>
									</div>
								</Col>
							</Row>
							
						</form>
					</CardBody>
				</Card>
				
			</div>
			
			<Modal isOpen={this.state.showAddModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
					Add Party Account
				</ModalHeader>
				<ModalBody>
					
				</ModalBody>
			</Modal>
		
		</div>
	  );
	}

}

export default ReceiptEntry;
