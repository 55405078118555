import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader, Tabs} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls, getCookie} from '../../../config';
import {reactLocalStorage} from 'reactjs-localstorage';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';

const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	}
}

class EditFellowship extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showFileBrowser: false,
			btnSubmit: 'Update Page',
			btnUpload: 'Upload Content',
			aboutData: '',
			data: [],
			pageCategory: 'bos',
			deptId: getls('user','deptid'),
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			showAddModal: false,
			fileList: [],
			fileView: [],
			deptCourse: reactLocalStorage.getObject('courselist'),
			batchList: reactLocalStorage.getObject('batchlist'),
			uploadAcademicYear: '',
			deptEmployees: reactLocalStorage.getObject('employeelist'),
		}
		
		this.handleFileUploadFormSubmit = this.handleFileUploadFormSubmit.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleAcademicYearChange = this.handleAcademicYearChange.bind(this);
	}
	
	componentDidMount(){
		
		var d = this.props.editData;
		this.setState({
			selectedUrl: d.filepath,
			uploadAcademicYear: d.academicyear,
			
		});
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handleAcademicYearChange(e){
		try{
			
			//this.setState({uploadAcademicYear: item.value});
			var v = e.target.value;
			if(v=='') return false;
			var d = this.state.batchList.filter(function(obj){
				return obj.id==v;
			});
			
			this.setState({uploadAcademicYear: d[0].value});
		}
		catch(error){
			
		}
	}
	
	handleFileUploadFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnUpload: 'Uploading..'});
			API.post('v1/department/filelist/save',$("#frm_add_bos").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					
					this.setState({showLoader: false, btnUpload: 'Upload Content', selectedFile: '', selectedFilename: '', selectedUrl: '', showAddModal: false });
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnUpload: 'Upload Content'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnUpload: 'Upload Content'});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.editData;
			
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	setField(fieldName){
		try{
			
		}
		catch(error){
			
		}
	}
	
	
	render(){
		
		let sno = 1;
		
		return(
			<div>
				
				<PageLoader loading={this.state.showLoader}  />
				<LineLoader loading={this.state.showLoader}  />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_add_bos" onSubmit={this.handleFileUploadFormSubmit} >
						
					<input type="hidden" name="i__id" value={this.getField('id')} />
					<input type="hidden" name="i__category" value={this.props.pageCategory} />
					<input type="hidden" name="i__deptId" value={this.state.deptId} />
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
					<input type="hidden" name="i__academicName" value={this.state.uploadAcademicYear} />
						
						<Row>
							<Col md={12} >
								<Label>Department</Label>
								<Input type="text" size="sm" readOnly value={this.state.deptCourse[0].departmentname} />
							</Col>
						</Row>
						
						<Row>
							<Col md={12} >
								<Label>Academic Year</Label>
								<Input type="select" size="sm"  name="i__academicId"  onChange={this.handleAcademicYearChange} >
									<option value=""> -Select- </option>
									{this.state.batchList.map((item,i)=>{
										return <option key={i} selected={this.getField('academicyearid')==item.id ? 'selected' : ''} value={item.id}  > {item.value} </option>;
									})
									}
								</Input>
							</Col>
						</Row>
						
						{this.props.showStaff ?<Row className="mt-10" >
							<Col md={10} >
								<Label>Staff Name</Label>
								<Input type="select" size="sm" name="i__faculty" >
								<option value=""> -Select- </option>
									{this.state.deptEmployees.map((item,i)=>{
										return <option key={i} value={item.id} selected={this.getField('staffid')==item.id ? 'selected' : ''} > 
											{item.empname} {item.qualification} 
										</option>;
									})
									}
								</Input>
							</Col>
							<Col md={2} >
								<Label>Sem Type</Label>
								<Input type="select" size="sm" name="i__semType" >
									<option value=""> -Select- </option>
									<option value="odd" selected={this.getField('semtype')=='odd' ? 'selected' : ''} > Odd </option>
									<option value="even" selected={this.getField('semtype')=='even' ? 'selected' : ''} > Even </option>
								</Input>
							</Col>
						</Row> : null}
					
						
						<Row className="mt-10" >
							<Col md={12} >
								<Label>File Title <span className="required">*</span> </Label>
								<Input type="text" size="sm" required name="i__title" required defaultValue={this.state.selectedFilename}  defaultValue={this.getField('filetitle')} />
							</Col>
						</Row>	
						
						<Row>
							<Col md={12} >
								<Label>File Path <span className="required">*</span> </Label>
								<div className="input-group" >
									<Input type="text" size="sm" required name="i__file" required value={this.state.selectedUrl} />
									<div className="input-group-addon" style={{cursor: 'pointer' }} onClick={()=>{this.setState({showFileBrowser: true})}}  >
										Browse
									</div>
								</div>
							</Col>
						</Row>	
						
						<Row className="mt-15" >
							<Col md={12} >
								<div className="text-center" >
									<Button type="submit" color="success" size="sm" disabled={this.state.btnUpload!='Upload Content'} >
											{this.state.btnUpload=='Upload Content' ? 
												<span className="ion-android-checkmark" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnUpload}
									</Button>
								</div>
							</Col>
						</Row>

				</form>					
								
				<ModalFileBrowser isOpen={this.state.showFileBrowser} 
				atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
					
				</ModalFileBrowser>
												
												
			</div>
		);
	}

}

export default EditFellowship;
