import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, numberWithCommas} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

const styles = {
	thStyle:{
		fontSize: '12px',
		borderBottom: '1px solid black',
		borderCollapse: 'collapse',
		paddingTop: '5px',
		paddingBottom: '5px',
		color: 'black',
	},
	tdStyle:{
		fontSize:'12px',
		paddingTop:'5px',
		paddingBottom:'5px',
		color: 'black',
		borderBottom: '1px dashed grey',
		borderCollapse: 'collapse',
	},
	dtStyle: {
		float: 'right',
		color: 'black',
	}
}

class DayReportA4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	getYearBySem(sem){
		try{
			if(sem=='1' || sem=='2') return '1';
			else if(sem=='3' || sem=='4') return '2';
			else if(sem=='5' || sem=='6') return '3';
			else return '';
		}
		catch(error){ return '';}
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	getCategoryData(){
		try{
			var d = [];
			d = groupByMultiple(this.props.reportData,function(obj){
				return[obj.catid];
			});
			return d;
		}
		catch(error){
			return [];
		}
	}
	
	calculateTotal(items){
		try{
			var t = 0;
			items.map((item,i)=>{
				t += parseFloat(item.amount);
			});
			return numberWithCommas(parseFloat(t).toFixed(0));
		}
		catch(error){
			return '0';
		}
	}
	
	render(){
		
		var logo = getls('company','logo');
		let sno = 1;
		
		let grandTotal = 0;
		
	  return (
	  <div>
				
			<page className="A4" >
			<div  id="frm_ag_Feecat_DayReportPrint" >
				<table width="98%" align="center" style={{borderCollpase:'collapse'}} >
					<tbody style={{borderCollpase:'collapse'}} >
						<tr style={{ borderCollpase:'collapse'}} >
							<td colSpan="4" align="center" style={{borderBottom:'1px solid black', borderCollpase:'collapse'}}  >
									{logo.length > 3 ? <div style={{position:'absolute', marginLeft: '30px', marginTop: '5px'}} >
									<img src={logo} style={{maxHeight:'75px'}} />
									</div> : null}
								<div style={{marginTop:'5px', fontWeight:'800', fontSize:'17px'}} >{upperCase(getls('company','companyname'))}, {upperCase(getls('company','city'))}</div>
								<div style={{fontSize:'14px', fontWeight:'600', margin:'2px'}} >(Autonomous)</div>
								<div style={{marginBottom:'0px'}} >(Under the Management of H.R & C.E Dept.)</div>
								<div style={{fontSize: '13px', marginTop: '5px', marginBottom: '5px' }} >DAY REPORT - {upperCase(getls('currentacbusiness','businessname'))} - {this.props.reportType} - {getls('currentacyear','value')}
								&emsp; <span style={styles.dtStyle} >DATE : {this.props.reportDate}</span>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<table width="100%" style={{borderCollapse: 'collapse'}} >
									<thead style={{borderCollapse: 'collapse'}}  >
										<tr style={{borderCollapse: 'collapse'}}  >
											<th width="50" style={styles.thStyle} align="left" >S.N</th>
											<th style={styles.thStyle} align="left" >Bill.No</th>
											<th style={styles.thStyle} align="left" >Adm.No</th>
											<th style={styles.thStyle} align="left" >Name</th>
											<th width="50" style={styles.thStyle} >Year</th>
											<th style={styles.thStyle} align="left" >Department</th>
											<th style={styles.thStyle} align="left" >{this.props.showCatDetails ? 'Fees Type' : 'Collect Year'}</th>
											<th style={styles.thStyle} align="right" >Amount</th>
										</tr>
									</thead>
									<tbody >
									{this.props.reportData.map((item,i)=>{
										grandTotal += parseFloat(item.amount);
										return <tr key={i} >
											<td style={styles.tdStyle} >{sno++}</td>
											<td style={styles.tdStyle} >{item.voucherid}</td>
											<td style={styles.tdStyle} >{item.admno}</td>
											<td style={styles.tdStyle} >{item.name}</td>
											<td style={styles.tdStyle} align="center" >{this.getYearBySem(item.studysem)}</td>
											<td style={styles.tdStyle} >{item.course}</td>
											<td style={{fontSize:'11px',paddingTop:'5px',paddingBottom:'5px',color: 'black',borderBottom: '1px dashed grey',borderCollapse:'collapse'}} >
												{this.props.showCatDetails ? item.partyname : item.studybatch}
											</td>
											<td style={styles.tdStyle} align="right" >{numberWithCommas(parseFloat(item.amount).toFixed(0))}</td>
										</tr>;
									})
									}
									<tr>
										<td colSpan="7" align="right" style={{fontSize: '12px'}} > Total : &emsp; </td>
										<td align="right" style={{fontWeight:'600', borderBottom:'1px solid black'}} >{numberWithCommas(parseFloat(grandTotal).toFixed(0))}</td>
									</tr>
									</tbody>
								</table>
							</td>
						</tr>	
						{this.props.showCatDetails ? <tr>
							<td>
								<br />
								<table width="100%" style={{borderCollapse: 'collapse', border: '1px solid black'}} >
									<tbody>
										<tr>
											<td colSpan="3" align="center" style={{fontWeight:'600', borderCollapse: 'collapse', border: '1px solid black'}} > All Details</td>
										</tr>
										{this.getCategoryData().map((item,i)=>{
											return  <tr key={i} >
												<td style={{paddingLeft:'20px'}} height="40" >{item[0].partyname}</td>
												<td align="center" width="100"> - </td>
												<td width="200" align="right" style={{paddingRight:'50px'}} >{this.calculateTotal(item)}</td>
											</tr>;
										})
										}
										<tr>
											<td align="right">Total</td>
											<td align="center" >: </td>
											<td align="right" style={{fontWeight:'600', paddingRight:'50px', borderTop: '1px solid black', fontSize:'15px'}} >{numberWithCommas(parseFloat(grandTotal).toFixed(2))}</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr> : null}
					</tbody>
				</table>
				
			</div>
			</page>
			
			{this.props.autoPrint ? null : <div className="print_fixed_options" >
				<ul>
					<li>
						<Button type="button" size="lg" title="Print" onClick={this.printDocument.bind(this,'frm_ag_Feecat_DayReportPrint')} >
							<span className="ion-printer"></span>
						</Button>
					</li>
				</ul>
			</div>}
				
		</div>
	  );
	}

}

export default DayReportA4;
