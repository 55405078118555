import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api } from '../../../variables';
import API from '../../../config/API';
import axios from 'axios';
import { setls, upperCase, capitalizeFirst, groupByMultiple, getls } from '../../../config';
import Select from 'react-select';
import { Container, Button as FloatButton, Link } from 'react-floating-action-button';

class AddPapers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveText: 'Save',
            id: getls('user', 'empid'),
        }
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
    }

    handleModalSubmit(e) {
        try {
            e.preventDefault();
            this.setState({ showLoader: true, saveText: 'Saving' });
            axios.post(Api.AcademicPath + 'staff/savepaper', $("#frmPaperModal").serialize()).then(response => {
                if (response['data'].status == '1') {
                    this.setState({ showLoader: false, saveText: 'Save' });
                    document.getElementById('frmPaperModal').reset();
                    notify.show(response['data'].msg, 'success');
                    this.props.afterFinish();
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    render() {


        let getYear = [];
        var today = new Date();
        var curyear = today.getFullYear();
        for (var i = 1980; i <= curyear; i++) {
            getYear.push(<option value={i}>{i}</option>);
        }

        return (
            <div>
                <form action="" method="post" encType="multipart/form-data" id="frmPaperModal" onSubmit={this.handleModalSubmit} >
                    <input type="hidden" name="id" value={this.state.id} />
                    <input type="hidden" name="ispublish" value="0" />
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Title</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="title" required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Level</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="level" >
                                    <option value="">Level</option>
                                    <option value="National">National</option>
                                    <option value="International">International</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Description</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="textarea" rows="3" name="description" required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Place</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="place" required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Date</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="date" size="sm" name="presentdate" />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="float-right">
                                    <Button type="button" color="danger" onClick={this.props.onClose}>
                                        Cancel
                                    </Button>&nbsp;
                                    <Button type="submit" color="primary" disabled={this.state.saveText != 'Save' ? true : false}>
                                        {this.state.saveText != 'Save' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div >);
    }

}

export default AddPapers;
