import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader, Tabs} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls, getCookie} from '../../../config';
import CKEditor from 'ckeditor4-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';


import Menu from './menu';
import AddFile from './AddFile';
import EditFile from './EditFile';


const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	}
}

class DeptCourseOutcomes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showEditModal: false,
			showFileBrowser: false,
			btnSubmit: 'Update Page',
			btnUpload: 'Upload Content',
			aboutData: '',
			data: [],
			pageCategory: 'courseoutcomes',
			deptId: getls('user','deptid'),
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			showAddModal: false,
			fileList: [],
			fileView: [],
			deptCourse: reactLocalStorage.getObject('courselist'),
			batchList: reactLocalStorage.getObject('batchlist'),
			uploadAcademicYear: '',
			editData: [],
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
		this.handleFileUploadFormSubmit = this.handleFileUploadFormSubmit.bind(this);		
		this.onContentEditorChange = this.onContentEditorChange.bind(this);		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.handleTblSearch = this.handleTblSearch.bind(this);
		this.handleTblDropdownSearch = this.handleTblDropdownSearch.bind(this);
		this.modalAfterFinish = this.modalAfterFinish.bind(this);
		this.loadFileList = this.loadFileList.bind(this);
	}
	
	componentDidMount(){
		
		this.loadPageContent();
		this.loadFileList();
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader: true});

			API.get('v1/department/page/'+this.state.deptId+'/'+this.state.pageCategory).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({data: res['data'].data, aboutData: res['data'].data.pagecontent, showLoader: false});
				}
				else
				{
					//notify.show(res['data'].message,'error');					
					this.setState({showLoader: false});
				}
			});
			
			
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			 
			this.setState({ aboutData: evt.editor.getData() }); 
		 }
		 catch(error){
			 
		 }
    }
	


	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(!this.state.aboutData  || !this.state.aboutData ==null || this.state.aboutData.length<1)
			{
				notify.show('Please enter Page Content','warning');
				return false;
			}
			
			this.setState({showLoader: true, btnSubmit: 'Updating..'});
			API.post('v1/department/page/save',$("#frm_academic_About").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					var d = this.state.data;
					d['id'] = res['data'].pageid;
					this.setState({showLoader: false, btnSubmit: 'Update Page',data: d });
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSubmit: 'Update Page'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnSubmit: 'Update Page'});
			console.log(error);
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			if(d[fieldName])
			{
				return d[fieldName];
			}
			else return '';
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleTextAreaChange(key, e){
		try{
			var d = this.state.data;
			d[key] = e.target.value;
			this.setState({data: d});
		}
		catch(error){
			
		}
	}
	
	handleAcademicYearChange(item,e){
		try{
			
			this.setState({uploadAcademicYear: item.value});
		}
		catch(error){
			
		}
	}
	
	handleFileUploadFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnUpload: 'Uploading..'});
			API.post('v1/department/filelist/save',$("#frm_add_bos").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					
					this.setState({showLoader: false, btnUpload: 'Upload Content', selectedFile: '', selectedFilename: '', selectedUrl: '', showAddModal: false });
					this.loadFileList();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnUpload: 'Upload Content'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnUpload: 'Upload Content'});
			console.log(error);
		}
	}
	
	
	loadFileList(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/department/filelist/'+this.state.deptId+'/'+this.state.pageCategory).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({fileList: res['data'].data, fileView: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleTblSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.fileList.filter(function(obj){
				return(upperCase(obj.filetitle).indexOf(v)>-1);
			});
			
			this.setState({fileView: d});
		}
		catch(error){
			
		}
	}
	
	handleTblDropdownSearch(e){
		try{
			
			if(e.target.value=="")
			{
				this.setState({fileView: this.state.fileList});
				return;
			}
			var v = upperCase(e.target.value);
			var d = this.state.fileList.filter(function(obj){
				return(upperCase(obj.forcourse)==v);
			});
			
			this.setState({fileView: d});
		}
		catch(error){
			
		}
	}
	
	handlefileRemove(item){
		try{
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to Delete?',
				buttons: {
					Yes: function(){
						$this.confirmFileRemove(item);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	confirmFileRemove(item){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/department/filelist/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var index = this.state.fileList.findIndex(function(obj){
						return((obj.id==item.id));
					});
					var s = this.state.fileList;
					s.splice(index,1);
					this.setState({fileList: s, fileView: s, showLoader: false});
					notify.show(res['data'].message,'success');
				}
				else
				{
					this.setState({showLoader: false});
					notify.show(res['data'].message,'error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	modalAfterFinish(){
		
		this.setState({showAddModal: false, showEditModal: false});
		this.loadFileList();
	}
	
	handleEdit(item){
		try{
			
			this.setState({editData: item, showEditModal: true});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		let sno = 1;
		
		return(
			<div>
				
				<PageLoader loading={this.state.showLoader} />
				<LineLoader loading={this.state.showLoader} />
				
				<Menu />
				
				
				<div className="pt-50" >
				
			<Card>
				<CardBody>
					<Row>
						<Col md={7} >
							<div className="input-group" >
								<Input type="text"size="sm" placeholder="Search by  Title" onChange={this.handleTblSearch} />
								<div className="input-group-addon" >
									<span className="ion-search"></span>
								</div>
							</div>
						</Col>
						<Col md={2} >
							<Input type="select" size="sm" onChange={this.handleTblDropdownSearch} >
								<option value=""> </option>
								<option value="all" > All </option>
								<option value="ug" > UG </option>
								<option value="pg" > PG </option>
								<option value="mphil" > M.Phil </option>
							</Input>
						</Col>
						<Col md={3}>
							<div className="text-right" >
								<Button type="button" size="sm" color="warning" onClick={this.loadFileList} >
									<span className="ion-ios7-reload"></span>
								</Button>
								&nbsp;
								<Button type="button" size="sm" color="info" onClick={()=>{this.setState({showAddModal: true})}} >
									<span className="ion-android-add"></span> Add Content
								</Button>
							</div>
						</Col>
					</Row>
					<Row className="mt-10" >
						<Col md={12} >
							<Table className="tbl" >
								<thead>
									<tr>
										<th width="80" >S.No</th>
										<th>File Title</th>
										<th>Academic Year</th>
										<th>Category</th>
										<th width="120" >Manage</th>
									</tr>
								</thead>
								<tbody>
								{this.state.fileView.map((item,i)=>{
									return <tr key={i} >
										<td align="center" >{sno++}</td>
										<td>{item.filetitle}</td>
										<td>{item.academicyear}</td>
										<td>{upperCase(item.forcourse)}</td>
										<td align="center" >
											<Button type="button" size="xs" color="info" onClick={this.handleEdit.bind(this,item)} >
												<span className="ion-edit"></span>
											</Button>
											&nbsp;
											
											<Button type="button" size="xs" color="danger" onClick={this.handlefileRemove.bind(this,item)} >
												<span className="ion-close"></span>
											</Button>
										</td>
									</tr>;
								})
								}
								</tbody>
							</Table>
						</Col>
					</Row>
				</CardBody>
			</Card>
				
				
			<Card>
				<CardBody>
				
					<form action="" method="post" encType="multipart/form-data" id="frm_academic_About" onSubmit={this.handleFormSubmit} >
				
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__category" value={this.state.pageCategory} />
				<input type="hidden" name="i__deptId" value={this.state.deptId} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				<Row>
					<Col md={12} >
						
								<Row>
									<Col md={12} >
										<div className="text-left" >
										<div className="pretty p-icon p-smooth">
										{this.getField('showinpage')=='1' ? 
											<input type="checkbox" name="i__about_ShowInPage" defaultChecked={true} /> :
											<input type="checkbox" name="i__about_ShowInPage"  />
										}
											<div className="state p-success">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show In Page</label>
											</div>
										</div>
										</div>
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
								</Row>
								
								<Row className="mt-20" >
									<Col md={12} >
										<Label>PSO Page Content <span className="required">*</span>
											<div style={{position: 'absolute', top: '0px', left: '175px' }} >
												<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
												>
													<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
														Open File Manager
													</Button>
												</ModalFileBrowser>
											</div>
										</Label>
										{this.state.showLoader?  null : <CKEditor 
									name='editor_dept_about' 
									config={{ extraPlugins: 'print,font', 
									height: '700', 
									startupShowBorders: 'false', 
									allowedContent: true, 
									extraAllowedContent: '*(*);*{*}' }} 
									data={this.state.aboutData} 
										onChange={this.onContentEditorChange} /> }
										
									</Col>
									<Col md={12} className="mt-15" >
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
								</Row>
							
					</Col>
				</Row>
				
				<input type="hidden" name="i__about_Pagecontent" value={this.state.aboutData} />
				
				</form>
				
				</CardBody>
			</Card>
				
				</div>
				
				<Modal isOpen={this.state.showAddModal} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({selectedFilename: '', selectedFile: '', selectedUrl: '', showAddModal: false})}} >
						Upload Content
					</ModalHeader>
					<ModalBody>
						
							<AddFile pageCategory={this.state.pageCategory}
							atFinish={this.modalAfterFinish} />
							
					</ModalBody>
				</Modal>
				
				<Modal isOpen={this.state.showEditModal} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({selectedFilename: '', selectedFile: '', selectedUrl: '', showEditModal: false})}} >
						Edit Uploaded Content
					</ModalHeader>
					<ModalBody>
						
							<EditFile pageCategory={this.state.pageCategory}
							editData={this.state.editData}
							atFinish={this.modalAfterFinish} />
							
					</ModalBody>
				</Modal>
			
				
				<ModalFileBrowser isOpen={this.state.showFileManager} 
				atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
					<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
						Open File Manager
					</Button>
				</ModalFileBrowser>
				
																
												
			</div>
		);
	}

}

export default DeptCourseOutcomes;
