import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'reactstrap';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	  return (
		<div className="page-sub-nav page-sub-nav-fixed">
		  <Nav pills>
			<NavItem>
				<NavLink to="/fees/settings" exact >
					Fee Category
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/fees/settings/feeassign"  exact >
					Fee Assigning
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/fees/settings/accounts"  exact >
					Account List
				</NavLink>
			</NavItem>	
			
			<NavItem>
				<NavLink to="/fees/settings/others"  exact >
					Others
				</NavLink>
			</NavItem>	
		  </Nav>
		</div>
	  );
	}

}
export default Menu;
