import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader, Tabs} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls, getCookie} from '../../../config';
import {reactLocalStorage} from 'reactjs-localstorage';
import CKEditor from 'ckeditor4-react';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';
import ContentLoader from 'react-content-loader'

const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	},
	removeBtn: {
	    position: 'absolute',
		background: 'red',
		color: '#fff',
		borderRadius: '50px',
		padding: '0px 4px',
		fontSize: '11px',
		border: '1px solid black',
	}
}

class EditFacility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showFileBrowser: false,
			showImgLoader: true,
			btnSubmit: 'Update Page',
			btnUpload: 'Upload Content',
			aboutData: '',
			data: [],
			pageCategory: 'researchfacility',
			deptId: getls('user','deptid'),
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			showAddModal: false,
			fileList: [],
			fileView: [],
			deptCourse: reactLocalStorage.getObject('courselist'),
			batchList: reactLocalStorage.getObject('batchlist'),
			deptEmployees: reactLocalStorage.getObject('employeelist'),
			uploadAcademicYear: '',
		}
		this.onContentEditorChange = this.onContentEditorChange.bind(this);		
		this.handleFileUploadFormSubmit = this.handleFileUploadFormSubmit.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleAcademicYearChange = this.handleAcademicYearChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadFiles();
		var v = this.props.editData;
		this.setState({aboutData: v.pagecontent});
	}
	
	 onContentEditorChange( evt ) {
		 try{
			 
			this.setState({ aboutData: evt.editor.getData() }); 
		 }
		 catch(error){
			 
		 }
    }
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			var d = this.state.fileList;
			d.push({
				url: Api.ImageUploadPath+ url,
				filepath: url,
				name: files[0].data.name,
				status: '1'				
			});
			
			this.setState({fileList: d});
			console.log(this.state.fileList);
			/*
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path*/
		}
		catch(error){
			
		}
	}
	

	handleAcademicYearChange(e){
		try{
			
			//this.setState({uploadAcademicYear: item.value});
			var v = e.target.value;
			if(v=='') return false;
			var d = this.state.batchList.filter(function(obj){
				return obj.id==v;
			});
			
			this.setState({uploadAcademicYear: d[0].value});
		}
		catch(error){
			
		}
	}
	
	handleFileUploadFormSubmit(e){
		e.preventDefault();
		try{
			
			//this.setState({showLoader: true, btnUpload: 'Uploading..'});
			API.post('v1/department/research/facility/update',$("#frm_add_rf").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					
					//this.setState({showLoader: false, btnUpload: 'Upload Content', selectedFile: '', selectedFilename: '', selectedUrl: '', showAddModal: false });
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					//this.setState({showLoader: false, btnUpload: 'Upload Content'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnUpload: 'Upload Content'});
		}
	}
	
	handleRemoveImageClick(index){
		try{			
			var d = this.state.fileList;
			d[index]['status'] = '0';
			this.setState({fileList: d});
		}
		catch(error){
		
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.editData;
			
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	
	loadFiles(){
		try{
			var d = this.props.editData;
			var refid = d.id;
			this.setState({showImgLoader: true});
			API.get('v1/department/research/facility/'+refid).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({fileList: res['data'].data, showImgLoader: false});
				}					
				else
				{
					this.setState({showImgLoader: false});
				}					
			});
		}
		catch(error){
			this.setState({showImgLoader: false});
		}
	}
	
	
	render(){
		
		let sno = 1;
		
		return(
			<div>
				
				<PageLoader loading={this.state.showLoader}  />
				<LineLoader loading={this.state.showLoader}  />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_add_rf" onSubmit={this.handleFileUploadFormSubmit} >
					
					<input type="hidden" name="i__id" value={this.getField('id')} />
					<input type="hidden" name="i__category" value={this.props.pageCategory} />
					<input type="hidden" name="i__deptId" value={this.state.deptId} />
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
					<input type="hidden" name="i__academicName" value={this.state.uploadAcademicYear} />
						
						<Row>
							<Col md={8} >
						
						<Row>
							<Col md={6} >
								<Label>Department</Label>
								<Input type="text" size="sm" readOnly value={this.state.deptCourse[0].departmentname} />
							</Col>
						
							<Col md={6} >
								<Label>Academic Year (Establishment Year)</Label>
								<Input type="select" size="sm"  name="i__academicId"  onChange={this.handleAcademicYearChange} >
									<option value=""> -Select- </option>
									{this.state.batchList.map((item,i)=>{
										return <option key={i} value={item.id}  selected={this.getField('academicyearid')==item.id ? 'selected' : ''} > {item.value} </option>;
									})
									}
								</Input>
							</Col>
						</Row>
						
						{this.props.showStaff ?<Row className="mt-10" >
							<Col md={9} >
								<Label>Research Co-Ordinator</Label>
								<Input type="select" size="sm" name="i__faculty" >
								<option value=""> -Select- </option>
									{this.state.deptEmployees.map((item,i)=>{
										return <option key={i} value={item.id} selected={this.getField('staffid')==item.id ? 'selected' : ''} > {item.empname} {item.qualification} </option>;
									})
									}
								</Input>
							</Col>
							<Col md={3} >
								<Label>Date of Approval</Label>
								<Input type="text" size="sm" name="i__approvalDate"  />
							</Col>
						</Row> : null}
												
						<Row className="mt-10" >
							<Col md={12} >
								<Label>Title <span className="required">*</span> </Label>
								<Input type="text" size="sm" required name="i__title" required defaultValue={this.getField('filetitle')} />
							</Col>
						</Row>	
						
						<Row>
							<Col md={12} >
								<Label>Content <span className="required">*</span> </Label>
								{this.state.showLoader?  null : <CKEditor 
									name='editor_re_fac_new' 
									config={{
									toolbar: [
										[ 'Bold', 'Italic' ],
										[ 'Cut', 'Copy' ],
										['Styles', 'Format', 'FontSize', 'TextColor', 'BGColor' ],
										[ 'NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyBlock', 'JustifyRight', 'Blockquote' ],
										['Link', 'Image', 'Table', 'Source', 'Maximize']
									],								
									height: '300', 
									startupShowBorders: 'false', 
									allowedContent: true, 
									extraAllowedContent: '*(*);*{*}' }} 
									data={this.state.aboutData} 
										onChange={this.onContentEditorChange} /> }
							</Col>
						</Row>	
						
						</Col>
						<Col md={4} >
							
							<Row>
								<Col md={12} >
									<Label>Images</Label>
									<div className="float-right" >
										<Button type="button" size="sm" color="info" onClick={()=>{this.setState({showFileBrowser: true})}} >
											Select Images
										</Button>
									</div>
								</Col>
							</Row>
							
							
							{this.state.showImgLoader ?
								<Row className="mt-15" >
								<ContentLoader >
									<rect x="0" y="17" rx="0" ry="0" width="120" height="84" />
									<rect x="130" y="17" rx="0" ry="0" width="120" height="84" />
									<rect x="260" y="17" rx="0" ry="0" width="120" height="84" />
								</ContentLoader >
								</Row>
								: <Row className="mt-15" >
									{this.state.fileList.map((item,i)=>{
										var url = Api.ImageUploadPath + item.filepath;
										return item.status=='1' ? <Col md={4} >
												<a href="javascript:;" style={styles.removeBtn} onClick={this.handleRemoveImageClick.bind(this,i)} >
													<i className="ion-android-close"></i>
												</a>
											<img src={url} style={{maxHeight: '150px', border:'1px solid grey'}}/>
										</Col> : null;
									})
									}
								</Row>
							}
							
							
						</Col>
					</Row>
						
						<Row className="mt-15" >
							<Col md={12} >
								<div className="text-center" >
									<Button type="submit" color="success" size="sm" disabled={this.state.btnUpload!='Upload Content'} >
											{this.state.btnUpload=='Upload Content' ? 
												<span className="ion-android-checkmark" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnUpload}
									</Button>
								</div>
							</Col>
						</Row>

					<input type="hidden" name="i__pageContent" value={this.state.aboutData} />
					<input type="hidden" name="i__fileList" value={JSON.stringify(this.state.fileList)} />

				</form>					
								
				<ModalFileBrowser isOpen={this.state.showFileBrowser} 
				atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
					
				</ModalFileBrowser>
									


												
			</div>
		);
	}

}

export default EditFacility;
