import React from 'react';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './views/app/Login';

import './vibe/scss/styles.scss';

export default function App() {
  return (
    <HashRouter>
	  <Switch>
		<Route path="/app" component={DashboardLayout} />
		<Route path="/dept" component={DashboardLayout} />
		<Route path="/fees" component={DashboardLayout} />
		<Route path="/login" component={Login} />
		<Redirect from="/" to="/login" />
	  </Switch>
    </HashRouter>
  );
}
