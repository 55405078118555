import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { BlockLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import Select from 'react-select';


class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Update',
			deptList: [],
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.loadDepartments = this.loadDepartments.bind(this);
	}

	componentDidMount(){
		if(this.props.department)
			this.loadDepartments();
	}

	loadDepartments(){
		try{
			this.setState({showLoader: true});
			API.get('v1/course/departments/all').then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({showLoader: false, deptList: res['data'].data});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/user/password/update', $("#frmEditPassword").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmEditPassword").reset();
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnText: 'Update'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Update'});
				}
			});
		}
		catch(error){ 
			this.setState({showLoader: false, btnText: 'Update'});
		}
	}

	render() {

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />
				<BlockLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frmEditPassword" autocomplete="off" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__id" value={this.getField('id')} />
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Password <span className="text-danger">*</span></Label>
							<Input type="text" size="sm" required name="i__password" autoComplete="off"  />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Change Password <span className="text-danger">*</span></Label>
							<Input type="text" size="sm" required name="i__confirmPassword"  />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="reset" size="sm" color="danger" >
									<i className="ion-close" /> Reset
								</Button>
								&nbsp;
								<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Update'} >
									{this.state.btnText=='Update' ?  <i className="ion-checkmark" />  : <i className="ion-loading-a" /> } 
									&nbsp; {this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
				
				</form>

			</div>
		);
	}

}

export default ChangePassword;
