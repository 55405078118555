import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader, Tabs} from '../../../elements';
import API from '../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder} from '../../../config';
import classnames from 'classnames';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import About from './Edit/About';
import Vision from './Edit/Vision';
import Awards from './Edit/Awards';
import Syllabus from './Edit/Syllabus';
import CourseOutcomes from './Edit/CourseOutcomes';
import TeachingPlan from './Edit/TeachingPlan';
import Ematerials from './Edit/Ematerials';

import Menu from './menu';


const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	}
}

class EditAcademics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			batchList: [],
			courseList: [],
			courseView: [],
			courseInfo: [],
			academicPages: [],
			deptId: this.props.match.params.id,
			activeTab: '1',
		}
		
		
	}
	
	componentDidMount(){
		this.setState({deptId: this.props.match.params.id});

		this.loadBatchList();
		
		this.loadPages();
	}
	
	
	loadBatchList(){
		try{
			this.setState({showLoader: true, batchList: [], courseList: [], courseView: []});
			axios.post(Api.AcademicPath+'course/coursebytype/UG/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						batchList: res['data'].data,
						courseInfo: res['data'].course,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			})
		}
		catch(error){
			
		}
	}
	
	loadPages(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/academics/pages/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({academicPages: res['data'].data});
					this.forceUpdate();
				}
				this.setState({showLoader: false});
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getPageContent(category){
		try{
			
			var d = this.state.academicPages.filter(function(obj){
				return(upperCase(obj.category)==upperCase(category));
			});
			return Object.keys(d).length>0 ? d[0] : [];
		}
		catch(error){
			
		}
	}
	
	render(){
		
		let courseSno = 1;
		
		return(
			<div>
				
				<PageLoader loading={this.state.showLoader} />
				<LineLoader loading={this.state.showLoader} />
				
				<Menu />
				
				
				<div className="pt-50" >
				
					{this.state.deptId && this.state.deptId.length > 0 ?
					<div>
					<div style={styles.courseBtn} >
						<Button type="button" color="success" >
							{this.state.courseInfo.type} {this.state.courseInfo.coursename} - {this.state.courseInfo.name} ({capitalizeFirst(this.state.courseInfo.coursetype)})
						</Button>
					</div>
					
					 <Nav tabs>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '1' })}
							onClick={()=>{this.setState({activeTab: '1'})}}
						  >
							About
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '2' })}
							onClick={()=>{this.setState({activeTab: '2'})}}
						  >
							Vision & Mission
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '4' })}
							onClick={()=>{this.setState({activeTab: '4'})}}
						  >
							Syllabus
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '5' })}
							onClick={()=>{this.setState({activeTab: '5'})}}
						  >
							PSO & Course Outcomes
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '6' })}
							onClick={()=>{this.setState({activeTab: '6'})}}
						  >
							PSO & Teaching Plan
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '7' })}
							onClick={()=>{this.setState({activeTab: '7'})}}
						  >
							E-Material
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: this.state.activeTab === '8' })}
							onClick={()=>{this.setState({activeTab: '8'})}}
						  >
							Awards
						  </NavLink>
						</NavItem>
						
					  </Nav>
					   
					   <TabContent activeTab={this.state.activeTab}>
						 
						 <TabPane tabId="1">
								
								<About deptId={this.state.deptId} content={this.getPageContent('about')} />
								
						 </TabPane>
						 <TabPane tabId="2">
							
							<Vision deptId={this.state.deptId} content={this.getPageContent('vision')}  />
							
						 </TabPane>
						 
						  <TabPane tabId="4">
							
							<Syllabus batch={this.state.batchList} deptId={this.state.deptId} courseInfo={this.state.courseInfo} content={this.getPageContent('syllabus')} />
							
						 </TabPane>
						 
						 <TabPane tabId="5">
							
							<CourseOutcomes batch={this.state.batchList} deptId={this.state.deptId} courseInfo={this.state.courseInfo} content={this.getPageContent('courseoutcomes')} />
							
						 </TabPane>

						 <TabPane tabId="6">
							
							<TeachingPlan batch={this.state.batchList} deptId={this.state.deptId} courseInfo={this.state.courseInfo} content={this.getPageContent('teachingplan')} />
							
						 </TabPane>
						 
						 <TabPane tabId="7">
							
							<Ematerials batch={this.state.batchList} deptId={this.state.deptId} courseInfo={this.state.courseInfo} content={this.getPageContent('ematerials')} />
							
						 </TabPane>
						 
						 <TabPane tabId="8" >
							
							<Awards deptId={this.state.deptId} content={this.getPageContent('awards')} />
							
						 </TabPane>

						
						 
					   </TabContent>
					
					</div>
					
					: <div>
						
					</div>
					}
					 
				  </div>
				  
			</div>
		);
	}

}

export default EditAcademics;
