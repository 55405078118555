import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

class EditTitle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Update',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnSave:'Updating..'});
			API.post('v1/naac/ssr/title/update', $("#frm_ssr_add_Title").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		
	  return (
	  <div>
			<form action="" method="post" encType="multipart/form-data" id="frm_ssr_add_Title" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__id" value={this.getField('id')} />
				
				<Row>
					<Col md={12} >
						<Label>Code <span className="required">*</span> </Label>
						<Input type="text" size="sm" name="i__code" required defaultValue={this.getField('code')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<Label>Title <span className="required">*</span> </Label>
						<Input type="text" size="sm" name="i__title" required defaultValue={this.getField('title')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnSave!='Update'} >
								Cancel
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave!='Update' ? <span className="ion-loading-a"></span> : null}
								{this.state.btnSave}
							</Button>
						</div>
					</Col>
				</Row>
			</form>			
		</div>
	  );
	}

}

export default EditTitle;
