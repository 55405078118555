import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {AutoComplete} from 'primereact/autocomplete';
import CKEditor from 'ckeditor4-react';
import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

class EditReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showfileBrowser: false,
			showInMenu: true,
			btnSave: 'Update',
			pageData: '',
			titles: [],
			titleSuggest: [],
			selectedTitle: [],
			groupTitles: [],
			groupTitleView: [],
			groupTitleSuggest: [],
			selectedGrpoupTitle: [],
			menuCode: '',
			menuTitle: '',
		}
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onSuggestTitleSelect = this.onSuggestTitleSelect.bind(this);
		this.onSuggestGroupTitleSelect = this.onSuggestGroupTitleSelect.bind(this);
	}
	
	componentDidMount(){
		var d = this.props.editData;
		this.setState({pageData: d['pagecontent'],  showInMenu: d['showinpage']=='1'});
		this.loadTitles();
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){ }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(Object.keys(this.state.selectedTitle).length<1){
				notify.show('Please select title','warning');
				return;
			}
			else if(Object.keys(this.state.selectedGrpoupTitle).length<1){
				notify.show('Please select group title','warning');
				return;
			}
			this.setState({showLoader: true, btnSave:'Updating..'});
			API.post('v1/naac/ssr/update', $("#frm_ssr_add_GroupTitle").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	loadTitles(){
		try{
			this.setState({showLoader: true, titles: [], titleSuggest: []});
			API.get('v1/naac/ssr/grouptitles').then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var title = groupByMultiple(d,function(obj){
						return[obj.titleid];
					});
					var t = this.makeTitle(title);
					this.setState({titles: t, titleSuggest: t, groupTitles: d, showLoader: false});
					this.loadSelectedTtitles();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	makeTitle(titles){
		try{
			var d = [];
			titles.map((items,i)=>{
				var item = items[0];
				d.push(item);
			});
			return d;
		}
		catch(error){ }
	}
	
	makeGroupTitle(grouptitles){
		try{
			var _d = [];
			grouptitles.map((_item,i)=>{
				var _displayname = _item.groupcode +'.'+_item.grouptitle;
				_item['displayname'] = _displayname;
				_d.push(_item);
			});
			return _d;
		}
		catch(error){ }
	}
	
	
	makeTitleSuggestion(e){
		try{
			var v = upperCase(e.query);
			var d = this.state.titles.filter(function(obj){
				return((upperCase(obj.title).indexOf(v)>-1) || (upperCase(obj.titlecode).indexOf(v)>-1));
			});
			this.setState({ titleSuggest: d });
		}
		catch(error){ }
	}
	
	onSuggestTitleSelect(e){
		try{
			var v = this.state.groupTitles.filter(function(obj){
				return(obj.titleid==e.value.titleid);
			});
			//v = this.makeGroupTitle(v);
			this.setState({selectedTitle: e.value, groupTitleView: v});
		}
		catch(error){ }
	}
	
	makeGroupTitleSuggestion(e){
		try{
			var v = upperCase(e.query);
			var d = this.state.groupTitleView.filter(function(obj){
				return((upperCase(obj.grouptitle).indexOf(v)>-1) || (upperCase(obj.groupcode).indexOf(v)>-1));
			});
			this.setState({ groupTitleSuggest: d });
		}
		catch(error){ }
	}
	
	onSuggestGroupTitleSelect(e){
		try{
			this.setState({selectedGrpoupTitle: e.value});
		}
		catch(error){ }
	}
	
	getField(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	loadSelectedTtitles(){
		try{
			var p = this.props.editData;
			var t = this.state.titles.filter(function(obj){
				return(obj.titleid==p.titleid);
			});
			var gt = this.state.groupTitles.filter(function(obj){
				return(obj.id==p.groupid);
			});
			
			var d = this.state.groupTitles.filter(function(obj){
				return(obj.titleid==p.titleid);
			});
			this.setState({selectedTitle: t[0], selectedGrpoupTitle: gt[0], groupTitleSuggest: d});
		}
		catch(error){ }
	}
	
	render(){
		
		const pageTitle = this.state.menuCode + this.state.menuTitle;
		
	  return (
	  <div>
			<form action="" method="post" encType="multipart/form-data" id="frm_ssr_add_GroupTitle" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__titleId" value={this.state.selectedTitle.titleid} />
				<input type="hidden" name="i__titleCode" value={this.state.selectedTitle.titlecode} />
				
				<input type="hidden" name="i__groupId" value={this.state.selectedGrpoupTitle.id} />
				<input type="hidden" name="i__groupCode" value={this.state.selectedGrpoupTitle.groupcode} />
				
				<Row  >
					<Col md={2} >
						<Label>Type <span className="required">*</span> </Label>
						<Input type="select" size="sm" name="i__type" required >
							<option value="qnm" selected={this.getField('type')=='qnm' ? 'selected' : ''} > QNM </option>
							<option value="qlm" selected={this.getField('type')=='qlm' ? 'selected' : ''}  > QLM </option>
						</Input>
					</Col>
					<Col md={5} >
						<Label>Title <span className="required">*</span> </Label>
						<AutoComplete 
							placeholder="Title" 
							field="displaytitle" 
							inputClassName="form-control form-control-sm" 
							value={this.state.selectedTitle}		
							onChange={(e) => this.setState({selectedTitle: e.value})}
							suggestions={this.state.titleSuggest} 
							completeMethod={this.makeTitleSuggestion.bind(this)}
							onSelect={this.onSuggestTitleSelect}
							style={{width: '100%'}} 
						/>
					</Col>
					<Col md={5} >
						<Label>Group Title <span className="required">*</span> </Label>
						<AutoComplete 
							placeholder="Group Title" 
							field="displaygrouptitle" 
							inputClassName="form-control form-control-sm" 
							value={this.state.selectedGrpoupTitle}
							onChange={(e) => this.setState({selectedGrpoupTitle: e.value})}
							suggestions={this.state.groupTitleSuggest} 
							completeMethod={this.makeGroupTitleSuggestion.bind(this)}
							onSelect={this.onSuggestGroupTitleSelect}
							style={{width: '100%'}} 
						/>
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={2} >
						<Label>Code <span className="required">*</span> </Label>
						<Input type="text" size="sm" required name="i__code" onChange={(e)=>{this.setState({menuCode: e.target.value})}} defaultValue={this.getField('code')} />
					</Col>
					<Col md={5} >
						<Label>Menu Title <span className="required">*</span> </Label>
						<Input type="text" size="sm" required name="i__menuTitle" onChange={(e)=>{this.setState({menuTitle: e.target.value})}}  defaultValue={this.getField('menutitle')} />
					</Col>
					<Col md={5} >
						<Label>Page Title <span className="required">*</span> </Label>
						<Input type="text" size="sm" required name="i__pageTitle" defaultValue={pageTitle} defaultValue={this.getField('pagetitle')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<Label>Page Content <span className="required">*</span> </Label>
							<CKEditor 
							name='add_ssr_page_content' 
							config={{ extraPlugins: 'print,font', 
							height: '450', 
							startupShowBorders: 'false', 
							allowedContent: true, 
							extraAllowedContent: '*(*);*{*}' }} 
							data={this.state.pageData} 
							onChange={this.onContentEditorChange} />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<div className="pretty p-icon p-smooth">
							<input type="checkbox" name="i__showInMenu" checked={this.state.showInMenu} onChange={()=>{this.setState({showInMenu: !this.state.showInMenu})}}  />
							<div className="state p-primary">
								<i className="icon ion-ios7-checkmark-empty"></i>
								<label>Show In Menu</label>
							</div>
						</div>
					</Col>
					<Col md={8} >
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnSave!='Update'} >
								Cancel
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave!='Update' ? <span className="ion-loading-a"></span> : null}
								{this.state.btnSave}
							</Button>
						</div>
					</Col>
				</Row>
				
				<input type="hidden" name="i__pageData" value={this.state.pageData} />
				
			</form>			
			
				
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
			</ModalFileBrowser>	
			
		</div>
	  );
	}

}

export default EditReport;
