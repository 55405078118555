import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { Nav, NavItem } from 'reactstrap';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}


	render() {

		return (
			<div className="page-sub-nav page-sub-nav-fixed">
				<Nav pills>
					<NavItem>
						<NavLink to="/app/users" exact >
							Webamin Users
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="/app/users/deptadmin" exact >
							Department Admin
						</NavLink>
					</NavItem>
				</Nav>
			</div>
		);
	}

}
export default Menu;
