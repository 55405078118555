import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version} from '../../../../variables';
import API from '../../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../../config';

const styles = {
	tdStyle: {
		borderBottom: '1px solid #f3f3f3',
		padding: '8px',
		cursor: 'pointer',
	}
}

class Add extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  btnText: 'Save',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({ showLoader: true, btnText: 'Updating..' });
			API.post('students/saveoldattendance',$("#frmNewAttn_Certificate").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					document.getElementById("frmNewAttn_Certificate").reset();
					notify.show(res['data'].msg,'success');
					this.setState({ showLoader: false, btnText: 'Save' });
					if(this.props.afterFinish) this.props.afterFinish();
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({ showLoader: false, btnText: 'Save' });
				}
			});
		}
		catch(error){
			this.setState({ showLoader: false, btnText: 'Save' });
		}
	}
	
	
	render(){
		
	  return (
	  <div>
			<form action="" method="post" encType="multipart/form-data" id="frmNewAttn_Certificate" onSubmit={this.handleFormSubmit} >
				<Row>
					<Col md={4} >
						<Label >Student Register Number</Label>
					</Col>
					<Col md={8} >
						<Input type="text" size="sm" name="i__regno" placeholder="Student Register Number" />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={4} >
						<Label >Name of the Student <span  className="required" >*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="text" size="sm" name="i__name" required placeholder="Student Name" />
					</Col>
				</Row>
				<Row  className="mt-10" >
					<Col md={4} >
						<Label >Mobile Number</Label>
					</Col>
					<Col md={8} >
						<Input type="text" size="sm" name="i__mobile" placeholder="Contact Mobile Number" />
					</Col>
				</Row>
				
				<Row  className="mt-10" >
					<Col md={4} >
						<Label >Academic Year (Batch)</Label>
					</Col>
					<Col md={8} >
						<Input type="text" size="sm" name="i__academic"  />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={4} >
						<Label >Class & Course Duration <span  className="required" >*</span></Label>
					</Col>
					<Col md={4} >
						<Input type="text" size="sm" name="i__class" required placeholder="Class" />
					</Col>
					<Col md={4} >
						<Input type="text" size="sm" name="i__duration" required placeholder="Course Duration" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label >No.of Working Days <span  className="required" >*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="number" size="sm" name="i__workingdays" required placeholder="" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label >No.of Days attended <span  className="required" >*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="number" size="sm" name="i__daysattended" required placeholder="" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label >Percentage <span  className="required" >*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="number" size="sm" name="i__percentage" required placeholder="" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label >Entry Date <span  className="required" >*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="date" size="sm" name="i__entrydate" required placeholder="" />
					</Col>
				</Row>
				
				<Row>
					<Col md={12} >
						<hr />
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnText!='Save'}  onClick={this.props.atClose} >
								<span className="ion-close"></span> Cancel
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
								<span className="ion-checkmark"></span> {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
				
			</form>			
	  </div>
	  );
	}

}

export default Add;
