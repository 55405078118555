import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls} from '../../../config';
import {BlockLoader, LineLoader} from '../../../elements';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';

class EditBatch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save',
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			selectedFileUrl: '',
			showFileBrowser: false,
			showAddModal: false,
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
	}

	componentDidMount(){
		var fileUrl = this.getFieldValue('logopath');
		this.setState({selectedUrl: fileUrl, selectedFileUrl: Api.ImageUploadPath + fileUrl, i__startYear: this.getFieldValue('startyear'), i__endYear: this.getFieldValue('endyear')});
	}
	
	handleFileBrowserClick(files){
		try{
			
			console.log(files);
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				selectedFileUrl: files[0].path
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}

	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/course/batch/update', $("#frmEditBatch").serialize()).then(res=>{
				if(res['data'].status=='1'){
					//document.getElementById("addAcDeptForm").reset();
					notify.show(res['data'].message,'success');
					this.setState({btnText: 'Save', showLoader: false});
					//this.loadFileList();
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save', showLoader: false});
					
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false, btnText: 'Save'});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	handleYearChange(e){
		this.setState({[e.target.name] : e.target.value});
	}
	
	render(){
		

		
	  return (
		<div>
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<form action="" method="post" encType="multipart/form-data" id="frmEditBatch" onSubmit={this.handleFormSubmit}  >
			
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
				<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
				
				<Row >
					<Col md={5}>
						<Label>Type <span className="text-danger">*</span></Label>
					</Col>
					<Col md={7} >
						<Input type="select" size="sm" required name="i__courseType" >
							<option value="UG" selected={this.getFieldValue('coursetype')=='UG' ? 'selected' : ''} > UG </option>
							<option value="PG" selected={this.getFieldValue('coursetype')=='PG' ? 'selected' : ''} > PG </option>
							<option value="MPhil" selected={this.getFieldValue('coursetype')=='MPhil' ? 'selected' : ''} > MPhil </option>
							<option value="PhD" selected={this.getFieldValue('coursetype')=='PhD' ? 'selected' : ''} > PhD </option>
						</Input>
					</Col>
				</Row>
				
				<Row className="mt-15"  > 
					<Col md={5}>
						<Label>Start Year <span className="text-danger">*</span></Label>
					</Col>
					<Col md={7} >
						<Input type="number" name="i__startYear" required size="sm" onChange={this.handleYearChange} defaultValue={this.getFieldValue('startyear')} />
					</Col>
				</Row>
				
				<Row className="mt-15"  >
					<Col md={5}>
						<Label>End Year <span className="text-danger">*</span></Label>
					</Col>
					<Col md={7} >
						<Input type="number" name="i__endYear" required size="sm" onChange={this.handleYearChange} defaultValue={this.getFieldValue('endyear')} />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={5}>
						<Label>Batch Value <span className="text-danger">*</span></Label>
					</Col>
					<Col md={7} >
						<Input type="text" name="i__batch" readOnly size="sm" value={this.state.i__startYear +'-'+ this.state.i__endYear} />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={5}>
						<Label>Display Order <span className="text-danger"></span></Label>
					</Col>
					<Col md={7} >
						<Input type="number" name="i__displayOrder" size="sm" defaultValue={this.getFieldValue('ordernum')} />
					</Col>
				</Row>
				
			
				<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnText!='Save'} >
								<i className="ion-android-close" /> &nbsp; Reset
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
								{this.state.btnText=='Save' ? <i className="ion-android-checkmark" /> : <i className="ion-loading-a" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			
			</form>	
		</div>
	  );
	}

}

export default EditBatch;
