import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import Select from 'react-select';
import Menu from './Menu';

class AddSubject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			selectedDept: getls('user', 'deptid'),
			saveText: 'Save',
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSelectChange(name, e) {
		this.setState({ [name]: e });
	}

	handlePsoSelectChange(name, e) {
		this.setState({ [name]: e });
	}

	handleSubmit(e) {
		try {
			e.preventDefault();
			this.setState({ showLoader: true, saveText: 'Saving' });
			API.post('v1/savesubject', $("#frm_add_subject").serialize()).then(response => {
				if (response['data'].status == '1') {
					this.setState({ showLoader: false, saveText: 'Save' });
					document.getElementById('frm_add_subject').reset();
					notify.show(response['data'].message, 'success');
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	getPsoSelectOptions() {
		try {

			var d = [
				{ value: '1', label: 'PSO 1' },
				{ value: '2', label: 'PSO 2' },
				{ value: '3', label: 'PSO 3' },
				{ value: '4', label: 'PSO 4' },
				{ value: '5', label: 'PSO 5' },
				{ value: '6', label: 'PSO 6' },
				{ value: '7', label: 'PSO 7' }
			];
			return d;
		}
		catch (error) {
			return [];
		}
	}

	getPoSelectOptions() {
		try {

			var d = [
				{ value: '1', label: 'PO 1' },
				{ value: '2', label: 'PO 2' },
				{ value: '3', label: 'PO 3' },
				{ value: '4', label: 'PO 4' },
				{ value: '5', label: 'PO 5' },
				{ value: '6', label: 'PO 6' },
				{ value: '7', label: 'PO 7' }
			];
			return d;
		}
		catch (error) {
			return [];
		}
	}

	render() {

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >

					<form id="frm_add_subject" action="" method="post" onSubmit={this.handleSubmit}>
						<input type="hidden" name="i__userId" value={getls('user', 'id')} />
						<input type="hidden" name="i__co1_pso" value={JSON.stringify(this.state.co1_pso)} />
						<input type="hidden" name="i__co1_po" value={JSON.stringify(this.state.co1_po)} />
						<input type="hidden" name="i__co2_pso" value={JSON.stringify(this.state.co2_pso)} />
						<input type="hidden" name="i__co2_po" value={JSON.stringify(this.state.co2_po)} />
						<input type="hidden" name="i__co3_pso" value={JSON.stringify(this.state.co3_pso)} />
						<input type="hidden" name="i__co3_po" value={JSON.stringify(this.state.co3_po)} />
						<input type="hidden" name="i__co4_pso" value={JSON.stringify(this.state.co4_pso)} />
						<input type="hidden" name="i__co4_po" value={JSON.stringify(this.state.co4_po)} />
						<input type="hidden" name="i__co5_pso" value={JSON.stringify(this.state.co5_pso)} />
						<input type="hidden" name="i__co5_po" value={JSON.stringify(this.state.co5_po)} />

						<Row>
							<Col md={12} >
								<Row>
									<Col md={2} ><Label>Subject Code <span className="required">*</span> </Label></Col>
									<Col md={3}>
										<Input type="text" size="sm" name="i__subCode" id="i__subCode" required placeholder="Subject Code" />
									</Col>
									<Col md={2} ><Label>Subject Name <span className="required">*</span> </Label></Col>
									<Col md={5}>
										<Input type="text" size="sm" name="i__subject" required style={{ textTransform: 'uppercase' }} placeholder="Subject Name" />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mt-20">
							<Col md={12}>
								<Table>
									<thead>
										<tr>
											<th>CO</th>
											<th>PSO</th>
											<th>PO</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Co 1</td>
											<td>
												<Select isMulti
													onChange={this.handlePsoSelectChange.bind(this, 'co1_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti
													onChange={this.handleSelectChange.bind(this, 'co1_po')}
													options={this.getPoSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 2</td>
											<td>
												<Select isMulti
													onChange={this.handlePsoSelectChange.bind(this, 'co2_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti
													onChange={this.handleSelectChange.bind(this, 'co2_po')}
													options={this.getPoSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 3</td>
											<td>
												<Select isMulti
													onChange={this.handlePsoSelectChange.bind(this, 'co3_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti
													onChange={this.handleSelectChange.bind(this, 'co3_po')}
													options={this.getPoSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 4</td>
											<td>
												<Select isMulti
													onChange={this.handlePsoSelectChange.bind(this, 'co4_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti
													onChange={this.handleSelectChange.bind(this, 'co4_po')}
													options={this.getPoSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 5</td>
											<td>
												<Select isMulti
													onChange={this.handlePsoSelectChange.bind(this, 'co5_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti
													onChange={this.handleSelectChange.bind(this, 'co5_po')}
													options={this.getPoSelectOptions()} />
											</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col md={11}></Col>
							<Col md={1}>
								<Button type="submit" color="primary" disabled={this.state.saveText != 'Save' ? true : false}>
									{this.state.saveText != 'Save' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
								</Button>
							</Col>
						</Row>
					</form>

				</div>

			</div>
		);
	}

}

export default AddSubject;
