import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName} from '../../../variables';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import FileBrowser from '../filebrowser';

const chartColors = {
      red: 'rgb(233, 30, 99)',
      danger: 'rgb(233, 30, 99)',
      dangerTransparent: 'rgba(233, 30, 99, .8)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 180, 0)',
      green: 'rgb(34, 182, 110)',
      blue: 'rgb(68, 159, 238)',
      primary: 'rgb(68, 159, 238)',
      primaryTransparent: 'rgba(68, 159, 238, .8)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',

      primaryShade1: 'rgb(68, 159, 238)',
      primaryShade2: 'rgb(23, 139, 234)',
      primaryShade3: 'rgb(14, 117, 202)',
      primaryShade4: 'rgb(9, 85, 148)',
      primaryShade5: 'rgb(12, 70, 117)'
    };


class FileManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			smsStatistic: [],
			studentsBatchCount: [],
			batchList: [],
			courseList: [
				{value:'regular',type:'ug',text:'UG REGULAR'},{value:'self',type:'ug',text:'UG SELF'},
				{value:'regular',type:'pg',text:'PG REGULAR'},{value:'self',type:'pg',text:'PG SELF'},
				{value:'regular',type:'mphil',text:'MPHIL REGULAR'},{value:'self',type:'mphil',text:'MPHIL SELF'}],
		}
		
	}
	
	componentDidMount(){
		
		//this.loadSmsStatistic();
		//this.loadStuBatchCount();
	}
	
	loadSmsStatistic(){
		try{
			this.setState({showSmsLoader:true});
			API.get('message/todaystatisticreport').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({smsStatistic: res['data'].data});
				}
				this.setState({showSmsLoader:false});
			});
		}
		catch(error){
			console.log(error);
			this.setState({showSmsLoader:false});
		}
	}
	
	loadStuBatchCount(){
		try{
			this.setState({showStuBatchLoader:true});
			API.get('studentsreport/curbatchstucount').then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					var s = groupByMultiple(d,function(obj){
						return [obj.batch];
					});
					//console.log(s);
					this.setState({studentsBatchCount: res['data'].data,batchList: s});
				}
				this.setState({showStuBatchLoader:false});
			});
		}
		catch(error){
			console.log(error);
			this.setState({showStuBatchLoader:false});
		}
	}
	
	getStudentsCount(course,batch,gender){
		try{
			var d = this.state.studentsBatchCount;
			var s = d.filter(function(obj){
				return(
					(upperCase(obj.admisiontype)==upperCase(course.value)) &&
					(upperCase(obj.coursetype)==upperCase(course.type)) &&
					(upperCase(obj.gender)==upperCase(gender)) &&
					(upperCase(obj.batch)==upperCase(batch)));
			});
			
			return Object.keys(s).length>0 ? s[0].count : '';
		}
		catch(error){
			return '';
		}
	}

	render(){
		
	if(this.state.isRedirect){
		return(<Redirect to="/app/home" />);
	}
	
	const heroStyles = {
		padding: '50px 0 70px'
	};
	
	var dLabels = [];
	var dData = [];
	var smsTotalCount = 0;
	let smsRow = this.state.smsStatistic.map((item,i)=>{
					smsTotalCount += parseFloat(item.count);
					dLabels.push(item.reportstatus==null ? 'Report Pending' : item.reportstatus);
					dData.push(item.count);
					return <tr key={i} >
						<td>{item.reportstatus}</td>
						<td align="center" >{item.count}</td>
					</tr>;
				})
				
	const donutData = {
      labels: dLabels,
      datasets: [
        {
          data: dData,
          backgroundColor: [
            chartColors.primaryShade1,
            chartColors.primaryShade2,
            chartColors.primaryShade3
          ],
          hoverBackgroundColor: [
            chartColors.primaryShade4,
            chartColors.primaryShade4,
            chartColors.primaryShade4
          ]
        }
      ]
    };
	
	  return (
		<div>
			
			
			<FileBrowser />
			
		</div>
	  );
	}

}

export default FileManager;
