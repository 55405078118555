import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, getAscSortOrder} from '../../../config';
import './A5Print.css';

class FeeCategoryA5Print extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			editData: [],			
		}
	}
	
	componentDidMount(){
		if(this.props.autoPrint)
			this.printDocument('frm_ag_Feecat_Print');
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	getField(fieldName){
		try{
			var d = this.props.printData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	getYearBySem(sem){
		try{
			if(sem=='1' || sem=='2') return '1';
			else if(sem=='3' || sem=='4') return '2';
			else if(sem=='5' || sem=='6') return '3';
			else return '';
		}
		catch(error){ return '';}
	}
	
	render(){

	var logo = getls('company','logo');
	
	  return (
	  <div style={{marginLeft:'auto', marginRight: 'auto'}} >
			
			<div style={{background:'#fff' }} id="frm_ag_Feecat_Print" >
				<page size="A5" >
				<table width="100%" align="center" style={{borderCollpase:'collapse'}} >
					<tbody style={{borderCollpase:'collapse'}} >
					<tr style={{ borderCollpase:'collapse'}} >
						<td colSpan="4" align="center" style={{border:'1px solid black', borderCollpase:'collapse'}}  >
							<div style={{backgroundColor:'black', width: '60px', color:'#fff', fontWeight:'600'}} >Receipt</div>
								{logo.length > 3 ? <div>
								<img src={logo} style={{maxHeight:'90px', position:'absolute', left: '30px'}} />
								</div> : null}
							<div style={{marginTop:'10px', fontWeight:'800', fontSize:'16px'}} >{upperCase(getls('company','companyname'))}, {upperCase(getls('company','city'))}</div>
							<div style={{fontSize:'14px', fontWeight:'600', margin:'10px'}} >(Autonomous)</div>
							<div style={{marginBottom:'10px'}} >(Under the Management of H.R & C.E Dept.)</div>
							
						</td>
					</tr>
					<tr style={{ borderCollpase:'collapse'}} >
						<td width="140" height="40"  style={{paddingLeft: '30px', fontWeight:'600', borderLeft:'1px solid black',borderCollpase:'collapse'}} >Bill No</td>
						<td> : {this.getField('voucherid')} </td>
						<td width="80" style={{fontWeight:'600'}} >Bill Date</td>
						<td width="200" style={{borderRight:'1px solid black', borderCollpase:'collapse'}}  > : {momentDate(this.getField('voucherdate'),'DD-MM-YYYY')}</td>
					</tr>
					<tr style={{ borderCollpase:'collapse'}} >
						<td width=""  height="40"  style={{paddingLeft: '30px', fontWeight:'600',borderLeft:'1px solid black',borderCollpase:'collapse'}} >Name</td>
						<td> : {this.getField('name')} </td>
						<td width="" style={{fontWeight:'600'}} >Year</td>
						<td width="200" style={{borderRight:'1px solid black', borderCollpase:'collapse'}} > : {this.getYearBySem(this.getField('studysem'))} </td>
					</tr>
					<tr style={{borderCollpase:'collapse'}} >
						<td width=""  height="40"  style={{paddingLeft: '30px', fontWeight:'600', borderBottom:'1px solid black', borderCollpase:'collapse', borderBottom:'1px solid black',borderLeft:'1px solid black'}} >Admission No</td>
						<td style={{ borderBottom:'1px solid black', borderCollpase:'collapse'}} > : {this.getField('admno')}</td>
						<td width="" style={{fontWeight:'600',  borderBottom:'1px solid black', borderCollpase:'collapse'}} >Major</td>
						<td width="200" style={{ borderBottom:'1px solid black',borderRight:'1px solid black', borderCollpase:'collapse'}} > : {this.getField('course')}</td>
					</tr>
					<tr style={{borderCollpase:'collapse'}} >
						<td colSpan="2" height="90" align="" style={{paddingLeft:'50px',borderBottom:'1px solid black', borderLeft:'1px solid black',  borderCollpase:'collapse'}} > {this.getField('partyname')}</td>
						<td colSpan="2" style={{borderBottom:'1px solid black', borderRight:'1px solid black', borderCollpase:'collapse'}} > : &emsp; {this.getField('amount')} </td>
					</tr>
					<tr style={{borderCollpase:'collapse'}} >
						<td colSpan="" height="60" align="" style={{paddingLeft:'50px', borderBottom:'1px solid black', borderLeft:'1px solid black',  borderCollpase:'collapse'}} > Signature</td>
						<td colSpan="3" align="center" style={{fontSize:'20px', fontWeight:'600', borderBottom:'1px solid black', borderRight:'1px solid black', borderCollpase:'collapse'}} > 
							Total - Rs.{this.getField('amount')}
						</td>
					</tr>
					</tbody>
				</table>
				</page>
			</div>
						
		</div>
	  );
	}

}

export default FeeCategoryA5Print;
