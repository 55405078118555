import ErrorPage from './pages/404';

import Home from './app/home';
import FileManager from './app/home/FileManager';
import Pages from './app/pages';
import AddPage from './app/pages/AddPage';
import EditPage from './app/pages/EditPage';
import PageCategory from './app/pages/PageCategory';
import Newsandevents from './app/Newsandevents';
import AddNewsandEvents from './app/Newsandevents/AddNewsandEvents';
import EditNewsandEvents from './app/Newsandevents/EditNewsandEvents';
import Announcement from './app/Newsandevents/Announcement';
import MenuList from './app/menulist';
import MenuOrder from './app/menulist/menuorder';
import Users from './app/Users';
import DepartmentAdminUsers from './app/Users/DepartmentAdminUsers';

import Academics from './dept/academics';
import DeptAbout from './dept/academics/DeptAbout';
import DeptVision from './dept/academics/DeptVision';
import DeptBos from './dept/academics/DeptBos';
import DeptSyllabus from './dept/academics/DeptSyllabus';
import DeptCourseOutcomes from './dept/academics/DeptCourseOutcomes';
import DeptTeachingPlan from './dept/academics/DeptTeachingPlan';
import DeptEmaterial from './dept/academics/DeptEmaterial';
import DeptAwards from './dept/academics/DeptAwards';
import DeptHighlights from './dept/academics/DeptHighlights';
import DeptActivities from './dept/academics/DeptActivities';
import DeptSlider from './dept/academics/DeptSlider';
import DeptAlumni from './dept/academics/DeptAlumni';

import Research from './dept/research';
import Publications from './dept/research/Publications';
import DeptMou from './dept/research/DeptMou';
import ResearchFacility from './dept/research/ResearchFacility';
import ResearchCentre from './dept/research/ResearchCentre';

import EditAcademics from './app/academics/EditAcademics';

import SliderPage from './app/sliderpage';
import Gallery from './app/Gallery';
import VideoGallery from './app/videogallery';
import MyProfile from './app/profile';
import Pso from './app/Pso';
import AddPso from './app/Pso/AddPso';
import BestofPso from './app/Pso/BestofPso';
import Subject from './app/Subject';
import AddSubject from './app/Subject/AddSubject';
import EditSubject from './app/Subject/EditSubject';
import ExternalMark from './app/Pso/ExternalMark';

import NaacSsr from './app/NaacSsr';
import Titles from './app/NaacSsr/Titles';
import GroupTitles from './app/NaacSsr/GroupTitles';

import Settings from './app/settings';
import LoginUserProfile from './app/settings/LoginUserProfile';

import Party from './accounts/Party';
import AccountCategory from './accounts/Party/AccountCategory';
import Payments from './accounts/Payments';
import PaymentEntry from './accounts/Payments/PaymentEntry';
import Receipts from './accounts/Receipts';
import ReceiptEntry from './accounts/Receipts/ReceiptEntry';
import DayBook from './accounts/Reports/DayBook';
import CashBook from './accounts/Reports/CashBook';
import PartyWise from './accounts/Reports/PartyWise';

import FeeSettings from './fees/FeeSettings';
import FeeAccountList from './fees/FeeSettings/FeeAccountList';
import FeeOthersSettings from './fees/FeeSettings/FeeOthersSettings';
import FeePayment from './fees/FeePayment';
import HostelA5BillPrint from './fees/FeePayment/HostelA5BillPrint';
import FeePaymentList from './fees/FeePayment/FeePaymentList';
import FeeReports from './fees/FeeReports';
import BreakageDaily from './fees/FeeReports/BreakageDaily';
import FeesMonthlyReport from './fees/FeeReports/FeesMonthlyReport';
import FeeYearlyReport from './fees/FeeReports/FeeYearlyReport';
import BreakageMonthlyReport from './fees/FeeReports/BreakageMonthlyReport';
import FeeDepartmentReport from './fees/FeeReports/FeeDepartmentReport';
import CompanyFeeSettings from './fees/FeeSettings/CompanyFeeSettings';

import Oldstudentsattendancecertificate from './app/students/oldstudentsattendancecertificate';
import Studentslist from './app/students/studentslist';

import AcaDepartments from './academic/AcaDepartments';
import AcaCourseCategory from './academic/AcaCourseCategory';
import AcaCourse from './academic/AcaCourse';
import AcaBatchList from './academic/AcaBatchList';
import AcaStaffs from './academic/AcaStaffs';

import CKEditor from 'ckeditor4-react';
CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.13.1/full/ckeditor.js';

const pageList = [
  { name: 'Dashboard', path: '/app/home', component: Home },
  { name: 'Profile', path: '/app/settings/myprofile', component: LoginUserProfile },
  { name: 'File Manager', path: '/app/filemanager', component: FileManager },
  { name: 'Pages', path: '/app/pages', component: Pages, allowed: ['webadmin', 'siteadmin'] },
  { name: 'News & Events', path: '/app/newsandevents/events', component: Newsandevents, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Add Event', path: '/app/newsandevents/addevent', component: AddNewsandEvents, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Edit Event', path: '/app/newsandevents/editevent/:id/:slug', component: EditNewsandEvents, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Announcement', path: '/app/newsandevents', component: Announcement, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Add Page', path: '/app/pages/add', component: AddPage, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Edit Page', path: '/app/pages/edit/:id/:url', component: EditPage, allowed: ['webadmin', 'siteadmin'] },
  { name: 'Page Categories', path: '/app/pages/categories', component: PageCategory },
  { name: 'Users', path: '/app/users', component: Users, allowed: ['webadmin']  },
  { name: 'Users', path: '/app/users/deptadmin', component: DepartmentAdminUsers, allowed: ['webadmin']  },
  { name: 'Menus', path: '/app/menus', component: MenuList, allowed: ['webadmin', 'siteadmin']  },
  { name: 'Menu Order', path: '/app/menus/order', component: MenuOrder , allowed: ['webadmin', 'siteadmin'] },
  { name: 'Academics', path: '/dept/academics', component: Academics , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'About Department', path: '/dept/academics/about', component: DeptAbout , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'About Department', path: '/dept/academics/slider', component: DeptSlider , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Vision & Mission', path: '/dept/academics/vision', component: DeptVision , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'BOS', path: '/dept/academics/bos', component: DeptBos , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Syllabus', path: '/dept/academics/syllabus', component: DeptSyllabus , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'PSO', path: '/dept/academics/pso', component: DeptCourseOutcomes , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Teachin Plan', path: '/dept/academics/teachingplan', component: DeptTeachingPlan , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'E-Materials', path: '/dept/academics/ematerials', component: DeptEmaterial , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Awards', path: '/dept/academics/awards', component: DeptAwards , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Highlights', path: '/dept/academics/highlights', component: DeptHighlights , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Activities', path: '/dept/academics/activities', component: DeptActivities , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Alumni', path: '/dept/academics/alumni', component: DeptAlumni , allowed: ['deptadmin', 'researchadmin'] },
  { name: 'Research', path: '/dept/research', component: Research , allowed: ['researchadmin'] },
  { name: 'Publications', path: '/dept/research/publications', component: Publications , allowed: ['researchadmin'] },
  { name: 'Research Centre', path: '/dept/research/researchcentre', component: ResearchCentre , allowed: ['researchadmin'] },
  { name: 'MOU', path: '/dept/research/mou', component: DeptMou , allowed: ['researchadmin'] },
  { name: 'Facility', path: '/dept/research/facility', component: ResearchFacility , allowed: ['researchadmin'] },
  { name: 'Academics', path: '/app/academics/edit/:id?', component: EditAcademics , allowed: ['admin'] },
  { name: 'Sliders', path: '/app/manageslider', component: SliderPage , allowed: ['webadmin', 'siteadmin'] },
  { name: 'Gallery', path: '/app/gallery', component: Gallery , allowed: ['webadmin', 'siteadmin'] },
  { name: 'Video Gallery', path: '/app/video-gallery', component: VideoGallery , allowed: ['webadmin', 'siteadmin'] },
  { name: 'PSO', path: '/app/program-specific-outcomes', component: Pso, allowed: ['staff'] },
  { name: 'Add PSO', path: '/app/program-specific-outcomes/add', component: AddPso, allowed: ['staff'] },
  { name: 'Best of PSO', path: '/app/program-specific-outcomes/bestofpso', component: BestofPso, allowed: ['staff'] },
  { name: 'MyProfile', path: '/app/myprofile/:id?', component: MyProfile, allowed: ['staff'] },
  { name: 'Settings', path: '/app/settings', component: Settings , allowed: ['webadmin', 'siteadmin'] },
  { name: 'SSR Report', path: '/app/naac/ssr', component: NaacSsr , allowed: ['naacadmin'] },
  { name: 'SSR Titles', path: '/app/naac/ssr/titles', component: Titles , allowed: ['naacadmin'] },
  { name: 'SSR Group Titles', path: '/app/naac/ssr/grouptitles', component: GroupTitles , allowed: ['naacadmin'] },
  { name: 'Party Accounts', path: '/app/accounts', component: Party , allowed: ['accountant'] },
  { name: 'Accounts Category', path: '/app/accounts/category', component: AccountCategory , allowed: ['accountant'] },
  { name: 'Payments', path: '/app/account-payments', component: Payments , allowed: ['accountant'] },
  { name: 'Payment Entry', path: '/app/account-payments/new', component: PaymentEntry , allowed: ['accountant'] },
  { name: 'Receipts', path: '/app/account-receipts', component: Receipts , allowed: ['accountant'] },
  { name: 'Receipt Entry', path: '/app/account-receipts/new', component: ReceiptEntry , allowed: ['accountant'] },
  { name: 'Day Book', path: '/app/accounts-report', component: DayBook , allowed: ['accountant'] },
  { name: 'Cash Book', path: '/app/accounts-report/cashbook', component: CashBook , allowed: ['accountant'] },
  { name: 'Party wise Report', path: '/app/accounts-report/partywise', component: PartyWise , allowed: ['accountant'] },
  { name: 'Fee Category', path: '/fees/settings', component: FeeSettings , allowed: ['feecollector'] },
  { name: 'Account List', path: '/fees/settings/accounts', component: FeeAccountList , allowed: ['feecollector'] },
  { name: 'Other Settings', path: '/fees/settings/others', component: FeeOthersSettings , allowed: ['feecollector'] },
  { name: 'Fee Payment', path: '/fees/category/payment', component: FeePayment , allowed: ['feecollector'] },
  { name: 'Payment List', path: '/fees/category/payments', component: FeePaymentList , allowed: ['feecollector'] },
  { name: 'Payment List', path: '/fees/category/payments/:id', component: HostelA5BillPrint , allowed: ['feecollector'] },
  { name: 'Daily Report', path: '/fees/category/reports', component: FeeReports , allowed: ['feecollector'] },
  { name: 'Monthly Report', path: '/fees/category/reports/monthly', component: FeesMonthlyReport , allowed: ['feecollector'] },
  { name: 'Yearly Report', path: '/fees/category/reports/yearly', component: FeeYearlyReport , allowed: ['feecollector'] },
  { name: 'Breakage Daily Report', path: '/fees/category/reports/breakage-daily', component: BreakageDaily , allowed: ['feecollector'] },
  { name: 'Breakage Monthly Report', path: '/fees/category/reports/breakage-monthly', component: BreakageMonthlyReport , allowed: ['feecollector'] },
  { name: 'Department Wise Report', path: '/fees/category/reports/deptwisereport', component: FeeDepartmentReport , allowed: ['feecollector'] },
  { name: 'Fee Assigning', path: '/fees/settings/feeassign', component: CompanyFeeSettings , allowed: ['feecollector'] },
  { name: 'Students List', path: '/app/reports/studentslist', component: Studentslist },
  { name: 'Subject', path: '/app/subject', component: Subject, allowed: ['staff'] },
  { name: 'Add Subject', path: '/app/subject/add', component: AddSubject, allowed: ['staff'] },
  { name: 'Edit Subject', path: '/app/subject/edit/:id', component: EditSubject, allowed: ['staff'] },
  { name: 'External Mark Entry', path: '/app/program-specific-outcomes/external', component: ExternalMark, allowed: ['staff'] },
  { name: 'Course', path: '/app/academic', component: AcaCourse, allowed: ['webadmin'] },
  { name: 'Departments', path: '/app/academic/departments', component: AcaDepartments, allowed: ['webadmin'] },
  { name: 'Departments', path: '/app/academic/coursecategory', component: AcaCourseCategory, allowed: ['webadmin'] },
  { name: 'Academic Years', path: '/app/academic/batchlist', component: AcaBatchList, allowed: ['webadmin'] },
  { name: 'Staffs', path: '/app/academic/staffs', component: AcaStaffs, allowed: ['webadmin'] },
];

export default pageList;
