import axios from 'axios';
import {Api} from '../variables';
import {getlsItem} from './index.js';

const TOKEN = getlsItem('logintoken');

export default axios.create({
  baseURL: Api.Url,
  /*timeout: 1000,*/
  headers: {
	  /*'Content-Type': 'multipart/form-data',*/
	'Authorization-Token': TOKEN
  }
});