import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../config';

import Menu from './menu';
import AddCategory from './AddCategory';

class PageCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showAddModal: false,
		  showLoader: false,
		}
	}
	
	componentDidMount(){
	
	
	}
	
	render(){
		
	let sno = 1;
		
	  return (
	  <div>
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody>
						
						<div className="text-right" >
							<Button type="button" size="sm" color="primary" onClick={()=>{this.setState({showAddModal:true})}} >
								<span className="ion-android-add"></span> Add Category
							</Button>
						</div>
						<hr />
					</CardBody>
				</Card>
				
			</div>
			
			<Modal isOpen={this.state.showAddModal} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
						Add Page Category
					</ModalHeader>
					<ModalBody>
						
						<AddCategory 
							atClose={()=>{this.setState({showAddModal: false})}} 
						/>
						
					</ModalBody>
				</Modal>
				  
		
		</div>
	  );
	}

}

export default PageCategory;
