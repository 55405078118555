import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls} from '../../../config';
import {BlockLoader, LineLoader} from '../../../elements';
import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';

class EditCourse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save',
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			selectedFileUrl: '',
			showFileBrowser: false,
			showAddModal: false,
			courseType: [],
			courseDeptView: [],
			courseTypeView: [],
		}		
		this.handleDeptTypeChange = this.handleDeptTypeChange.bind(this);
		this.handleCourseTypeChange = this.handleCourseTypeChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount(){
		this.loadDepartments();
		this.loadCourseType();
	}

	loadDepartments(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/course/departments/all').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({courseDept: res['data'].data, showLoader: false});	
					this.handleDeptTypeChange(this.getFieldValue('deptype'));
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	loadCourseType(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/course/coursetype/all').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({courseType: res['data'].data, showLoader: false});	
					this.handleCourseTypeChange(this.getFieldValue('type'));
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleDeptTypeChange(e){
		try{
			var v = e;
			if(e.target)
				v = e.target.value;
			
			var d = this.state.courseDept.filter(function(obj){
				return(upperCase(obj.deptype)==upperCase(v));
			});
			this.setState({courseDeptView: d});
		}
		catch(error){ }
	}
	
	handleCourseTypeChange(e){
		try{
			var v = e;
			if(e.target)
				v = e.target.value;
			
			var d = this.state.courseType.filter(function(obj){
				return(upperCase(obj.type)==upperCase(v));
			});
			this.setState({courseTypeView: d});
		}
		catch(error){ }
	}
	
	
	handleFileBrowserClick(files){
		try{
			
			console.log(files);
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				selectedFileUrl: files[0].path
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}

	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/course/courses/update', $("#frmEditAcInfo").serialize()).then(res=>{
				if(res['data'].status=='1'){
					//document.getElementById("addAcDeptForm").reset();
					notify.show(res['data'].message,'success');
					this.setState({btnText: 'Save', showLoader: false});
					//this.loadFileList();
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save', showLoader: false});
					
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false, btnText: 'Save'});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		

		
	  return (
		<div>
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<form action="" method="post" encType="multipart/form-data" id="frmEditAcInfo" onSubmit={this.handleFormSubmit}  >
			
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
				<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
			
				<Row>
					<Col md={4} >
						<Label>Type <span className="text-danger">*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="select" size="sm" required name="i__deptType" onChange={this.handleDeptTypeChange} defaultValue={this.getFieldValue('deptype')} >
							<option value="" > -Select- </option>
							<option value="arts"> Arts </option>
							<option value="science"> Science </option>
							<option value="commerce"> Commerce </option>
						</Input>							
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label>Department <span className="text-danger">*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="select" size="sm" required name="i__deptName" >
							<option value=""> -Select- </option>
							{this.state.courseDeptView.map((item,i)=>{
								return <option key={i} value={item.id} selected={this.getFieldValue('department')== item.id ? 'selected' : ''} > {item.departmentname} </option>;
							})
							}
						</Input>							
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label>Category <span className="text-danger">*</span></Label>
					</Col>
					<Col md={8} >
						<Input type="select" size="sm" required name="i__courseType" onChange={this.handleCourseTypeChange} defaultValue={this.getFieldValue('type')} >
							<option value=""> -Select- </option>
							<option value="UG"> UG </option>
							<option value="PG"> PG </option>
							<option value="MPhil"> MPhil </option>
							<option value="PhD"> PhD </option>
						</Input>							
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label>Course Name <span className="text-danger">*</span></Label>
					</Col>
					<Col md={3} className="pr-0" >
						<Input type="select" size="sm" required name="i__degree" >
							<option value=""> -Select- </option>
							{this.state.courseTypeView.map((item,i)=>{
								return <option key={i} value={item.id} selected={this.getFieldValue('groupname')== item.id ? 'selected' : ''} > {item.name} </option>;
							})
							}
						</Input>	
					</Col>
					<Col md={5} >
						<Input type="text" name="i__courseName" required size="sm" defaultValue={this.getFieldValue('name')} />			
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label>Medium & Type <span className="text-danger">*</span></Label>
					</Col>
					<Col md={3} className="pr-0" >
						<Input type="select" size="sm" required name="i__medium" defaultValue={this.getFieldValue('medium')} >
							<option value=""> -Select- </option>
							<option value="tamil" > Tamil </option>
							<option value="english" > English </option>
						</Input>	
					</Col>
					<Col md={5} >
						<Input type="select" size="sm" required name="i__coursecategory" defaultValue={this.getFieldValue('coursetype')} >
							<option value=""> -Select- </option>
							<option value="aided" > Aided </option>
							<option value="un-aided" > Un-Aided </option>
						</Input>
					</Col>
				</Row>								
			
				
				<Row className="mt-10" >
					<Col md={4} >
						<Label>Order & Status <span className="text-danger">*</span></Label>
					</Col>
					<Col md={3} className="pr-0" >
						<Input type="number" size="sm" name="i__displayOrder" min="1" max="99" defaultValue={this.getFieldValue('ordernum')} />
					</Col>
					<Col md={5} >
						<Input type="select" size="sm" required name="i__status" defaultValue={this.getFieldValue('activestatus')} >
							<option value=""> -Select- </option>
							<option value="1" > Active </option>
							<option value="0" > In-Active </option>
						</Input>
					</Col>
				</Row>	
				
				{/* <Row className="mt-15" >
					<Col md={5}>
						<Label>Type <span className="text-danger">*</span></Label>
					</Col>
					<Col md={7} >
						<Input type="select" size="sm" required name="i__courseType" >
							<option value="UG" selected={this.getFieldValue('type')=='UG' ? 'selected' : ''} > UG </option>
							<option value="PG" selected={this.getFieldValue('type')=='PG' ? 'selected' : ''} > PG </option>
							<option value="MPhil" selected={this.getFieldValue('type')=='MPhil' ? 'selected' : ''} > MPhil </option>
							<option value="PhD" selected={this.getFieldValue('type')=='PhD' ? 'selected' : ''} > PhD </option>
						</Input>
					</Col>
				</Row> */}
				
				<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnText!='Save'} >
								<i className="ion-android-close" /> &nbsp; Reset
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
								{this.state.btnText=='Save' ? <i className="ion-android-checkmark" /> : <i className="ion-loading-a" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			
			</form>	
		</div>
	  );
	}

}

export default EditCourse;
