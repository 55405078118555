import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {InputMask} from 'primereact/inputmask';

import Menu from './menu';

class Receipts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			fromDate: '',
			toDate: '',
			dataList: [],
			dataView: [],
			editData: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadVouchers = this.loadVouchers.bind(this);
	}
	
	componentDidMount(){
		var today = new Date();
		//var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
		this.setState({fromDate: date, toDate: date});
		this.loadVouchers();
	}
	
	loadVouchers(){
		try{
			
			this.setState({showLoader:true});
			var form = new FormData();
			form.append('type', 'receipt');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('fromdate', this.state.fromDate);
			form.append('todate', this.state.toDate);
			
			API.post('v1/accounts/vouchers', form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.categoryname).indexOf(v)>-1) 
					|| (upperCase(obj.acdebitname).indexOf(v)>-1)
					|| (upperCase(obj.accreditname).indexOf(v)>-1)
					|| (upperCase(obj.voucherno).indexOf(v)>-1));
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/accounts/partyaccount/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,i){
		this.setState({editData: item,showEditModal: true});
	}
	
	render(){
		
	let sno = 1;
	var voucherTotal = 0;
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody className="pb-0 mb-0" >
						<Row>
							<Col md={4} >
								<div className="input-group">
									<Input type="text" size="sm" placeholder="Search by Voucher No, From, To (OR) Category" onChange={this.handlePageSearch} />
									<span className="input-group-addon" >
										<span className="ion-android-search"></span>
									</span>
								</div>
							</Col>
							<Col md={3} >
								<div className="input-group">
									<span className="input-group-addon" >
										From
									</span>
									<InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="dd/mm/yyyy"  onChange={(e)=>{this.setState({fromDate: e.value})}} className="form-control form-control-sm" value={this.state.fromDate} />
								</div>
							</Col>
							<Col md={2} className="pl-0" >
								<div className="input-group">
									<span className="input-group-addon" >
										To
									</span>
									<InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="dd/mm/yyyy"  onChange={(e)=>{this.setState({toDate: e.value})}} className="form-control form-control-sm" value={this.state.toDate} />
								</div>
							</Col>
							<Col md={1} className="px-0" >
								<Button type="button" size="sm" color="primary" onClick={this.loadVouchers} >
									VIEw
								</Button>
							</Col>
							<Col md={2} >
								<div className="text-right" >
									<Button type="button" size="sm" color="warning" onClick={this.loadVouchers} >
										<span className="ion-ios7-reload"></span>
									</Button>
									&nbsp;
									<Link className="btn btn-info btn-sm" to="/app/account-receipts/new" >
										<span className="ion-android-add"></span> Add New
									</Link>
								</div>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<hr  className="mt-0" />
						{this.state.showLoader ? <Loader type="dot" /> :
						<Table className="tbl" >
							<thead>
								<tr>
									<th width="80" >S.No</th>
									<th>Category Name</th>
									<th>Vch.No</th>
									<th>Vch.Date</th>
									<th>From</th>
									<th>To</th>
									<th>Amount</th>
									{/*<th width="120" >Manage</th>*/}
								</tr>
							</thead>
							<tbody>
							{currentTodos.map((item,i)=>{
								voucherTotal += parseFloat(item.amount);
								return <tr key={i} >
									<td>{indexOfFirstTodo+i+1}</td>
									<td>{item.categoryname}</td>
									<td>{item.voucherno}</td>
									<td>{item.voucherdate}</td>
									<td>{item.acdebitname}</td>
									<td>{item.accreditname}</td>
									<td align="right" >{item.amount}</td>
										{/*<td >
										<UncontrolledDropdown  >
											<DropdownToggle nav caret >
											  Manage
											</DropdownToggle>
											<DropdownMenu className="tbl-dropdown" >
											  <DropdownItem className="tbl-dropdown-list" onClick={this.handleEdit.bind(this,item,i)} >
												<i className="ion-edit"></i> &nbsp; Edit
											  </DropdownItem>
											  <DropdownItem className="tbl-dropdown-list" onClick={this.handleRemove.bind(this,item,i)} >
												<i className="ion-close"></i> &nbsp; Remove
											  </DropdownItem>
											</DropdownMenu>
									  </UncontrolledDropdown>
										</td>*/}
								</tr>;
							})
							}
							<tr>
								<td align="right" colSpan="6" > Total : </td>
								<td align="right" className="bg-light" style={{fontWeight:'600'}} >
									{parseFloat(voucherTotal).toFixed(2)}
								</td>
							</tr>
							</tbody>
						</Table>
						}
						
						<Row>
							<Col md={1} >
								<select className="form-control form-control-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} style={{padding:'0px'}} >
									<option selected={this.state.todosPerPage=='10' ? 'selected' : ''} value="10"> 10 </option>
									<option selected={this.state.todosPerPage=='12' ? 'selected' : ''}  value="15"> 15 </option>
									<option selected={this.state.todosPerPage=='20' ? 'selected' : ''}  value="20"> 20 </option>
									<option selected={this.state.todosPerPage=='50' ? 'selected' : ''}  value="50"> 50 </option>
									<option selected={this.state.todosPerPage=='100' ? 'selected' : ''}  value="100"> 100 </option>
								</select>
							</Col>
							<Col md={8}>
								<Pagination
								 activePage={this.state.activePage}
								 itemsCountPerPage={this.state.todosPerPage}
								 pageRangeDisplayed={3}
								 totalItemsCount={this.state.dataView.length}
								 onChange={(pageNumber)=>{
									this.setState({activePage: pageNumber});
									this.state.currentPage = Number(pageNumber); 
								 }}
								/>
							</Col>
						</Row>
						
					</CardBody>
				</Card>
				
			</div>
			
			<Modal isOpen={this.state.showAddModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
					Add Party Account
				</ModalHeader>
				<ModalBody>
				
				</ModalBody>
			</Modal>
		
		</div>
	  );
	}

}

export default Receipts;
