import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName} from '../../../variables';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import Menu from './Menu';

class Pso extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}

	render(){
	
	  return (
		<div>
			
			<LineLoader loading={this.state.showLoader} />
		
			<Menu />
			
			<div className="pt-50" >
			
			</div>
			
		</div>
	  );
	}

}

export default Pso;
