import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'reactstrap';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	  return (
		<div className="page-sub-nav page-sub-nav-fixed">
		  <Nav pills>
			<NavItem>
				<NavLink to="/app/naac/ssr" exact >
					SSR Report
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/app/naac/ssr/grouptitles"  exact >
					SSR Group Title
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink to="/app/naac/ssr/titles" exact >
					SSR Titles
				</NavLink>
			</NavItem>	
			{/*<NavItem>
				<NavLink to="/app/pages/categories" exact >
					Page Categories
				</NavLink>
			</NavItem>*/}
		  </Nav>
		</div>
	  );
	}

}
export default Menu;
