import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {Dropdown} from 'primereact/dropdown';

import Menu from './menu';

class FeeOthersSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
			btnBreakage: 'Update',
			selectedBreakageId: '',
		}
		
		this.loadCategory = this.loadCategory.bind(this);
		this.handleBreFormSubmit = this.handleBreFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.setState({selectedBreakageId: getls('currentacbusiness','breakageid')});
		this.loadCategory();
	}
	
	loadCategory(){
		try{
			
			this.setState({showLoader:true, typeLoader: true});
			var cid = getls('currentacbusiness', 'id');
			API.get('v1/fees/category/'+cid).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false, typeLoader: false});	
				}
				else
				{
					this.setState({showLoader: false, typeLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false, typeLoader: false});
		}
	}
	
	handleBreFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnBreakage: 'Updating..'});
			API.post('v1/fees/others/breakage/update',$("#frm_up_breCat").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnBreakage: 'Update'});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnBreakage: 'Update'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnBreakage: 'Update'});
		}
	}
	
	
	render(){

	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody>
						<Row>
							<Col md={12} style={{borderTop:'1px dotted black', borderBottom:'1px dotted black'}} className="py-15" >
							
							<form id="frm_up_breCat" method="post" encType="multipart/form-data" onSubmit={this.handleBreFormSubmit} >
								<input type="hidden" name="i__userId" value={getls('user', 'id')} />
								<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
								<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
								<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
								
								<input type="hidden" name="i__categoryId" value={this.state.selectedBreakageId} />
								<Row>
									<Col md={3}>
										<Label style={{fontSize: '18px', fontWeight: '600'}} >Breakage Id</Label>
									</Col>
									<Col md={6} >
									<Dropdown
										options={this.state.dataList.map((item,i)=>{
											return {label: item.partyname, value: item.partyid};
										})
										}
										filter={true}
										value={this.state.selectedBreakageId}
										onChange={(e)=>{this.setState({selectedBreakageId: e.value})}}
										className="form-control-sm"
										style={{width:'100%'}}
									/>
									{this.state.typeLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
									</Col>
									<Col md={2} >
										<Button type="submit" size="md" color="primary" disabled={this.state.btnBreakage!='Update'} >
											{this.state.btnBreakage}
										</Button>
									</Col>
								</Row>
							</form>
							
						</Col>
					</Row>
						
					</CardBody>
				</Card>
				
				<Card>
					<CardBody>
						<Row>
							<Col md={12} style={{borderTop:'1px dotted black', borderBottom:'1px dotted black'}} className="py-15" >
							
							<form id="frm_up_breCat" method="post" encType="multipart/form-data" onSubmit={this.handleBreFormSubmit} >
								<input type="hidden" name="i__userId" value={getls('user', 'id')} />
								<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
								<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
								<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
								
								
								<Row>
									<Col md={3}>
										<Label style={{fontSize: '18px', fontWeight: '600'}} >Payment Screen Layout</Label>
									</Col>
									<Col md={6} >
										<Input type="select" name="i__billFormat" required size="sm" >
											<option value="default"> Default </option>
											<option value="default"> Hostel Format </option>
										</Input>
									</Col>
									<Col md={2} >
										<Button type="submit" size="md" color="primary" disabled={this.state.btnBreakage!='Update'} >
											{this.state.btnBreakage}
										</Button>
									</Col>
								</Row>
							</form>
							
						</Col>
					</Row>
						
					</CardBody>
				</Card>
				
			</div>
			
		
		
		</div>
	  );
	}

}

export default FeeOthersSettings;
