/***
* @Global Variables
*
*/

module.exports = {
	AppName: 'Kalvisalai',
	
	ModuleName: 'SMS Panel',
	
	Version: '1.0',
	
	AppHeaderText: 'KALVISALAI',
	
	/**
	* ClientName
	* name of the institution
	*/
	ClientName: 'Tirunelveli Dakshina Mara Nadar Sangam College',
	
	/**
	* ClientPlace
	* name of the institution place
	*/
	ClientPlace: 'T.Kallikulam',
	
	/**
	* ClientAddress
	* address of the Institution
	*/
	ClientAddress: 'Tirunelveli - 627002',
	
	/**
	* Support  []
	* to hold support email id and mobile nos
	*/
	Support:{
		Email: 'peacesoft.developer@gmail.com',
		Mobile: '+91 9487900120',
	},
	
	/** JWTKEY*/
	JWTKEY : '21R91541sD5411H0G5',
	
	/**
	* LSKEY
	* variable to hold the local storage in this name
	**/
	LSKEY: 'tdmns26120',
	
	/**
	* Api
	* to hold api realted informations
	*/
	Api: {
							
		Url: 'https://webadmin.tdmnscollege.edu.in/services/api/',
		SiteUrl: 'https://tdmnscollege.edu.in/',
		ImageUploadPath: 'https://webadmin.tdmnscollege.edu.in/services', //Don't put slash at last
		AcademicPath: 'https://webadmin.tdmnscollege.edu.in/academicapi/',
	},
	
	/**
	* ImgPath
	* to hold all the image paths
	*/
	ImgPath: {
		StudentProfilePhoto: 'https://stjohnscollege.kalvisalai.in/academic/admin/public/studentphoto/'
	},
	
	MonthList: [ 'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	
	ExamMonths: [ 'April', 'Nov'],
	
	PSO: {
		UNITS: [
			{id: '1', value: 'Unit I'},
			{id: '2', value: 'Unit II'},
			{id: '3', value: 'Unit III'},
			{id: '4', value: 'Unit IV'},
			{id: '5', value: 'Unit V'}
		],
		PARTS: [
			{id: 'PART_A', value: 'Part-A'},
			{id: 'PART_B', value: 'Part-B'},
			{id: 'PART_C', value: 'Part-C'}
		],
		INTERNAL: {
			PART_A: ['1','2','3','4'],
		},
	},
			
};

