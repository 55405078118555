import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { notify } from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import { AppName, Version, Api } from '../../../variables';
import API from '../../../config/API';
import { groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, getAscSortOrder } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';

import DefaultPayment from './DefaultPayment';
import HostelPayment from './HostelPayment';
import PtaPayment from './PtaPayment';

class FeePayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,

		}

	}

	componentDidMount() {

	}



	render() {


		var billFormat = getls('currentacbusiness', 'billformat');


		return (
			<div>

				{billFormat == 'default' ? <DefaultPayment /> : null}
				{billFormat == 'hostel' ? <HostelPayment /> : null}
				{billFormat == 'pta' ? <PtaPayment /> : null}

			</div>
		);
	}

}

export default FeePayment;
