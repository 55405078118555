import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import LoginLogo from '../../assets/images/user_200.png';
import {AppName, Version, Api} from '../../variables';
import API from '../../config/API';
import {setls, setlsItem, getlsItem, upperCase} from '../../config';
import {reactLocalStorage} from 'reactjs-localstorage';
import axios from 'axios';

const styles = {
	logoStyle:{
		width: '130px',
		marginBottom: '15px',
		borderRadius: '100px',
		padding: '10px',
	},
	loginDiv:{
		padding:'0px 50px'
	},
	imgLogo:{
		width:'200px',
		padding:'5px 10px',
	},
	txtLogo:{
		color:'#fff',
		marginTop:'20px',
	}
}

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  loginText: 'Login',
		  isRedirect: false,
		  captcha: [],
		  showCaptchaLoader: false,
		}
		this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this);
		this.loadCaptcha = this.loadCaptcha.bind(this);
	}
	
	componentDidMount(){
		
		this.loadCaptcha();
	}
	
	handleLoginFormSubmit(e){
		try{
			e.preventDefault();
			this.setState({loginText:'Validating..'});
			API.post('v1/login/',$("#frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					this.loadDept(res);
				}
				else
				{
					this.loadCaptcha();
					notify.show(res['data'].message,'error');
					this.setState({loginText:'Login'});
				}

			});
			
			
		}
		catch(error){
			this.setState({loginText:'Login'});
		}
	}
	
	loadDept(res)
	{
		try{
			this.setState({loginText: 'Fetching Data'});
			if(res['data'].user.deptid=='0')
			{
				setlsItem('logged','yes');
				setls('company',res['data'].company);
				setls('user',res['data'].user);
				setlsItem('logintoken',res['data'].token);
				
				if(res['data'].user.accountcompany!='0'){
					var userAcBId = res['data'].user.accountcompany;
					setls('acbusiness',res['data'].acbusiness);
					setls('acyears',res['data'].acyears);
					setls('currentacyear',res['data'].acyears[0]);
					
					if(Object.keys(res['data'].acbusiness).length>0){
						var cb = res['data'].acbusiness.filter(function(obj){
							return(obj.id==userAcBId);
						});
						setls('currentacbusiness',cb[0]);
					}
						//this.setState({isRedirect:true});
						window.location.href = "";
						//this.setState({isRedirect:true});
				}
				else{
					window.location.href = "";
					//this.setState({isRedirect:true});
				}
				
			}
			else
			{				
				var d = this.courseOrder(res['data'].courselist);
				
				reactLocalStorage.setObject('batchlist', res['data'].batchlist);
				reactLocalStorage.setObject('courselist', d);
				//reactLocalStorage.setObject('employeelist', []);
				reactLocalStorage.setObject('employeelist', res['data'].staffs);
				
				setlsItem('logged','yes');
				setls('company',res['data'].company);
				setls('user',res['data'].user);
				setls('courselist',d);
				setls('employeelist',res['data'].staffs);
				setls('logintoken',res['data'].token);
				//setls('logged_id',res['data'].data.logged);
				window.location.href = "";
					
				/* this.setState({loginText:'Fetching Data..'});
				var deptId = res['data'].user.deptid;
				axios.post(Api.AcademicPath+'course/coursebydept/'+deptId).then(response=>{
					if(response['data'].status=='1')
					{
						var d = this.courseOrder(response['data'].data);
						reactLocalStorage.setObject('batchlist', response['data'].batch);
						reactLocalStorage.setObject('courselist', d);
						reactLocalStorage.setObject('employeelist', response['data'].employees);
						
						setlsItem('logged','yes');
						setls('company',res['data'].company);
						setls('user',res['data'].user);
						setls('courselist',d);
						setls('logintoken',res['data'].token);
						//setls('logged_id',res['data'].data.logged);
						window.location.href = "";
						//this.setState({isRedirect:true});
					}
					else
					{
						this.setState({showLoader: false});
						notify.show('Error in getting department information','error');
						this.setState({loginText:'Login'});
					}
				}); */
			}
		}
		catch(error){
			console.log(error);
			this.setState({loginText:'Login'});
		}
		
	}
	
	courseOrder(course){
		try{
			
			var rv = [];
			
			var corder= [
				{type: 'UG', coursetype: 'aided'},
				{type: 'UG', coursetype: 'un-aided'},
				{type: 'PG', coursetype: 'aided'},
				{type: 'PG', coursetype: 'un-aided'},
				{type: 'MPhil', coursetype: 'aided'},
				{type: 'MPhil', coursetype: 'un-aided'},
				{type: 'PhD', coursetype: 'aided'},
				{type: 'PhD', coursetype: 'un-aided'},
			];
			
			corder.map((item,i)=>{
				var d = course.filter(function(obj){
					return (upperCase(obj.type)==upperCase(item.type) && obj.coursetype==item.coursetype);
				});
				
				//rv.push(d);
				d.map((m,k)=>{
					rv.push(m);
				});
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	/* courseOrder(course){
		try{
			
			var rv = [];
			
			var corder= [
				{type: 'UG', coursetype: 'regular'},
				{type: 'UG', coursetype: 'self'},
				{type: 'PG', coursetype: 'regular'},
				{type: 'PG', coursetype: 'self'},
			];
			
			corder.map((item,i)=>{
				var d = course.filter(function(obj){
					return (obj.type==item.type && obj.coursetype==item.coursetype);
				});
				//rv.push(d);
				d.map((m,k)=>{
					rv.push(m);
				});
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	 */
	loadCaptcha(){
		try{
			this.setState({captcha: [], showCaptchaLoader: true});
			API.get('v1/captcha').then(res => {
				if(res['data'].status=='1'){
					this.setState({captcha: res['data'], showCaptchaLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showCaptchaLoader: false});
		}
	}


	render(){
		
	if(this.state.isRedirect || getlsItem('logged')=='yes'){
		return(<Redirect to="/app/home" />);
	}
	
	  return (
		<React.Fragment >
		<Row style={{background: 'linear-gradient(to right, #2c3e50, #4ca1af)',overflow:'hidden',width:'101%'}} >
			<Col md={3} >
				{/*<img src="http://vedic.kalvisalai.in/accounts/static/media/kalvisalai-school.15926409.png" style={styles.imgLogo} />*/}
			</Col>
			<Col md={6} >
				<center>
					<h3 style={styles.txtLogo} className="mb-25" >Web Admin Login </h3>
				</center>
			</Col>
			<Col md={3} >
				{/*<img src="http://sarahtuckercollege.edu.in/web/wp-content/uploads/2018/07/stc-logo.png" style={{width:'100%'}} />*/}
			</Col>
		</Row>
		<Container >
			<Notifications />
			
			<Row style={{ marginTop: '5%' }} >
				<Col md={{size:6, offset: 3}} >
						<div style={styles.loginDiv} >
							{/*<center  >
							<img src={LoginLogo} alt="Login" style={styles.logoStyle} className="bg-gradient-info" />
							</center>*/}
						<Card>						
							<CardBody>
								<CardTitle>
									 <span className="ion-android-keypad"></span> &nbsp; ACCOUNT LOGIN
									<hr />
								</CardTitle>
							</CardBody>
							<CardBody style={{ paddingTop: 0 }} >
								<form action="" method="post" encType="multipart/form-data" id="frmLogin" onSubmit={this.handleLoginFormSubmit} >
									<Row>
										<Col md={12} >
											<FormGroup>
											{/*<Label for="i__username" >Username</Label>*/}
											<div className="input-group" >
												<div className="input-group-addon">
													<span className="ion-person"></span>
												</div>
												<Input type="text" name="i__username" id="i__username" placeholder="Username" bsSize="sm" required />
											</div>
											</FormGroup>										
										</Col>
									</Row>
									<Row>
										<Col md={12} >
											<FormGroup>
											{/*<Label for="i__password" >Password</Label>*/}
											<div className="input-group" >
												<div className="input-group-addon">
													<span className="ion-key"></span>
												</div>
												<Input type="password" name="i__password" id="i__password" placeholder="Password" bsSize="sm" required />
											</div>
											</FormGroup>										
										</Col>
									</Row>
									<input type="hidden" name="i__captchakey" value={this.state.captcha['code']} />
									<Row>
										<Col md={12} >
											<FormGroup>
											{/*<Label for="i__username" >Username</Label>*/}
											<div className="input-group" >
												<div className="input-group-addon" style={{padding:'0px'}} >
													<span style={{paddingTop:'3px',fontSize:'20px'}}  >
														{this.state.showCaptchaLoader ? 
															<div style={{padding: '6px 10px'}} ><i className="ion-loading-a" /></div> : 
															<img src={this.state.captcha['img']} />
														}
													</span>
												</div>
												<Input type="text" name="i__captcha" id="i__captcha" placeholder="Captcha Code"  required />
												<div className="input-group-addon" onClick={this.loadCaptcha} style={{cursor: 'pointer'}} >
													<span className="ion-refresh" style={{paddingTop:'3px',fontSize:'20px'}} ></span>
												</div>
											</div>
											</FormGroup>										
										</Col>
									</Row>
									<Row>
										<Col md={12} >
											<FormGroup>
												<div className="float-right" >
													<Button color="primary" size="sm"  disabled={this.state.loginText!='Login'} >
														{this.state.loginText=='Login' ? 
														<span className="ion-android-checkmark" ></span> :
														<span className="ion-loading-a" ></span>} &nbsp;
													{this.state.loginText}
													</Button>
												</div>
												<div className="float-left">
													<a href="">Forgot Password</a>
												</div>
											</FormGroup>	
										</Col>
									</Row>
									<hr />	
									<Row>
										<Col md={12} >
											<div className="text-center" >
												Copyright &copy; {(new Date()).getFullYear()} {AppName}
											</div>
										</Col>
									</Row>									
								</form>
							</CardBody>
						</Card>
					</div>
				</Col>
			</Row>
		</Container>
		</React.Fragment>
	  );
	}

}

export default Login;
