import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'reactstrap';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	  return (
		<div className="page-sub-nav page-sub-nav-fixed">
		  <Nav pills>
			<NavItem>
				<NavLink to="/dept/research" exact >International Fellowship</NavLink> 
			</NavItem>	
			<NavItem>
				<NavLink to="/dept/research/facility" exact >Research Facility</NavLink> 
			</NavItem>	
			<NavItem>
				<NavLink to="/dept/research/researchcentre" exact >Research Centre</NavLink> 
			</NavItem>	
			<NavItem>
				<NavLink to="/dept/research/publications" exact >Publications</NavLink> 
			</NavItem>	
			{/*<NavItem>
				<NavLink to="/dept/research/mou" exact >MOU</NavLink> 
			</NavItem>	*/}
		  </Nav>
		</div>
	  );
	}

}
export default Menu;
