import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';
import './assets/ionicons/css/ionicons.min.css';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';

import './assets/pretty-checkbox.min.css';
import './assets/style.css';

import App from './App';

serviceWorker.unregister();

ReactDOM.render(<App />, document.getElementById('app'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

