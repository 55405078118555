import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import {LineLoader, BlockLoader} from '../../../elements';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, getls} from '../../../config';

class LoginUserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showLoader: false,
		  btnText: 'Update Profile',
		  userData: [],
		}
	}
	
	loadProfile(){
		try{
			this.setState({showLoader: true});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
	  return (
	  <div>
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<Row className="mt-30" >
				<Col md={8} >
					<Card>
						<CardBody>
							<form action="" method="post" encType="multipart/form-data" id="frm_loginUserSettings" >
								
								<Row>
									<Col md={3} ><Label>Name <span className="text-danger">*</span> </Label></Col>
									<Col md={9} >
										<Input type="text" size="sm" required name="i__name" />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ><Label>Employee Code </Label></Col>
									<Col md={9} >
										<Input type="text" size="sm" readOnly />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ><Label>Mobile No</Label></Col>
									<Col md={9} >
										<Input type="text" size="sm" name="i__mobile" />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ><Label>Email Id</Label></Col>
									<Col md={9} >
										<Input type="email" size="sm" name="i__email" />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ><Label>Last Login On </Label></Col>
									<Col md={9} >
										<Input type="text" size="sm" readOnly />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ><Label>Last Login Ip </Label></Col>
									<Col md={9} >
										<Input type="text" size="sm" readOnly />
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={3} ></Col>
									<Col md={9} >
										<Button type="submit" color="info" size="sm" disabled={this.state.btnText!='Update Profile'} 	>
											{this.state.btnText=='Update Profile' ? <i className="ion-checkmark-round" /> : <i className="ion-loading-a" />} &nbsp;
											{this.state.btnText}
										</Button>
									</Col>
								</Row>
								
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		
		</div>
	  );
	}

}

export default LoginUserProfile;
