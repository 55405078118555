import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName} from '../../../variables';
import {LineLoader} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import Menu from './menu';

class MenuOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Update',
			treeData: [],
			menuList: [],
		}
		this.handleTreeOnChange = this.handleTreeOnChange.bind(this);
		this.handleUpdateClick = this.handleUpdateClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadMenus();
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader: true, treeData: []});
			API.get('v1/menus/1').then(res => {
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, menuList: res['data'].data, treeData: res['data'].data});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleTreeOnChange(treedata){
		try{
			
			this.setState({treeData: treedata});
			
			console.log(treedata);
		}
		catch(error){
			
		}
	}
	
	
	handleUpdateClick(){
		
		try{
			this.setState({showLoader: true, btnSave: 'Updating..'});
			var d = this.state.treeData;
			API.post('v1/menus/updateorder',d).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSave: 'Update'});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Update'});
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	render(){
		
		return(
			<div>
			
				<LineLoader loading={this.state.showLoader} />
				
				<Menu />
				
				<div className="pt-50" >
					
					<Row>
						<Col md={9} >
						
							 <div style={{ height: 'calc(100vh)' }}>
								<SortableTree
								  treeData={this.state.treeData}
								  onChange={this.handleTreeOnChange}
								  
								/>
							  </div>
							  
						</Col>
						
						<Col md={3} >
							<Button type="button" size="sm" color="success" onClick={this.handleUpdateClick} disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave}
							</Button>	
						</Col>
					</Row>
				  
				  </div>
				  
			</div>
		);
	}

}

export default MenuOrder;
