import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, getAscSortOrder} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {Dropdown} from 'primereact/dropdown';
import axios from 'axios';

import FeeCategoryA5Print from './FeeCategoryA5Print';

class DefaultPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Save Payment',
			showAddModal: false,
			showEditModal: false,
			typeLoader: false,
			showPrint: false,
			selectedYear: 'Others',
			printData: [],
			categoryList: [],
			dataList: [],
			dataView: [],
			editData: [],
			selectedCategoryId: '',
			selectedAmount: '',
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
			today: '',
			paymentDate: '',
			maxDate: '',
			vId: '',
			vNo: '',
			studId: '',
			selectedStudent: [],
		}
		this.handleStudentSearch = this.handleStudentSearch.bind(this);
		this.loadCategory = this.loadCategory.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount() {
		var today = new Date();
		var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		//this.setState({today: date, paymentDate: date});
		this.setState({today: momentDate(today,'YYYY-MM-DD'), paymentDate: momentDate(today,'YYYY-MM-DD'), maxDate: momentDate(today,'YYYY-MM-DD')});
		this.loadCategory();
		this.loadVoucherInfo();
	}
	
	loadCategory(){
		try{
			
			this.setState({typeLoader:true});
			var cid = getls('currentacbusiness', 'id');
			API.get('v1/fees/category/'+cid).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({categoryList: res['data'].data, typeLoader: false});	
				}
				else
				{
					this.setState({typeLoader: false});
				}
			});
		}
		catch(error){
			this.setState({typeLoader:false});
		}
	}
	
	
	loadVoucherInfo(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type', 'receipt');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			
			API.post('v1/fees/voucher/no',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({vNo: res['data'].vno, vId: res['data'].vid, showLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleStudentSearch(e){
		try{
			if(this.state.studId.length<1){
				notify.show('Please enter Student No','warning');
				return;
			}
			this.setState({showLoader: true});
			axios.get(Api.AcademicPath+'students/getstudentbyadmissionno/'+this.state.studId).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data[0];
					var ye = 'Others';
					if(d['semester']=='1' || d['semester']=='2') ye = 'I Year';
					else if(d['semester']=='3' || d['semester']=='4') ye = 'II Year';
					else if(d['semester']=='5' || d['semester']=='6') ye = 'III Year';
					
					this.setState({selectedStudent: res['data'].data[0], selectedYear: ye, showLoader: false});
				}
				else{
					notify.show(res['data'].msg,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleCategoryChange(e){
		try{
			var v = e.value;
			var d = this.state.categoryList.filter(function(obj){
				return(obj.id==v);
			});
			this.setState({selectedCategoryId: v, selectedAmount: d[0].amount});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedCategoryId==''){
				notify.show('Selecte Category','warning');
				return;
			}
			else if(parseFloat(this.state.selectedAmount)<=0){
				notify.show('Enter amount greater than 0','warning');
				return;
			}
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/fees/voucher/save',$("#frm_category_Payment_New").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					document.getElementById("frm_category_Payment_New").reset();
					this.setState({showLoader: false, selectedCategoryId:'', selectedToId:'', selectedFromId: '', selectedTypeId: '', vId: res['data'].vid, vNo: res['data'].vno, payMethod: '', btnSave: 'Save Payment', selectedStudent: [], studId: '', selectedYear: 'Others'});
					var insId = res['data'].insid;
					this.showPrintView(insId);
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false,btnSave: 'Save Payment' });		
				}
			});
		}
		catch(error){
			notify.show('Error','error');
			this.setState({showLoader: false,btnSave: 'Save Payment' });
		}
	}
	
	showPrintView(id){
		try{
			this.setState({showLoader:true});
			var form = new FormData();
			form.append('type', 'payment');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('voucherid', id);
			
			API.post('v1/fees/vouchers', form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({printData: res['data'].data[0],showPrint: true, showLoader: false});	
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({showLoader: false});
		}
	}
	
	getStudentData(fieldName){
		try{
			var d = this.state.selectedStudent;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
	
	
		var courseName = this.getStudentData('coursetype');
		if(courseName!=undefined)
			courseName = courseName + " - " +this.getStudentData('coursename');
		
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<div className="pt-10" >
			
				<Card>
					<CardBody className="pb-0 mb-0" >
						<Row>
							<Col md={5} >
								<div className="input-group">
									<Input type="text" size="sm" placeholder="Student Adm.No" onChange={(e)=>{this.setState({studId: e.target.value})}} value={this.state.studId} />
									<span className="input-group-addon" style={{padding:'0px'}} >
										<Button size="sm" type="button" onClick={this.handleStudentSearch} >
											VIEW
										</Button>
									</span>
								</div>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									
								</div>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<hr  className="mt-0" />

						<form action="" method="post" id="frm_category_Payment_New" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
						
							<input type="hidden" name="i__userId" value={getls('user', 'id')} />
							<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
							<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
							<input type="hidden" name="breakageid" value={getls('currentacbusiness', 'breakageid')} />
							<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
							<input type="hidden" name="i__transactionType" value="receipt" />
							
							<input type="hidden" name="i__categoryId" value={this.state.selectedCategoryId} />
							<input type="hidden" name="i__admNo" value={this.state.studId} />
							<input type="hidden" name="i__courseId" value={this.getStudentData('course')} />
							
							
							<Row>
								<Col md={8} >
									<Row>
										<Col md={2}><Label className="required" >Year</Label></Col>
										<Col md={7} >
											<Input type="select" name="i__year" size="sm" required >
												<option value="I Year" selected={this.state.selectedYear=="I Year" ? 'selected' : ''} > I Year </option>
												<option value="II Year" selected={this.state.selectedYear=="II Year" ? 'selected' : ''} > II Year </option>
												<option value="III Year" selected={this.state.selectedYear=="III Year" ? 'selected' : ''} > III Year </option>
												<option value="Final Year" selected={this.state.selectedYear=="Final Year" ? 'selected' : ''} > Final Year </option>
												<option value="Old" selected={this.state.selectedYear=="Old" ? 'selected' : ''} > Old </option>
												<option value="Others" selected={this.state.selectedYear=="Others" ? 'selected' : ''} > Others </option>
											</Input>
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={2}><Label className="required" >Name</Label></Col>
										<Col md={7} >
											<Input type="text" size="sm" required name="i__name" placeholder="Name" defaultValue={this.getStudentData('name')}  />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={2}><Label>Mobile</Label></Col>
										<Col md={7} >
											<Input type="text" size="sm" name="i__mobile" placeholder="Mobile" defaultValue={this.getStudentData('mobile')}  />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={2}><Label>Course</Label></Col>
										<Col md={7} >
											<Input type="text" size="sm" name="i__course" placeholder="Course" defaultValue={courseName}  />
										</Col>

									</Row>
									<Row className="mt-10" >
										<Col md={2}><Label>Year/Sem</Label></Col>
										<Col md={4} >
											<Input type="text" size="sm" name="i__batch" placeholder="Year" defaultValue={this.getStudentData('batch')}  />
										</Col>
										<Col md={3} >
											<Input type="text" size="sm" name="i__sem" placeholder="SEM" defaultValue={this.getStudentData('semester')}  />
										</Col>
									</Row>
								</Col>
								
								<Col md={4} >
									<Row>
										<Col md={4} >
											<Label>Bill No</Label>
										</Col>
										<Col md={6} className="px-0" >
											<Input type="text" readOnly size="sm" value={this.state.vNo} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={4} >
											<Label>Date</Label>
										</Col>
										<Col md={6} className="px-0" >
											<Input type="date"  size="sm" name="i__paymentDate" required defaultValue={this.state.paymentDate} max={this.state.maxDate} />
										</Col>
									</Row>
								</Col>
								
								<Col md={12} >
									<Row  className="mt-100">											
										<Col md={9} >
											<Row >
												<Col md={2}><Label>Category</Label></Col>
												<Col md={9} className="pl-0" >
													<Dropdown
														options={this.state.categoryList.map((item,i)=>{
															return {label: item.partyname, value: item.partyid, amount: item.amount};
														})
														}
														filter={true}
														value={this.state.selectedCategoryId}
														onChange={this.handleCategoryChange}
														className="form-control-sm"
														style={{width:'100%'}}
													/>
													{this.state.typeLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
												</Col>
											</Row>
										</Col>
										<Col md={3} >
											<Row  >
												<Col md={4} >
													<Label>Amount</Label>
												</Col>
												<Col md={6} className="px-0" >
													<Input type="text"  size="sm" name="i__amount" style={{textAlign:'right', fontWeight:'800'}} placeholder="0.00" defaultValue={this.state.selectedAmount} onChange={(e)=>{this.setState({selectedAmount: e.target.value})}} />
												</Col>
											</Row>
										</Col>										
									</Row>
								</Col>
								
							</Row>
							
							<Row className="mt-10" >
								<Col md={1} >
									
								</Col>
								<Col md={6} className="pl-45" >
									
								</Col>
								<Col md={5} >
									<div className="text-right mt-40 " >
										<Button type="reset" color="danger" size="sm" disabled={this.state.btnSave!='Save Payment'} onClick={()=>{this.setState({selectedCategoryId:'', selectedToId:'', selectedFromId: '', selectedTypeId: '', selectedStudent:[], studId:'', selectedYear: 'Others'})}} >
											Reset
										</Button>
										&nbsp;
										<Button type="submit" color="primary" size="sm" disabled={this.state.btnSave!='Save Payment'} >
											{this.state.btnSave!='Save Payment' ? <i className="ion-loading-a"></i> : null}
											&nbsp; {this.state.btnSave}
										</Button>
									</div>
								</Col>
							</Row>
							
						</form>
						
					</CardBody>
				</Card>
				
			</div>
			
			{this.state.showPrint ? <div style={{display:'none'}} >
				<FeeCategoryA5Print printData={this.state.printData} autoPrint atClose={()=>{this.setState({printData:[], showPrint: false})}} />
			</div> : null}
			
		</div>
	  );
	}

}

export default DefaultPayment;
