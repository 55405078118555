import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import Menu from './menu';
import AddParty from './AddParty';
import EditParty from './EditParty';

class Party extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadCategory = this.loadCategory.bind(this);
	}
	
	componentDidMount() {
		console.log('-------------- LOGIN TOKEN -------------');
		console.log(getlsItem('logintoken'));
		console.log('-------------- LOGIN TOKEN -------------');
		this.loadCategory();
	}
	
	loadCategory(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/accounts/partyaccounts').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.categoryname).indexOf(v)>-1) 
					|| (upperCase(obj.partyname).indexOf(v)>-1)
					|| (upperCase(obj.mobile).indexOf(v)>-1)
					|| (upperCase(obj.phone).indexOf(v)>-1));
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/accounts/partyaccount/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,i){
		this.setState({editData: item,showEditModal: true});
	}
	
	render(){
		
	let sno = 1;
	
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody className="pb-0 mb-0" >
						<Row>
							<Col md={8} >
								<div className="input-group">
									<Input type="text" size="sm" placeholder="Search by Name (OR) Mobile" onChange={this.handlePageSearch} />
									<span className="input-group-addon" >
										<span className="ion-android-search"></span>
									</span>
								</div>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<Button type="button" size="sm" color="warning" onClick={this.loadCategory} >
										<span className="ion-ios7-reload"></span>
									</Button>
									&nbsp;
									<Button type="button" size="sm" color="primary" onClick={()=>{this.setState({showAddModal: true})}} >
										<span className="ion-android-add"></span> Add New
									</Button>
								</div>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<hr  className="mt-0" />
						{this.state.showLoader ? <Loader type="dot" /> :
						<Table className="tbl" >
							<thead>
								<tr>
									<th width="80" >S.No</th>
									<th>Type</th>
									<th>Category Name</th>
									<th>Party Name</th>
									<th>Mobile</th>
									<th>Balance</th>
									<th width="120" >Manage</th>
								</tr>
							</thead>
							<tbody>
							{currentTodos.map((item,i)=>{
								var balance = parseFloat(item.credittotal)-parseFloat(item.debittotal);
								return <tr key={i} >
									<td>{indexOfFirstTodo+i+1}</td>
									<td>{upperCase(item.partytype)}</td>
									<td>{item.categoryname}</td>
									<td>{item.prefix} {item.partyname}</td>
									<td>{item.phone}</td>
									<td align="right" >
									{Math.abs(parseFloat(balance)).toFixed(2)} &nbsp; {balance >= 0 ? 'Cr' : 'Dr'}
									</td>
									<td >
										<UncontrolledDropdown  >
											<DropdownToggle nav caret >
											  Manage
											</DropdownToggle>
											<DropdownMenu className="tbl-dropdown" >
											  <DropdownItem className="tbl-dropdown-list" onClick={this.handleEdit.bind(this,item,i)} >
												<i className="ion-edit"></i> &nbsp; Edit
											  </DropdownItem>
											  <DropdownItem className="tbl-dropdown-list" onClick={this.handleRemove.bind(this,item,i)} >
												<i className="ion-close"></i> &nbsp; Remove
											  </DropdownItem>
											</DropdownMenu>
									  </UncontrolledDropdown>
									</td>
								</tr>;
							})
							}
							</tbody>
						</Table>
						}
						
						<Row>
							<Col md={1} >
								<select className="form-control form-control-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} style={{padding:'0px'}} >
									<option selected={this.state.todosPerPage=='10' ? 'selected' : ''} value="10"> 10 </option>
									<option selected={this.state.todosPerPage=='12' ? 'selected' : ''}  value="15"> 15 </option>
									<option selected={this.state.todosPerPage=='20' ? 'selected' : ''}  value="20"> 20 </option>
									<option selected={this.state.todosPerPage=='50' ? 'selected' : ''}  value="50"> 50 </option>
									<option selected={this.state.todosPerPage=='100' ? 'selected' : ''}  value="100"> 100 </option>
								</select>
							</Col>
							<Col md={8}>
								<Pagination
								 activePage={this.state.activePage}
								 itemsCountPerPage={this.state.todosPerPage}
								 pageRangeDisplayed={3}
								 totalItemsCount={this.state.dataView.length}
								 onChange={(pageNumber)=>{
									this.setState({activePage: pageNumber});
									this.state.currentPage = Number(pageNumber); 
								 }}
								/>
							</Col>
						</Row>
						
					</CardBody>
				</Card>
				
			</div>
			
			<Modal isOpen={this.state.showAddModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
					Add Party Account
				</ModalHeader>
				<ModalBody>
					<AddParty atFinish={this.loadCategory} />
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditModal: false})}} >
					Edit Party Account
				</ModalHeader>
				<ModalBody>
					<EditParty editData={this.state.editData} atFinish={this.loadCategory} />
				</ModalBody>
			</Modal>
		
		</div>
	  );
	}

}

export default Party;
