import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";

import Menu from './menu';
import AddReport from './AddReport';
import EditReport from './EditReport';

class NaacSsr extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showLoader: false,
		  showAddModal: false,
		  showEditModal: false,
		  dataList: [],
		  dataView: [],
		  editData: [],
		  nodes: []
		}
		this.loadTitles = this.loadTitles.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}
	
	componentDidMount(){
		this.loadTitles();
	}
	
	loadTitles(){
		try{
			this.setState({showLoader: true, dataList: [], dataView: []});
			API.get('v1/naac/ssr/reports').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearch(e){
		try{			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.title).indexOf(v)>-1) || (upperCase(obj.code).indexOf(v)>-1) || (upperCase(obj.menutitle).indexOf(v)>-1));
			});
			this.setState({dataView: d});
		}
		catch(error){ }
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/naac/ssr/report/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item){
		try{
			this.setState({editData: item, showEditModal: true});
		}
		catch(error){ }
	}
	
	
	render(){
		
	let sno=1;
		
	  return (
		<div>
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				
				<Row>
					<Col md={12} >
						<Card>
							<CardBody className="pb-0 mb-0" >
								<Row>
									<Col md={8} >
										<div className="input-group">
											<Input type="text" size="sm" placeholder="Search by Code (OR) Title.." onChange={this.handleSearch} />
											<span className="input-group-addon" >
												<span className="ion-android-search"></span>
											</span>
										</div>
									</Col>
									<Col md={4} >
										<div className="text-right" >
											<Button type="button" size="sm" color="warning" onClick={this.loadTitles} >
												<span className="ion-ios7-reload"></span>
											</Button>
											&nbsp;
											<Button type="button" size="sm" color="info" onClick={()=>{this.setState({showAddModal: true})}} >
												<span className="ion-android-add"></span> Add New
											</Button>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardBody>
								<hr  className="mt-0" />
								{this.state.showLoader ? <Loader type="dot" /> :  
									<Table className="tbl" >
									<thead>
										<tr>
											<th width="80" >S.No</th>
											<th>Title</th>
											<th>Group Title</th>
											<th>Code</th>
											<th>Menu</th>
											<th width="120" >Action</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										var url = Api.SiteUrl +'ssr/'+ item.type+'/'+item.id+'/'+item.code;
										return <tr key={i} >
											<td>{sno++}</td>
											<td>{item.displaytitle}</td>
											<td>{item.displaygrouptitle}</td>
											<td>{item.code}</td>
											<td>
												<a href={url} target="_blank" >
													{item.menutitle}
												</a>
											</td>
											<td align="center" >
												<Button type="button" size="sm" color="primary" onClick={this.handleEdit.bind(this,item)} >
													<span className="ion-edit"></span>
												</Button>
												&nbsp;
												<Button type="button" size="sm" color="danger" onClick={this.handleRemove.bind(this,item,i)} >
													<span className="ion-close"></span>
												</Button>
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
								}							
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
			
			
			<Modal isOpen={this.state.showAddModal} size="xl" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
					Add Report
				</ModalHeader>
				<ModalBody>
					<AddReport atFinish={this.loadTitles} />
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditModal} size="xl" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditModal: false})}} >
					Edit Report
				</ModalHeader>
				<ModalBody>
					<EditReport editData={this.state.editData} atFinish={this.loadTitles} />
				</ModalBody>
			</Modal>
			
		
		</div>
	  );
	}

}

export default NaacSsr;
