import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { JWTKEY, AppName, Version, ClientName } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, upperCase, capitalizeFirst, groupByMultiple, encodeText } from '../../../config';
import { BlockLoader, LineLoader } from '../../../elements';
import JWT from 'jwt-simple';

import Menu from './Menu';
import AddUser from './AddUser';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';

class DepartmentAdminUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			showEditPassword: false,
			dataList: [],
			dataView: [],
			editData: [],
			userRole: 'deptadmin',
			passwordInfo: [],
			passwordLoader: false,
		}
		
		this.loadUsers = this.loadUsers.bind(this);
		this.setPasswordEmpty = this.setPasswordEmpty.bind(this);
		this.handlePageSearch = this.handlePageSearch.bind(this);
	}

	componentDidMount() {
		this.loadUsers();
	}

	loadUsers() {
		try {

			this.setState({ showLoader: true });
			var userid = getls('user', 'id');
			API.get('v1/users/all/'+ this.state.userRole).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ dataList: d, dataView: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	handleEdit(item, id, e) {
		try {

			this.setState({
				editData: item,
				editId: id,
				showEditModal: true
			});
		}
		catch (error) {

		}
	}
	
	handlePasswordEdit(item, id, e) {
		try {

			this.setState({
				editData: item,
				editId: id,
				showEditPassword: true
			});
		}
		catch (error) {

		}
	}

	handleRemove(item, index) {
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the User?',
			buttons: {
				Yes: function () {
					$this.confirmRemove(item, index);
				},
				No: function () {

				}
			}
		});
	}

	confirmRemove(item, i) {
		try {

			this.setState({ showLoader: true });
			API.delete('v1/user/remove/' + item.id).then(res => {
				if (res['data'].status == '1') {
					var d = this.state.dataList;
					var index = d.findIndex(function (obj) {
						return (obj.id == item.id);
					});
					d.splice(index, 1);
					notify.show(res['data'].message, 'success');
					this.setState({ showLoader: false, dataList: d, dataView: d });
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
			alert('Unexpected Error');
		}
	}
	
	handlePasswordView(item){
		try{
			
			this.setState({passwordLoader: true, passwordInfo: {id: item.id}});
			API.get('v1/user/pass/'+item.id).then(res=>{
				if(res['data'].status=='1'){
					var pass = JWT.decode(res['data'].password, JWTKEY, true, 'HS256');//, false, 'HS256');
					var d = this.state.passwordInfo;
					d['password'] = pass;
					this.setState({passwordLoader: false, passwordInfo: d});
					setTimeout(this.setPasswordEmpty, 5000);
				}
				else{
					this.setState({passwordLoader: false, passwordInfo: []});
					notify.show(res['data'].message,'error');
				}
			});
		}
		catch(error){
			this.setState({passwordLoader: false, passwordInfo: []});
		}
	}
	
	setPasswordEmpty(){
		this.setState({passwordInfo: [] });
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.username).indexOf(v)>-1
					|| upperCase(obj.name).indexOf(v)>-1
					|| upperCase(obj.departmentname).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}

	render() {

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />
				<BlockLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >
					<Row className="mb-10" > 
						<Col md={8} >
							<div className="input-group">
								<Input type="text" size="sm" placeholder="Search by Username OR Name OR Department Name" onChange={this.handlePageSearch} />
								<span className="input-group-addon" >
									<span className="ion-android-search"></span>
								</span>
							</div>
						</Col>
						<Col md={4} >
							<div className="text-right" >
								<Button type="button" size="sm" color="secondary" onClick={(e)=>{this.setState({showAddModal: true})}}  >
									<i className="ion ion-android-add" /> Add User
								</Button>
								<Button type="button" size="sm" color="secondary" onClick={this.loadUsers} >
									<i className="ion ion-refresh" /> Refresh
								</Button>
							</div>
						</Col>
					</Row>
					<Table className="tbl table" >
						<thead>
							<tr>
								<th width="60" >S.No</th>
								<th>Department Name</th>
								<th>Username</th>
								<th>Password</th>
								<th>Name</th>
								<th>Mobile</th>
								<th>Email</th>
								<th width="100" >Manage</th>
							</tr>
						</thead>
						<tbody>
							{this.state.dataView.map((item, i) =>
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{item.departmentname}</td>
									<td>{item.username}</td>
									<td style={{cursor: 'pointer'}} onClick={this.handlePasswordView.bind(this,item)} >
										{Object.keys(this.state.passwordInfo).length>0 && this.state.passwordInfo.id==item.id ? 
										this.state.passwordInfo.password : '*****'}
										{Object.keys(this.state.passwordInfo).length>0 && this.state.passwordInfo.id==item.id && this.state.passwordLoader ? 
										<span className="text-right" style={{fontSize: '16px'}} > <i className="ion-loading-a" /> </span> : null}
									</td>
									<td>{item.name}</td>
									<td>{item.mobile}</td>
									<td>{item.email}</td>
									<td align="center" >
										<Button type="button" size="xs" color="success" title="Edit" onClick={this.handlePasswordEdit.bind(this, item, item.id)} title="Change Password" >
											<span className="ion-key"></span>
										</Button>
										&nbsp;
										<Button type="button" size="xs" color="primary" title="Edit" onClick={this.handleEdit.bind(this, item, item.id)} title="Edit Profile" >
											<span className="ion-edit"></span>
										</Button>
										&nbsp;
										<Button type="button" size="xs" color="danger" title="Remove" onClick={this.handleRemove.bind(this, item, i)} title="Remove Profile" >
											<span className="ion-close-round"></span>
										</Button>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				<Modal isOpen={this.state.showAddModal} size="md" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
						Add User
					</ModalHeader>
					<ModalBody>
						
						<AddUser  atFinish={this.loadUsers} role={this.state.userRole} department={true}  />
						
					</ModalBody>
				</Modal>
				
				<Modal isOpen={this.state.showEditModal} size="md" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showEditModal: false})}} >
						Edit User
					</ModalHeader>
					<ModalBody>
						
						<EditUser editData={this.state.editData} atFinish={this.loadUsers} department={true}  />
						
					</ModalBody>
				</Modal>
				
				<Modal isOpen={this.state.showEditPassword} size="sm" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showEditPassword: false})}} >
						Change Password
					</ModalHeader>
					<ModalBody>
						
						<ChangePassword  editData={this.state.editData} atFinish={this.loadUsers} />
						
					</ModalBody>
				</Modal>
				
			</div>
		);
	}

}

export default DepartmentAdminUsers;
