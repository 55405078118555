import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api } from '../../../variables';
import API from '../../../config/API';
import axios from 'axios';
import { setls, upperCase, capitalizeFirst, momentDate, getls } from '../../../config';
import Select from 'react-select';
import { Container, Button as FloatButton, Link } from 'react-floating-action-button';
import { Facebook } from 'react-content-loader'

import AddQualification from './AddQualification';
import EditQualification from './EditQualification';
import AddExperience from './AddExperience';
import EditExperience from './EditExperience';
import AddPapers from './AddPapers';
import EditPapers from './EditPapers';
import AddPublished from './AddPublished';
import EditPublished from './EditPublished';
import AddSeminar from './AddSeminar';
import EditSeminar from './EditSeminar';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnText: 'Update',
            showLoader: false,
            employeedata: [],
            qdata: [],
            exdata: [],
            papers: [],
            published: [],
            seminar: [],
            editData: [],
            id: getls('user', 'empid'),
            tabId: this.props.id,
            showModal: false,
            showEditModal: false,
            showExAddModal: false,
            showExEditModal: false,
            showPaperAddModal: false,
            showPaperEditModal: false,
            showPublishedAddModal: false,
            showPublishedEditModal: false,
            showSeminarAddModal: false,
            showSeminarEditModal: false,
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.loadQualification = this.loadQualification.bind(this);
        this.loadExperience = this.loadExperience.bind(this);
        this.loadPresentedPapers = this.loadPresentedPapers.bind(this);
        this.loadPublishedPapers = this.loadPublishedPapers.bind(this);
        this.loadSeminar = this.loadSeminar.bind(this);
    }

    tabChange(e) {
        this.setState({ tabId: e.target.value });
    }

    onSeminarEdit(id, e) {
        try {
            var d = this.state.seminar;
            var index = d.findIndex(function (obj) {
                return (obj.id == id);
            });
            if (index > -1) {
                this.setState({ editData: d[index], showSeminarEditModal: true });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onPublishPaperEdit(id, e) {
        try {
            var d = this.state.published;
            var index = d.findIndex(function (obj) {
                return (obj.id == id);
            });
            if (index > -1) {
                this.setState({ editData: d[index], showPublishedEditModal: true });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    removeSeminar(id) {
        try {
            if (window.confirm('Do you want to remove paper?')) {
                this.setState({ showLoader: true });
                axios.get(Api.AcademicPath + 'staff/removeseminar/' + id).then(res => {
                    if (res['data'].status == '1') {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'success');
                        this.loadSeminar();
                    }
                    else {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'error');
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onPaperEdit(id, e) {
        try {
            var d = this.state.papers;
            var index = d.findIndex(function (obj) {
                return (obj.id == id);
            });
            if (index > -1) {
                this.setState({ editData: d[index], showPaperEditModal: true });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    removePaper(id) {
        try {
            if (window.confirm('Do you want to remove paper?')) {
                this.setState({ showLoader: true });
                axios.get(Api.AcademicPath + 'staff/removepaper/' + id).then(res => {
                    if (res['data'].status == '1') {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'success');
                        this.loadPresentedPapers();
                    }
                    else {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'error');
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onExEdit(id, e) {
        try {
            var d = this.state.exdata;
            var index = d.findIndex(function (obj) {
                return (obj.id == id);
            });
            if (index > -1) {
                this.setState({ editData: d[index], showExEditModal: true });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    removeExperience(id) {
        try {
            if (window.confirm('Do you want to remove experience?')) {
                this.setState({ showLoader: true });
                axios.get(Api.AcademicPath + 'staff/removeexperience/' + id).then(res => {
                    if (res['data'].status == '1') {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'success');
                        this.loadExperience();
                    }
                    else {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'error');
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onEdit(id, e) {
        try {
            var d = this.state.qdata;
            var index = d.findIndex(function (obj) {
                return (obj.id == id);
            });
            if (index > -1) {
                this.setState({ editData: d[index], showEditModal: true });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    removeQualification(id) {
        try {
            if (window.confirm('Do you want to remove qualification?')) {
                this.setState({ showLoader: true });
                axios.get(Api.AcademicPath + 'staff/removequalification/' + id).then(res => {
                    if (res['data'].status == '1') {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'success');
                        this.loadQualification();
                    }
                    else {
                        this.setState({ showLoader: false });
                        notify.show(res['data'].msg, 'error');
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    handleFormSubmit(e) {
        try {
            e.preventDefault();
            this.setState({ showLoader: true, btnText: 'Updating' });
            axios.post(Api.AcademicPath + 'staff/updateprofile', $("#frmProfile").serialize()).then(response => {
                if (response['data'].status == '1') {
                    this.setState({ showLoader: false, btnText: 'Update' });
                    notify.show(response['data'].msg, 'success');
                    this.loadEmployeeDetails();
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ tabId: props.match.params.id });
    }

    componentDidMount() {
        this.setState({ tabId: this.props.match.params.id, showLoader: true });
        this.loadEmployeeDetails();
        this.loadQualification();
        this.loadExperience();
        this.loadPresentedPapers();
        this.loadPublishedPapers();
        this.loadSeminar();
    }

    loadEmployeeDetails() {
        try {
            this.setState({ showLoader: true, employeedata: [] });
            axios.get(Api.AcademicPath + 'staff/getemployeebyid/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ employeedata: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showLoader: false });
        }
    }

    loadQualification() {
        try {
            this.setState({ showLoader: true, qdata: [] });
            axios.get(Api.AcademicPath + 'staff/getqualification/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ qdata: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showSmsLoader: false });
        }
    }

    loadExperience() {
        try {
            this.setState({ showLoader: true, exdata: [] });
            axios.get(Api.AcademicPath + 'staff/getexperience/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ exdata: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showSmsLoader: false });
        }
    }

    loadPresentedPapers() {
        try {
            this.setState({ showLoader: true, papers: [] });
            axios.get(Api.AcademicPath + 'staff/getpaperpresented/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ papers: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showSmsLoader: false });
        }
    }

    loadPublishedPapers() {
        try {
            this.setState({ showLoader: true, published: [] });
            axios.get(Api.AcademicPath + 'staff/getpublishedpapers/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ published: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showSmsLoader: false });
        }
    }

    loadSeminar() {
        try {
            this.setState({ showLoader: true, seminar: [] });
            axios.get(Api.AcademicPath + 'staff/getseminar/' + this.state.id).then(res => {
                if (res['data'].status == '1') {
                    this.setState({ seminar: res['data'].data });
                }
                this.setState({ showLoader: false });
            });
        }
        catch (error) {
            console.log(error);
            this.setState({ showSmsLoader: false });
        }
    }

    getValue(field) {
        try {
            var d = this.state.employeedata;
            console.log(d);
            return d[field];
        }
        catch (error) { }
    }

    setValue(field, e) {
        try {
            var d = this.state.employeedata;
            d[field] = e.target.value;
            this.setState({ employeedata: d });
        }
        catch (error) { }
    }

    render() {


        let getYear = [];
        var today = new Date();
        var curyear = today.getFullYear();
        for (var i = 1980; i <= curyear; i++) {
            getYear.push(<option value={i}>{i}</option>);
        }

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle><b style={{ color: 'black' }}>{this.getValue('empname')} - {this.getValue('empcode')}</b></CardTitle>
                            </CardHeader>
                            <hr style={{ margin: '1px' }} />
                            <CardBody>
                                <form action="" method="post" encType="multipart/form-data" id="frmProfile" onSubmit={this.handleFormSubmit} >
                                    <input type="hidden" name="empid" value={this.getValue('id')} />
                                    <Row>
                                        <Col md={2}>
                                            <img src={this.getValue('photo')} width="150px" height="170px" />
                                        </Col>
                                        {this.state.tabId == undefined ?

                                            <Col md={10}>
                                                {this.state.showLoader ? <div> <Facebook /></div> :
                                                    <Row>
                                                        <Col md={12} >

                                                            <Row>
                                                                <Col md={5}>
                                                                    <Label>Name</Label>
                                                                    <Input type="text" size="sm" name="empname" value={this.getValue('empname')} onChange={this.setValue.bind(this, 'empname')} required />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>Smart Id</Label>
                                                                    <Input type="number" size="sm" name="smartid" value={this.getValue('smartid')} readonly />
                                                                </Col>
                                                                <Col md={3}>
                                                                    <Label>Join Date</Label>
                                                                    <Input type="text" size="sm" name="joindate" value={this.getValue('joindate')} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={4}>
                                                                    <Label>DOB</Label>
                                                                    <Input type="date" size="sm" name="dob" value={this.getValue('dob')} onChange={this.setValue.bind(this, 'dob')} />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>Gender</Label>
                                                                    <Input type="select" size="sm" name="gender" value={this.getValue('gender')} onChange={this.setValue.bind(this, 'gender')}>
                                                                        <option value="">Gender</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                    </Input>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>Qualification</Label>
                                                                    <Input type="text" size="sm" name="qualification" value={this.getValue('qualification')} onChange={this.setValue.bind(this, 'qualification')} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={6}>
                                                                    <Label>Present Address</Label>
                                                                    <Input type="textarea" rows="3" name="presentaddress" value={this.getValue('presentaddress')} onChange={this.setValue.bind(this, 'presentaddress')} />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Label>Permanent Address</Label>
                                                                    <Input type="textarea" rows="3" name="permanentaddress" value={this.getValue('permanentaddress')} onChange={this.setValue.bind(this, 'permanentaddress')} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={4}>
                                                                    <Label>Country</Label>
                                                                    <Input type="text" size="sm" name="country" value={this.getValue('country')} onChange={this.setValue.bind(this, 'country')} />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>State</Label>
                                                                    <Input type="text" size="sm" name="state" value={this.getValue('state')} onChange={this.setValue.bind(this, 'state')} />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>City</Label>
                                                                    <Input type="text" size="sm" name="city" value={this.getValue('city')} onChange={this.setValue.bind(this, 'city')} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={4}>
                                                                    <Label>Pincode</Label>
                                                                    <Input type="number" size="sm" name="pincode" value={this.getValue('pincode')} onChange={this.setValue.bind(this, 'pincode')} />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>Mobile</Label>
                                                                    <Input type="number" size="sm" name="mobile" value={this.getValue('mobile')} onChange={this.setValue.bind(this, 'mobile')} required />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Label>Phone</Label>
                                                                    <Input type="number" size="sm" name="phone" value={this.getValue('phone')} onChange={this.setValue.bind(this, 'phone')} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={12}>
                                                                    <Label>Email</Label>
                                                                    <Input type="email" size="sm" name="email" value={this.getValue('email')} onChange={this.setValue.bind(this, 'email')} required />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-10">
                                                                <Col md={10}>

                                                                </Col>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <div className="float-right" >
                                                                            <Button type="submit" color="primary" size="sm" disabled={this.state.btnText != 'Update'} >
                                                                                {this.state.btnText == 'Update' ?
                                                                                    <span className="ion-android-checkmark" ></span> :
                                                                                    <span className="ion-loading-a" ></span>} &nbsp;
                                                                                    {this.state.btnText}
                                                                            </Button>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                }
                                            </Col> :
                                            this.state.tabId == 1 ?
                                                <Col md={10}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <b style={{ fontSize: '16px' }}>Educational Qualification</b>
                                                            <div className="float-right">
                                                                <Button type="button" color="info" size="sm" onClick={() => { this.setState({ showModal: true }) }}>
                                                                    <i className="ion-plus"></i>&nbsp;Add
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Table bordered responsive className="tbl" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Category</th>
                                                                        <th>Name of the Degree</th>
                                                                        <th>Specialization</th>
                                                                        <th>Year of Passing</th>
                                                                        <th>Name of the College</th>
                                                                        <th>Name of the University</th>
                                                                        <th>% of Mark / Grades obtained</th>
                                                                        <th>Class obtained</th>
                                                                        <th>Manage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.qdata.map((item, i) =>
                                                                        <tr>
                                                                            <td>{item.category}</td>
                                                                            <td>{item.degree}</td>
                                                                            <td>{item.specialization}</td>
                                                                            <td>{item.passyear}</td>
                                                                            <td>{item.college}</td>
                                                                            <td>{item.university}</td>
                                                                            <td>{item.percentage}</td>
                                                                            <td>{item.passclass}</td>
                                                                            <td>
                                                                                <Button type="button" color="info" size="sm" tooltip="edit" onClick={this.onEdit.bind(this, item.id)}><i className="ion-edit"></i></Button>&nbsp;
                                                                                <Button type="button" color="danger" size="sm" tooltip="remove" onClick={this.removeQualification.bind(this, item.id)}><i className="ion-android-trash"></i></Button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Col> :
                                                this.state.tabId == 2 ?
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <b style={{ fontSize: '16px' }}>Academic Experience</b>
                                                                <div className="float-right">
                                                                    <Button type="button" color="info" size="sm" onClick={() => { this.setState({ showExAddModal: true }) }}>
                                                                        <i className="ion-plus"></i>&nbsp;Add
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-10">
                                                            <Col md={12}>
                                                                <Table bordered responsive className="tbl" >
                                                                    <thead>
                                                                        <tr>
                                                                            <th rowSpan={2}>Name of the College</th>
                                                                            <th rowSpan={2}>Whether Govt/Aided/SF</th>
                                                                            <th rowSpan={2}>Designation</th>
                                                                            <th rowSpan={2}>Join Date</th>
                                                                            <th rowSpan={2}>Relieving Date</th>
                                                                            <th colSpan={3} align="center">Experience</th>
                                                                            <th rowSpan={2}>Manage</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Years</th>
                                                                            <th>Months</th>
                                                                            <th>Days</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.exdata.map((item, i) =>
                                                                            <tr>
                                                                                <td>{item.college}</td>
                                                                                <td>{item.category}</td>
                                                                                <td>{item.designation}</td>
                                                                                <td>{momentDate(item.joindate)}</td>
                                                                                <td>{momentDate(item.relievingdate)}</td>
                                                                                <td>{item.years}</td>
                                                                                <td>{item.months}</td>
                                                                                <td>{item.days}</td>
                                                                                <td>
                                                                                    <Button type="button" color="info" size="sm" tooltip="edit" onClick={this.onExEdit.bind(this, item.id)}><i className="ion-edit"></i></Button>&nbsp;
                                                                                    <Button type="button" color="danger" size="sm" tooltip="remove" onClick={this.removeExperience.bind(this, item.id)}><i className="ion-android-trash"></i></Button>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </Col> :
                                                    this.state.tabId == 3 ?
                                                        <Col md={10}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <b style={{ fontSize: '16px' }}>Papers Presented</b>
                                                                    <div className="float-right">
                                                                        <Button type="button" color="info" size="sm" onClick={() => { this.setState({ showPaperAddModal: true }) }}>
                                                                            <i className="ion-plus"></i>&nbsp;Add
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Table bordered responsive className="tbl" >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Level</th>
                                                                                <th>Description</th>
                                                                                <th>Place</th>
                                                                                <th>Date</th>
                                                                                <th>Manage</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.papers.map((item, i) =>
                                                                                <tr>
                                                                                    <td>{item.title}</td>
                                                                                    <td>{item.level}</td>
                                                                                    <td>{item.description}</td>
                                                                                    <td>{item.place}</td>
                                                                                    <td>{momentDate(item.presentdate)}</td>
                                                                                    <td>
                                                                                        <Button type="button" color="info" size="sm" tooltip="edit" onClick={this.onPaperEdit.bind(this, item.id)}><i className="ion-edit"></i></Button>&nbsp;
                                                                                        <Button type="button" color="danger" size="sm" tooltip="remove" onClick={this.removePaper.bind(this, item.id)}><i className="ion-android-trash"></i></Button>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        </Col> :
                                                        this.state.tabId == 4 ?
                                                            <Col md={10}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <b style={{ fontSize: '16px' }}>Published Papers</b>
                                                                        <div className="float-right">
                                                                            <Button type="button" color="info" size="sm" onClick={() => { this.setState({ showPublishedAddModal: true }) }}>
                                                                                <i className="ion-plus"></i>&nbsp;Add
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Table bordered responsive className="tbl" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Title</th>
                                                                                    <th>Place</th>
                                                                                    <th>Date</th>
                                                                                    <th>Manage</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.published.map((item, i) =>
                                                                                    <tr>
                                                                                        <td>{item.title}</td>
                                                                                        <td>{item.place}</td>
                                                                                        <td>{momentDate(item.presentdate)}</td>
                                                                                        <td>
                                                                                            <Button type="button" color="info" size="sm" tooltip="edit" onClick={this.onPublishPaperEdit.bind(this, item.id)}><i className="ion-edit"></i></Button>&nbsp;
                                                                                            <Button type="button" color="danger" size="sm" tooltip="remove" onClick={this.removePaper.bind(this, item.id)}><i className="ion-android-trash"></i></Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Row>
                                                            </Col> :
                                                            this.state.tabId == 5 ?
                                                                <Col md={10}>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <b style={{ fontSize: '16px' }}>Seminar / Conference / Workshops</b>
                                                                            <div className="float-right">
                                                                                <Button type="button" color="info" size="sm" onClick={() => { this.setState({ showSeminarAddModal: true }) }}>
                                                                                    <i className="ion-plus"></i>&nbsp;Add
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <Table bordered responsive className="tb" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Date</th>
                                                                                        <th>Title</th>
                                                                                        <th>Place</th>
                                                                                        <th>Manage</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.seminar.map((item, i) =>
                                                                                        <tr>
                                                                                            <td>{momentDate(item.seminardate)}</td>
                                                                                            <td>{item.title}</td>
                                                                                            <td>{item.place}</td>
                                                                                            <td>
                                                                                                <Button type="button" color="info" size="sm" tooltip="edit" onClick={this.onSeminarEdit.bind(this, item.id)}><i className="ion-edit"></i></Button>&nbsp;
                                                                                                <Button type="button" color="danger" size="sm" tooltip="remove" onClick={this.removeSeminar.bind(this, item.id)}><i className="ion-android-trash"></i></Button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </Table>
                                                                        </Col>
                                                                    </Row>
                                                                </Col> :
                                                                <Col md={10}>

                                                                </Col>}
                                    </Row>
                                    <Modal isOpen={this.state.showModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showModal: false }) }} >
                                            Add Educational Qualification
                                        </ModalHeader>
                                        <ModalBody>
                                            <AddQualification afterFinish={this.loadQualification} onClose={() => { this.setState({ showModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showEditModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showEditModal: false }) }} >
                                            Edit Educational Qualification
                                        </ModalHeader>
                                        <ModalBody>
                                            <EditQualification editData={this.state.editData} afterFinish={this.loadQualification} onClose={() => { this.setState({ showEditModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showExAddModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showExAddModal: false }) }} >
                                            Add Academic Experience
                                        </ModalHeader>
                                        <ModalBody>
                                            <AddExperience afterFinish={this.loadExperience} onClose={() => { this.setState({ showExAddModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showExEditModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showExEditModal: false }) }} >
                                            Edit Academic Experience
                                        </ModalHeader>
                                        <ModalBody>
                                            <EditExperience editData={this.state.editData} afterFinish={this.loadExperience} onClose={() => { this.setState({ showExEditModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showPaperAddModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showPaperAddModal: false }) }} >
                                            Add Papers
                                        </ModalHeader>
                                        <ModalBody>
                                            <AddPapers afterFinish={this.loadPresentedPapers} onClose={() => { this.setState({ showPaperAddModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showPaperEditModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showPaperEditModal: false }) }} >
                                            Edit Papers
                                        </ModalHeader>
                                        <ModalBody>
                                            <EditPapers editData={this.state.editData} afterFinish={this.loadPresentedPapers} onClose={() => { this.setState({ showPaperEditModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showPublishedAddModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showPublishedAddModal: false }) }} >
                                            Add Published Papers
                                        </ModalHeader>
                                        <ModalBody>
                                            <AddPublished afterFinish={this.loadPublishedPapers} onClose={() => { this.setState({ showPublishedAddModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showPublishedEditModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showPublishedEditModal: false }) }} >
                                            Edit Published Papers
                                        </ModalHeader>
                                        <ModalBody>
                                            <EditPublished editData={this.state.editData} afterFinish={this.loadPublishedPapers} onClose={() => { this.setState({ showPublishedEditModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showSeminarAddModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showSeminarAddModal: false }) }} >
                                            Add Seminar
                                        </ModalHeader>
                                        <ModalBody>
                                            <AddSeminar afterFinish={this.loadSeminar} onClose={() => { this.setState({ showSeminarAddModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                    <Modal isOpen={this.state.showSeminarEditModal} size="" backdrop={true} >
                                        <ModalHeader toggle={() => { this.setState({ showSeminarEditModal: false }) }} >
                                            Edit Seminar
                                        </ModalHeader>
                                        <ModalBody>
                                            <EditSeminar editData={this.state.editData} afterFinish={this.loadSeminar} onClose={() => { this.setState({ showSeminarEditModal: false }) }} />
                                        </ModalBody>
                                    </Modal>
                                </form>
                            </CardBody>
                            <Container>
                                <Link href="/#/app/myprofile/5" tooltip="Seminar">
                                    <i className="ion-android-earth"></i>
                                </Link>
                                <Link href="/#/app/myprofile/4" tooltip="Published Papers">
                                    <i className="ion-ios7-world"></i>
                                </Link>
                                <Link href="/#/app/myprofile/3" tooltip="Papers Presented">
                                    <i className="ion-android-note"></i>
                                </Link>
                                <Link href="/#/app/myprofile/2" tooltip="Academic Experience">
                                    <i className="ion-briefcase"></i>
                                </Link>
                                <Link href="/#/app/myprofile/1" tooltip="Educational Qualification">
                                    <i className="ion-android-book"></i>
                                </Link>
                                <Link href="/#/app/myprofile" tooltip="Profile">
                                    <i className="ion-person"></i>
                                </Link>
                                <FloatButton type="button" className="fab-item btn btn-link" color="warning" rotate={true} ><i className="ion-plus"></i></FloatButton>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }

}

export default Profile;