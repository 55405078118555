
export default {
	top: [
			{ name: 'Dashboard', url: '/app/home', icon: 'Home', ionicon: 'ion-grid', },
			{ name: 'Academics', url: '/app/academic', icon: 'Home', ionicon: 'ion-ios7-copy', allowed:['webadmin']},
			/*{ name: 'Students Reports', icon: 'File', ionicon: 'ion-clipboard', allowed: ['admin', 'webadmin'], 
			  children: [
				{ name: 'Students List', url: '/app/reports/studentslist',},
				{ name: 'Address List', url: '/app/reports/addresslist', },
				{ name: 'Caste List', url: '/app/reports/castelist', },
				{ name: 'Export List', url: '/app/reports/exportlist', },
				{ name: 'Custom Export', url: '/app/reports/customexport', },
				{ name: 'Statistic Report', url: '/app/reports/statisticreport', },
			  ],
			},
			{ name: 'Student Atten. Cert.', url: '/app/students/oldstudents/attedancecertificate', icon: 'Home', ionicon: 'ion-document', allowed:['admin'] },
			{ name: 'Messages', icon: 'File', ionicon: 'ion-ios7-chatboxes',allowed: ['admin'], 
			  children: [
				{ name: 'Templates', url: '/app/message/template', },
				{ name: 'Send to Students', url: '/app/message/tostudents', },
				{ name: 'Send to Staffs', url: '/app/message/tostaffs', },
				{ name: 'SMS Report', url: '/app/message/reports', }
			  ],
			},*/
			{ name: 'Academics', url: '/dept/academics', icon: 'Home', ionicon: 'ion-social-buffer',  allowed:['deptadmin', 'researchadmin'] },
			{ name: 'Research', url: '/dept/research', icon: 'Home', ionicon: 'ion-document',  allowed:['researchadmin'] },
			{ name: 'Posts', url: '/app/posts', icon: 'Home', ionicon: 'ion-arrow-graph-up-right',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Pages', url: '/app/pages', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'News & Events', url: '/app/newsandevents', icon: 'Home', ionicon: 'ion-clipboard',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Sliders', url: '/app/manageslider', icon: 'Home', ionicon: 'ion-ios7-albums',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Image Gallery', url: '/app/gallery', icon: 'Home', ionicon: 'ion-ios7-photos-outline',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Video Gallery', url: '/app/video-gallery', icon: 'Home', ionicon: 'ion-ios7-videocam-outline',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Menus', url: '/app/menus', icon: 'Home', ionicon: 'ion-drag',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Users', url: '/app/users', icon: 'Home', ionicon: 'ion-person',  allowed:['webadmin',] },
			{ name: 'Subject', url: '/app/subject', icon: 'Home', ionicon: 'ion-gear-a',  allowed:['staff'] },
			{ name: 'PSO', url: '/app/program-specific-outcomes', icon: 'Home', ionicon: 'ion-ios7-bookmarks-outline',  allowed:['staff'] },
			{ name: 'SSR Report', url: '/app/naac/ssr', icon: 'Home', ionicon: 'ion-ios7-gear-outline',  allowed:['naacadmin'] },
			{ name: 'Accounts', url: '/app/accounts', icon: 'Home', ionicon: 'ion-ios7-calculator-outline', allowed: ['accountant'] },
			{ name: 'Payments', url: '/app/account-payments', icon: 'Home', ionicon: 'ion-ios7-copy-outline', allowed: ['accountant'] },
			{ name: 'Receipts', url: '/app/account-receipts', icon: 'Home', ionicon: 'ion-ios7-copy-outline', allowed: ['accountant'] },
			{ name: 'Accounts Reports', url: '/app/accounts-report', icon: 'Home', ionicon: 'ion-clipboard', allowed: ['accountant'] },
			{ name: 'File Manager', url: '/app/filemanager', icon: 'Home', ionicon: 'ion-ios7-folder', allowed: ['webadmin', 'staff', 'deptadmin', 'researchadmin', 'naacadmin','filemanager', 'siteadmin'] },
			{ name: 'My Profile', url: '/app/myprofile/', icon: 'Home', ionicon: 'ion-person', allowed: ['staff'] },
			{ name: 'Settings', url: '/app/settings', icon: 'Home', ionicon: 'ion-gear-a',  allowed:['webadmin', 'siteadmin'] },
			{ name: 'Fee Payment', url: '/fees/category/payment', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['feecollector'] },
			{ name: 'Payment List', url: '/fees/category/payments', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['feecollector'] },
			{ name: 'Reports', url: '/fees/category/reports', icon: 'Home', ionicon: 'ion-clipboard',  allowed:['feecollector'] },
			{ name: 'Settings', url: '/fees/settings', icon: 'Home', ionicon: 'ion-gear-a',  allowed:['feecollector'] },
		],
	bottom: [
	]
}