import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {Dropdown} from 'primereact/dropdown';

class AddParty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			saveText: 'Save',
			typeList: [],
			selectedTypeId: '',
			selectedCategoryId: '',
		}		
		this.setCategoryType = this.setCategoryType.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.loadType();
	}
	
	loadType(){
		try{
			this.setState({typeLoader: true});
			API.get('v1/accounts/accountscategory').then(res=>{
				if(res['data'].status=='1'){
					this.setState({typeList: res['data'].data, typeLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({typeLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({typeLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(this.state.selectedTypeId==''){
				notify.show('Please select account type','warning');
				return;
			}
			this.setState({showLoader: true, saveText: 'Updating..'});
			API.post('v1/accounts/partyaccount/save', $("#frm_Add_ac_Party").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					document.getElementById("frm_Add_ac_Party").reset();
					this.setState({showLoader: false, saveText: 'Save', selectedTypeId: ''});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, saveText: 'Save'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, saveText: 'Save'});
		}
	}
	
	setCategoryType(e){
		try{
			var d = this.state.typeList.filter(function(obj){
				return(obj.catid==e.value);
			});
			this.setState({selectedTypeId: d[0].typeid, selectedCategoryId: e.value});
		}
		catch(error){ }
	}
	
	render(){

		
	  return (
	  <div>
			<form action="" method="post" id="frm_Add_ac_Party" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
				<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
				<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
				
				<input type="hidden" name="i__typeId" value={this.state.selectedTypeId} />
				<input type="hidden" name="i__categoryId" value={this.state.selectedCategoryId} />
				
				<Row>
					<Col md={2} >
						<Label  className="required"  >Type</Label>
					</Col>
					<Col md={4} className="pl-0" >
						<Input type="select" name="i__partyType" size="sm" required >
							<option value="others" > Others </option>
							<option value="business" > Business </option>
							<option value="person" > Person </option>
							<option value="bank" > Bank </option>
							<option value="cash" > Cash </option>
						</Input>
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={2} >
						<Label  className="required"  >Category</Label>
					</Col>
					<Col md={6} className="pl-0" >
						<Dropdown
							options={this.state.typeList.map((item,i)=>{
								return {label: item.categoryname, value: item.catid};
							})
							}
							filter={true}
							value={this.state.selectedCategoryId}
							onChange={this.setCategoryType}
							className="form-control-sm"
							style={{width:'100%'}}
						/>
						{this.state.typeLoader ? <div className="txt-inline-loader" ><span className="ion-loading-a"></span></div> : null }
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={2} >
						<Label className="" >Name  </Label>
					</Col>
					<Col md={2} className="pl-0"  >
						<Input type="select" size="sm"  name="i__prefix" >
							<option value=""> -Select- </option>
							<option value="M/s" > M/s </option>
							<option value="Mr" > Mr </option>
							<option value="Mrs" > Mrs </option>
							<option value="Miss" > Miss </option>
							<option value="Dr" > Dr </option>
							<option value="Dr" > Er </option>
						</Input>
					</Col>
					<Col md={8} className="pl-0" >
						<Input type="text" size="sm" required placeholder="Name" name="i__name" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={2} >
						<Label className="" >Address </Label>
					</Col>
					<Col md={7} className="pl-0"  >
						<Input type="text" size="sm" placeholder="Address" name="i__address" />
					</Col>
					<Col md={3} className="pl-0" >
						<Input type="text" size="sm" placeholder="City" name="i__city" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={2} >
						<Label className="" >Phone </Label>
					</Col>
					<Col md={4} className="pl-0" >
						<Input type="text" size="sm" placeholder="Phone" name="i__phone" />
					</Col>
					<Col md={6} className="pl-0" >
						<Input type="text" size="sm" placeholder="Email" name="i__email" />
					</Col>
				</Row>
				
				<Row>
					<Col md={12} >
						<hr className="" />
						<div className="text-center" >
							<Button type="reset" size="sm btn-flat" color="danger" disabled={this.state.saveText!='Save'} >
								<span className="ion-close"></span> Cancel
							</Button>
							&nbsp;
							<Button type="submit" size="sm btn-flat" color="success" disabled={this.state.saveText!='Save'} >
								{this.state.saveText=='Save' ? <span className="ion-checkmark"></span> : <span className="ion-loading-a"></span>}
								&nbsp; {this.state.saveText}
							</Button>
						</div>
					</Col>
				</Row>
			</form>			
		</div>
	  );
	}

}

export default AddParty;
