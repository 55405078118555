import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, Collapse, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader} from '../../../elements';
import API from '../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls} from '../../../config';
import CKEditor from 'ckeditor4-react';
import ReactFileReader from 'react-file-reader';
import {reactLocalStorage} from 'reactjs-localstorage';

import ModalFileBrowser from '../../app/filebrowser/ModalFileBrowser';

class AddEmaterials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnUpload: 'Upload Content',
			aboutData: '',
			activeToggleId: '',
			selectedBatch: [],
			dataList: [],
			dataView: [],
			selectedFilename: '', 
			selectedFile: '', 
			selectedUrl: '', 
			addFrmBtn: 'Upload to server',
			deptId: getls('user','deptid'),
			deptCourse: reactLocalStorage.getObject('courselist'),
			deptEmployees: reactLocalStorage.getObject('employeelist'),
		}
		
		this.onContentEditorChange = this.onContentEditorChange.bind(this);		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
	}
	
	componentDidMount(){
		try{
			
			
			
			
		}
		catch(error){
			console.log(error);
		}		
	}
	
	
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ aboutData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }

		
	handleFiles(files){
		try{
			
			this.setState({
				selectedFilename: files.fileList[0]['name'],  selectedFile: files.base64, selectedUrl: ''
			});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handlefileRemove(item){
		try{
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to Delete?',
				buttons: {
					Yes: function(){
						$this.confirmFileRemove(item);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	hadleFormFileRemove(){
		try{
			
			if(!window.confirm('Do you want to remove file?')) return false;
			
			this.setState({
				selectedFile: '',
				selectedFilename: '',
				selectedUrl: ''
			});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
				
			var e_url = document.getElementById("i__externalUrl").value;
			var f_url = document.getElementById("i__fileUrl").value;
			
			if(e_url.length>2 && f_url.length>2)
			{
				notify.show('Enter anyone filed {Externam Url OR File Url}', 'warning');
				return;
			}
			else if(e_url.length<2 && f_url.length<2)
			{
				notify.show('Enter File Path','warning');
				return;
			}
			
			this.setState({showLoader: true, addFrmBtn: 'Uploading...'});
			API.post('v1/department/ematerials/save',$("#frm_Add_Ematerial").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_Add_Ematerial").reset();
					this.setState({addFrmBtn: 'Upload to server', showLoader: false, showAddSyllabus: false, selectedFilename: '', selectedUrl : '', selectedFile: '', aboutData: '' });
					//this.loadEMaterials();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({addFrmBtn: 'Upload to server', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({addFrmBtn: 'Upload to server', showLoader: false});
		}
	}

	
	render(){
		
		let sno=1;
		
		return(
			<div>
				
				<LineLoader loading={this.state.showLoader} />
			
						<form action="" method="post" encType="multipart/form-data" id="frm_Add_Ematerial" onSubmit={this.handleFormSubmit} >			
								
							<input type="hidden" name="i__category" value="ematerials" />
							<input type="hidden" name="i__userId" value={getls('user','id')} />
							<input type="hidden" name="i__deptId" value={this.state.deptId} />
							
							<Row>
								<Col md={3} >
									<Label>Department</Label>
									<Input type="text" size="sm" readOnly value={this.state.deptCourse[0].departmentname} />
								</Col>
								<Col md={6} >
									<Label>Faculty Name</Label>
									<Input type="select" size="sm" name="i__faculty" >
									<option value=""> -Select- </option>
									{this.state.deptEmployees.map((item,i)=>{
										return <option key={i} value={item.id} > {item.empname} {item.qualification} </option>;
									})
									}
									</Input>
								</Col>
								<Col md={3} >
									<Label>File Type</Label>
									<Input type="select" size="sm" name="i__fileType" >
										<option value=""> -Select- </option>
										<option value="text" > Text </option>
										<option value="image" > Image </option>
										<option value="audio" > Audio </option>
										<option value="video" > Video </option>
										<option value="ppt" > PPT </option>
										<option value="pdf" > PDF </option>
									</Input>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={5} >
									<Label>External URL</Label>
									<Input type="url" size="sm" name="i__externalUrl" id="i__externalUrl" />
								</Col>
								<Col md={2} >
									<div className="text-center mt-30"  >
										<Label>(OR)</Label>
									</div>
								</Col>
								<Col md={5} >
									<Label>File URL</Label>
									<div style={{float: 'right'}} >
										<a href="javascript:;"  onClick={()=>{this.setState({selectedUrl: '', selectedFile: ''})}} >
											Remove
										</a>
									</div>
									<div className="input-group" >
										<Input type="text" size="sm" name="i__fileUrl" id="i__fileUrl" value={this.state.selectedUrl} />
										<div className="input-group-addon" style={{cursor: 'pointer' }} onClick={()=>{this.setState({showFileBrowser: true})}}  >
											Browse
										</div>
									</div>
								</Col>
							</Row>
							
							<Row className="mt-15" >
									<Col md={10} >
										<Label>Title <span className="required">*</span></Label>
										<Input type="text" size="sm" name="i__title" required defaultValue={this.state.selectedFilename} />
									</Col>
									<Col md={2} >
										<Label>Course Category</Label>
										<Input type="select" size="sm" name="i__courseCategory" >
											<option value="all" > All </option>
											<option value="ug" > UG </option>
											<option value="pg" > PG </option>
											<option value="mphil" > M.Phil </option>
											<option value="phd" > PhD </option>
										</Input>
									</Col>
									<Col md={12} className="mt-15" 	>
										<Label>Content <span className="required"></span>
											<div style={{position: 'absolute', top: '0px', left: '175px' }} >
												<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
												>
													<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
														Open File Manager
													</Button>
												</ModalFileBrowser>
											</div>
										</Label>
										{this.state.showLoader?  null : <CKEditor 
									name='add_ematerial' 
									config={{ extraPlugins: 'print,font', 
									height: '300', 
									startupShowBorders: 'false', 
									allowedContent: true, 
									extraAllowedContent: '*(*);*{*}' }} 
									data={this.state.aboutData} 
										onChange={this.onContentEditorChange} /> }
										
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={12} >
									<hr />
									<div className="text-center" >
										<Button type="submit" size="sm" color="success" >
												{this.state.addFrmBtn=='Upload to server' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.addFrmBtn}
										</Button>
									</div>
								</Col>
							</Row>
							
							
							<input type="hidden" name="i__ematerial_Pagecontent" value={this.state.aboutData} />
							
						</form>
						
					<ModalFileBrowser isOpen={this.state.showFileBrowser} 
						atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}} 
						fileBrowserClick={this.handleFileBrowserClick} >
						
					</ModalFileBrowser>
					
			</div>
		);
	}

}

export default AddEmaterials;
