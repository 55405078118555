import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'reactstrap';
import {getls} from '../../../config';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	var bId = getls('currentacbusiness','breakageid');
	var billFormat = getls('currentacbusiness', 'billformat');
		
	  return (
		<div className="page-sub-nav page-sub-nav-fixed">
		  <Nav pills>
			<NavItem>
				<NavLink to="/fees/category/reports" exact >
					Daily Report
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/fees/category/reports/monthly"  exact >
					Monthly Report
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/fees/category/reports/yearly"  exact >
					Yearly Report
				</NavLink>
			</NavItem>	
			{bId!=0 ?
			<NavItem>
				<NavLink to="/fees/category/reports/breakage-daily" exact >
					Breakage Daily Report
				</NavLink>
			</NavItem> 
			: null}
			{bId!=0 ?
			<NavItem>
				<NavLink to="/fees/category/reports/breakage-monthly"  exact >
					Breakage Monthly Report
				</NavLink>
			</NavItem>	
			: null}
			{billFormat=='pta' ?
			<NavItem>
				<NavLink to="/fees/category/reports/deptwisereport"  exact >
					Department Wise Report
				</NavLink>
			</NavItem>	
			: null}
		  </Nav>
		</div>
	  );
	}

}
export default Menu;
