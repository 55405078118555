import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../../variables';
import {LineLoader, PageLoader} from '../../../../elements';
import API from '../../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls} from '../../../../config';
import CKEditor from 'ckeditor4-react';

import ModalFileBrowser from '../../filebrowser/ModalFileBrowser';

class Vision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSubmit: 'Update Page',
			visionData: '',
			data: [],
		}
		this.onContentEditorChange = this.onContentEditorChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
	}
	
	componentDidMount(){
		/* 
		var $this = this;
		setTimeout(function() {
			$this.loadPageContent();	
		}, 100); */
		
		this.setState({data: this.props.content, visionData: this.props.content.pagecontent});
	}
	
	componentWillReceiveProps(){
		
		this.setState({data: this.props.content, visionData: this.props.content.pagecontent});
		
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader: true, data: []});
			API.get('v1/academics/page/'+this.props.deptId+'/vision').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, data: res['data'].data, visionData: res['data'].data.pagecontent});
				}
				else
				{
					//notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			 
			this.setState({ visionData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }
	


	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(!this.state.visionData  || !this.state.visionData ==null || this.state.visionData.length<1)
			{
				notify.show('Please enter Page Content','warning');
				return false;
			}
			
			this.setState({showLoader: true, btnSubmit: 'Updating..'});
			API.post('v1/academics/page/update',$("#frm_academic_Vision").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					var d = this.state.data;
					d['id'] = res['data'].pageid;
					this.setState({showLoader: false, btnSubmit: 'Update Page', data: d });
					if(res['data'].data && Object.keys(res['data'].data)>0)
						this.setState({data: res['data'].data});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSubmit: 'Update Page'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnSubmit: 'Update Page'});
			console.log(error);
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			if(d[fieldName])
				return d[fieldName];
			else return '';
		}
		catch(error){
			console.log(error);
		}
	}

	
	render(){
		
		
		return(
			<div>
				
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_academic_Vision" onSubmit={this.handleFormSubmit} >
				
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__category" value="vision" />
				<input type="hidden" name="i__deptId" value={this.props.deptId} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				<Row>
					<Col md={12} >
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<div className="text-left" >
										<div className="pretty p-icon p-smooth">
											{this.getField('showinpage')=='1' ? 
												<input type="checkbox" name="i__about_ShowInPage" defaultChecked={true} /> :
												<input type="checkbox" name="i__about_ShowInPage"  />
											}
											<div className="state p-success">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show In Page</label>
											</div>
										</div>
										</div>
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
								</Row>
								
								<Row className="mt-20" >
									<Col md={12} >
										<Label>Vison & Mission Content <span className="required">*</span>
											<div style={{position: 'absolute', top: '0px', left: '175px' }} >
												<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
												>
													<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
														Open File Manager
													</Button>
												</ModalFileBrowser>
											</div>
										</Label>

											<CKEditor 
											name='vision_editor' 
											config={{ extraPlugins: 'print,font', 
											height: '700', 
											startupShowBorders: 'false', 
											allowedContent: true, 
											extraAllowedContent: '*(*);*{*}' }} 
											data={this.state.visionData} 
											onChange={this.onContentEditorChange} />
									</Col>
									
									<Col md={12} className="mt-15" >
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
									
								</Row>
								
							</CardBody>
						</Card>
					</Col>
				</Row>
				
				<input type="hidden" name="i__about_Pagecontent" value={this.state.visionData} />
				
				</form>
				  
			</div>
		);
	}

}

export default Vision;
