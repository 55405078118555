import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version, Api} from '../../../../variables';
import API from '../../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../../config';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';

import View from '../../reports/addresslist/view';
import PrintBasic from '../../reports/printbasic';
import Edit from './edit';

class Studentslist extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		  showEditModal: false,
		  loginText: 'Login',
		  selectedCourse: '',
		  selectedBatch: '',
		  courseList: [],
		  courseView: [],
		  courseType: [],
		  batchList: [],
		  batchView: [],
		  dataList: [],
		  dataView: [],
		  studenT: [],
		  casteList: [],
		  communityList: [],
		}
		
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}
	
	componentDidMount(){
		this.loadCourses();
	}
	
	loadCourses(){
		try{
			
			axios.get(Api.AcademicPath+'course/coursewithtype/').then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					var ctype = groupByMultiple(res['data'].batch,function(obj){
						return [obj.type];
					});
					
					this.setState({courseView: d, courseList: d, courseType: ctype, batchList: res['data'].batch});
					this.filterCourseByType('ug');
					this.loadBatchByType('ug');
				}
				else
				{
					
				}
			});
		}
		catch(error){
			
		}
	}
	
	filterCourseByType(courseType){
		try{			
			
			var d = this.state.courseList.filter(function(obj){
				return (upperCase(obj.type)==upperCase(courseType));
			});
			
			this.setState({courseView: d});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleSearchSubmit(e){
		try{
			if(e) e.preventDefault();
			
			this.setState({showDataLoader: true, dataList: [], dataView: []});
			API.post('studentsreport/addresslist/',$("#frmInput").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					var caste = groupByMultiple(d,function(obj){
						return [obj.caste];
					});
					var community = groupByMultiple(d,function(obj){
						return [obj.community];
					});
					this.setState({dataList: res['data'].data, dataView: res['data'].data, casteList: caste, communityList: community, showDataLoader:false});
				}
				else
				{
					this.setState({showDataLoader:false});
					notify.show(res['data'].msg,'error');
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showDataLoader: false});
		}
	}
	
	handleViewClick(studentId){
		try{
			
			var d = this.state.dataView.filter(function(obj){
				return (obj.id==studentId);
			});
			var course = $("#i__course option:selected").text();
			var batch = $("#i__batch option:selected").text();
			this.setState({studenT:d[0], selectedCourse: course, selectedBatch:batch, showStudentModal:true});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEditClick(studentId){
		try{
			
			var d = this.state.dataView.filter(function(obj){
				return (obj.id==studentId);
			});
			var course = $("#i__course option:selected").text();
			var batch = $("#i__batch option:selected").text();
			this.setState({studenT:d[0], selectedCourse: course, selectedBatch:batch, showEditModal:true});
		}
		catch(error){
			console.log(error);
		}
	}
	
	loadBatchByType(type){
		try{
			
			var d = this.state.batchList.filter(function(obj){
				console.log(obj);
				return (upperCase(obj.type)==upperCase(type));
			});
			this.setState({batchView:d});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleSearch(e){
		try{
			var v = e.target.value.toUpperCase();
			
			var d = this.state.dataList.filter(function(obj){
				const EROLNO = upperCase(obj.temp_registerno);
				const REGNO = upperCase(obj.registerno);
				const ROLNO = upperCase(obj.registerno);
				const NAME = upperCase(obj.name);
				const MOBILE = (obj.mobile);
				return((EROLNO.indexOf(v)>-1) || (ROLNO.indexOf(v)>-1) || (NAME.indexOf(v)>-1) || (REGNO.indexOf(v)>-1) || (MOBILE.indexOf(v)>-1));
			});
			this.setState({dataView:d});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleDropdownChange(field,e){
		try{
			var v = e.target.value.toUpperCase();
			
			if(v=='')
			{
				this.setState({dataView: this.state.dataList});
				return;
			}
			
			var d = this.state.dataList.filter(function(obj){
				const FIELD = upperCase(obj[field]);
				return((FIELD==v));
			});
			this.setState({dataView:d});
		}
		catch(error){
			console.log(error);
		}
	}
	
	printDocument(printId){
			
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
	}
	
	getSelectedOptionValue(fieldId){
		try{
			var value = $("#"+ fieldId +" option:selected").text();
			return value;
		}
		catch(error){
			console.log(error);
			return "";
		}
	}
	
	render(){
		
	let sno = 1;
		
	  return (
		<Row>
			<Col md={12} >
			
			<form action="" method="post" encType="multipart/form-data" id="frmInput" onSubmit={this.handleSearchSubmit} >
				<Row>
					<Col md={2} >
						<Label>Course Type</Label>
						<select className="form-control form-control-sm" name="i__coursetype" id="i__coursetype" required 
							onChange={(e)=>{this.filterCourseByType(e.target.value); this.loadBatchByType(e.target.value);}}  >
						{this.state.courseType.map((item,i)=>{
							return <option value={item[0].type} > {item[0].type} </option>;
						})							
						}
						</select>
					</Col>
					<Col md={4} >
						<Label>Course</Label>
						<select className="form-control form-control-sm" name="i__course" id="i__course" required >
						{this.state.courseView.map((item,i)=>{
							return <option value={item.id} > {item.coursename} - {item.name} {item.coursetype=='self' ? '(SF)' : '(R)'} </option>;
						})							
						}
						</select>
					</Col>
					<Col md={2} >
						<Label>Batch</Label>
						<select className="form-control form-control-sm" name="i__batch" id="i__batch" required >
						{this.state.batchView.map((item,i)=>{
							return <option value={item.value} > {item.value} </option>;
						})							
						}
						</select>
					</Col>
					<Col md={2} >
						<Button type="submit" color="primary" size="sm" style={{ marginTop: '28px' }} >
							<span className="ion-ios7-search-strong"></span>  Search
						</Button>
					</Col>	
					<Col md={2} >
						<ButtonGroup style={{ marginTop: '27px' }} >
							<ReactHTMLTableToExcel type="button" className="btn btn-outline-secondary btn-sm"
								table="tbllist"
								filename="students_caste_list"
								sheet="Students"
								buttonText={<span><span className="ion-ios7-download-outline"></span> Export</span>}
							/>
							<button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.printDocument.bind(this,"#print_div")} >
								<span className="ion-ios7-printer"></span> Print
							</button>
						</ButtonGroup>
					</Col>					
				</Row>
			</form>
			
			<Row style={{ marginTop: '10px' }} >
				<Col md={12} >
					<Card>
						<CardBody>
							<Row>
								<Col md={6} >
								<div className="input-group" style={{ marginBottom: '15px' }} >
									<span className="input-group-addon">
										<span className="ion-android-search"></span>
									</span>
									<input type="text" className="form-control form-control-sm" placeholder="Search using Enrolment No, Register No, Mobile, Name.."  onChange={this.handleSearch} />
								</div>
							</Col>
							<Col md={3} >
								<div className="input-group" style={{ marginBottom: '15px' }} >
									<span className="input-group-addon">
										<span className="ion-android-search"></span> CASTE
									</span>
									<select name="i__caste" id="i__caste" className="form-control form-control-sm" onChange={this.handleDropdownChange.bind(this,'caste')} >
									<option value=""> All </option>
									{this.state.casteList.map((item,i)=>{
										return <option value={item[0].caste} > {upperCase(item[0].caste)} </option>;
									})							
									}
									</select>
								</div>
							</Col>
							<Col md={3} >
								<div className="input-group" style={{ marginBottom: '15px' }} >
									<span className="input-group-addon">
										<span className="ion-android-search"></span> COMMU.
									</span>
									<select name="i__community" id="i__community" className="form-control form-control-sm" onChange={this.handleDropdownChange.bind(this,'community')} >
									<option value=""> All </option>
									{this.state.communityList.map((item,i)=>{
										return <option value={item[0].community} > {upperCase(item[0].community)} </option>;
									})							
									}
									</select>
								</div>
							</Col>
						</Row>
								<Table responsive hover className="tbl tbl-text-small" >
									<thead>
										<tr>
											<th>S.No</th>
											<th>ENROLMENT NO</th>
											<th>REGISTER NO</th>
											<th>NAME</th>
											<th>GENDER</th>		
											<th>COMMU.</th>
											<th>CASTE</th>
											<th>Father Name</th>											
											<th>MOBILE</th>
											<th>CITY</th>
											<th>MANAGE</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										return <tr key={i} >
											<td>{sno++}</td>
											<td>{item.registerno != null && item.registerno.length > 0 ? item.registerno : item.temp_registerno}</td>
											<td>{item.registerno}</td>
											<td>{item.name}</td>
											<td>{capitalizeFirst(item.gender)}</td>
											<td>{upperCase(item.community)}</td>
											<td>{upperCase(item.caste)}</td>
											<td>{item.fathername}</td>											
											<td>{item.mobile}</td>											
											<td>{item.city_permanent}</td>		
											<td>
												<Button size="xs" color="info" title="View"  onClick={this.handleViewClick.bind(this,item.id)} >
													<span className="ion-eye" ></span>
												</Button>
												&nbsp;
												<Button size="xs" color="warning" title="Edit"  onClick={this.handleEditClick.bind(this,item.id)} >
													<span className="ion-edit" ></span>
												</Button>
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
							{this.state.showDataLoader ? <Loader type="dot" /> : null}
							
						</CardBody>
					</Card>
				</Col>
			</Row>
			
			
			<PrintBasic printId="#print_div" tableId="tbllist" dataList={this.state.dataView} 
				header={"Course / Batch : "+ this.getSelectedOptionValue("i__course") +" / "+this.getSelectedOptionValue("i__batch")}
				columns={[
					{field:'temp_registerno',value:'ENROL.NO'},
					{field:'registerno',value:'REG.NO'},
					{field:'name',value:'NAME'},
					{field:'gender',value:'GENDER'},
					{field:'community',value:'COMMU.'},
					{field:'caste',value:'CASTE'},
					{field:'fathername',value:'FATHER NAME'},
					{field:'mobile',value:'MOBILE'},
					{field:'email',value:'EMAIL'}
				]} />
			
			<Modal isOpen={this.state.showStudentModal} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showStudentModal:false})}} >
					<span className="ion-person"></span> &nbsp;&nbsp; View Student
				</ModalHeader>
				<ModalBody>
					<View student={this.state.studenT} course={this.state.selectedCourse} batch={this.state.selectedBatch} />
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditModal} size="xxl" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditModal:false})}} >
					<span className="ion-person"></span> &nbsp;&nbsp; Edit Student
				</ModalHeader>
				<ModalBody>
					<Edit student={this.state.studenT} course={this.state.selectedCourse} batch={this.state.selectedBatch}
						afterFinish={this.handleSearchSubmit}
					/>
				</ModalBody>
			</Modal>
			
			<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
			
			</Col>
		</Row>
	  );
	}

}

export default Studentslist;
