import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api } from '../../../variables';
import API from '../../../config/API';
import axios from 'axios';
import { setls, upperCase, capitalizeFirst, groupByMultiple, getls } from '../../../config';
import Select from 'react-select';
import { Container, Button as FloatButton, Link } from 'react-floating-action-button';

class EditQualification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveText: 'Update',
            id: getls('user', 'empid'),
            editData: this.props.editData
        }
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
    }

    handleModalSubmit(e) {
        try {
            e.preventDefault();
            this.setState({ showLoader: true, saveText: 'Updatng' });
            axios.post(Api.AcademicPath + 'staff/updatequalification', $("#frmEditModal").serialize()).then(response => {
                if (response['data'].status == '1') {
                    this.setState({ showLoader: false, saveText: 'Update' });
                    document.getElementById('frmEditModal').reset();
                    notify.show(response['data'].msg, 'success');
                    this.props.afterFinish();
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    getValue(field) {
        var d = this.state.editData;
        return d[field];
    }

    setValue(field, e) {
        var d = this.state.editData;
        d[field] = e.target.value;
        this.setState({ editData: d });
    }

    render() {


        let getYear = [];
        var today = new Date();
        var curyear = today.getFullYear();
        for (var i = 1980; i <= curyear; i++) {
            getYear.push(<option value={i}>{i}</option>);
        }

        return (
            <div>
                <form action="" method="post" encType="multipart/form-data" id="frmEditModal" onSubmit={this.handleModalSubmit} >
                    <input type="hidden" name="id" value={this.getValue('id')} />
                    <input type="hidden" name="empid" value={this.state.id} />
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Category</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="category" value={this.getValue('category')} onChange={this.setValue.bind(this, 'category')} required>
                                    <option value="">Category</option>
                                    <option value="UG">UG</option>
                                    <option value="PG">PG</option>
                                    <option value="Research">Research</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Degree</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="degree" value={this.getValue('degree')} onChange={this.setValue.bind(this, 'degree')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Specialization</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="specialization" value={this.getValue('specialization')} onChange={this.setValue.bind(this, 'specialization')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Passing Year</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="select" size="sm" name="yop" value={this.getValue('passyear')} onChange={this.setValue.bind(this, 'passyea')} required>
                                    <option value="">Year</option>
                                    {getYear}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>College</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="college" value={this.getValue('college')} onChange={this.setValue.bind(this, 'college')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>University</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="university" value={this.getValue('university')} onChange={this.setValue.bind(this, 'university')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Percentage</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="percentage" value={this.getValue('percentage')} onChange={this.setValue.bind(this, 'percentage')} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Class Obtained</Label>
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <Input type="text" size="sm" name="pclass" value={this.getValue('passclass')} onChange={this.setValue.bind(this, 'passclass')} required />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="float-right">
                                    <Button type="button" color="danger" onClick={this.props.onClose}>
                                        Cancel
                                    </Button>&nbsp;
                                    <Button type="submit" color="primary" disabled={this.state.saveText != 'Update' ? true : false}>
                                        {this.state.saveText != 'Update' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div >);
    }

}

export default EditQualification;
