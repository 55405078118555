import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader, Switch } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getls} from '../../../config';

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';
import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

const styles = {
	ftCss: {
		borderTop: '1px solid rgba(34,36,38,.15)',
		padding: '10px 0px 0px 0px',
	}
}

class EditVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
			showLoader: false,
			linkList: [],
			selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
			editData: this.props.editData,
		}
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.setState({
			selectedUrl: this.props.editData['url']
		});
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	setField(fieldName, e){
		try{
			
			var d = this.state.editData;
			d[fieldName] = e.target.value;
			this.setState({editData: d});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/videogallery/update', $("#frm_editlider").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSave: 'Update'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Update'});
					
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Update'});
		}
	}
	

	
	
	render(){
		
		return(
			<div>
				<form action="" method="post" encType="multipart/form-data" id="frm_editlider" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__id" value={this.getField('id')} />
					
					
					{/*<Row>
						<Col md={12} >
							<Label>Slider Image <span className="required"> (1920 x 800)</span></Label>
							<ModalFileBrowser isOpen={this.state.showfileBrowser} 
													atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
														fileBrowserClick={this.handleFileBrowserClick}
													>
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUploadPath+this.state.selectedUrl} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							</ModalFileBrowser>
						</Col>
					</Row>*/}
					
					<Row>
						<Col md={12} >
							<Label>Youtube Video Embeded URL <span className="required">*</span></Label>
							<Input type="text" name="i__fileUrl" size="sm"  placeholder="Youtube Video Embeded Url" required defaultValue={this.state.selectedUrl} onChange={this.handleYurlChange} />
						</Col>
						<Col md={4} className="mt-15" >
							<iframe src={this.state.selectedUrl} border="0"></iframe>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={10} >
							<Label>Video Title<span className="required"></span></Label>
							<Input type="text" name="i__title" size="sm"  placeholder="Video Title" value={this.getField('title')} onChange={this.setField.bind(this,'title')} />
						</Col>
						<Col md={2} >
							<Label>Video Order <span className="required"></span></Label>
							<Input type="number" name="i__order" size="sm"  placeholder="Order" value={this.getField('slideorder')} onChange={this.setField.bind(this,'slideorder')} />
						</Col>
					</Row>
				
					<div style={styles.ftCss} className="mt-20" >
						<div className="float-left" >
							<div className="pretty p-default">
								<input type="checkbox" name="i__active"  defaultChecked={this.getField('activestatus')=='1'} />
								<div className="state p-success">
									<label>Active</label>
								</div>
							</div>
						</div>
						<div className="float-right" >
							<Button type="submit" color="success" size="sm" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave=='Update' ? 
								<span className="ion-checkmark"></span> : 
								<span className="ion-loading-a"></span>}
								&nbsp; {this.state.btnSave}
							</Button>
							&nbsp;
							<Button type="reset" color="danger" size="sm" onClick={this.props.atClose} >
								Cancel 
							</Button>
							
						</div>
					</div>
				
				</form>
			</div>
		);
	}

}

export default EditVideo;
