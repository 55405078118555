import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls, decimalKeyPress} from '../../../config';
import {PageLoader, LineLoader, BlockLoader} from '../../../elements';

import Menu from './menu';

class HostelFeeSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			categoryList: [],
			assignedFee: [],
			currentPage: 1,
			todosPerPage: 20,
			activePage : 1,
			btnText: 'Update',
		}
		this.loadCategory = this.loadCategory.bind(this);
		this.loadAssignedFees = this.loadAssignedFees.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadCategory();
		this.loadAssignedFees();
	}
	
	loadCategory() {
		try {

			this.setState({ showLoader: true, categoryList: [] });
			var cid = getls('currentacbusiness', 'id');
			API.get('v1/fees/category/' + cid).then(res => {
				if (res['data'].status == '1') {
					this.setState({ categoryList: res['data'].data, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}
	
	
	loadAssignedFees() {
		try {

			this.setState({ showLoader: true, assignedFee: [] });
			var cid = getls('user', 'company');
			var bid = getls('currentacbusiness', 'id');
			var aid = getls('currentacyear', 'id');
			var form = new FormData();
			form.append('i__companyId', cid);
			form.append('i__businessId', bid);
			form.append('i__acyearId', aid);
			
			API.post('v1/fees/feeassign/all', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ assignedFee: res['data'].data, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/fees/feeassign/save', $("#frmFeeSettings").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, btnText: 'Update'});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'Update'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Update'});
		}
	}
	
	getFee(partyId, forType){
		try{
			var d = this.state.assignedFee;
			var f = d.filter(function(obj){
				return (obj.partyid==partyId);
			});
			if(Object.keys(f).length>0){
				if(forType=='new') 
					return f[0]['fornew'];
				else if(forType=='regular') 
					return f[0]['forregular'];
			}
			else return '';
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
	let sno=1;
	
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			<BlockLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Card>
					<CardBody >
					
						<form action="" method="post" encType="multipart/form-data" id="frmFeeSettings" onSubmit={this.handleFormSubmit} >
						
								<input type="hidden" name="i__userId" value={getls('user', 'id')} />
								<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
								<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
								<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
								
						<Row>
							<Col md={12} >						
								<Table className="tbl" >
									<thead>
										<tr>
											<th width="100" >S.No</th>
											<th>Category</th>
											<th width="200" >For NEW Students</th>
											<th width="200" >For REGULAR Students</th>
										</tr>
									</thead>
									<tbody>
									{this.state.categoryList.map((item,i)=>{
										return <tr key={i} >
											<td>{sno++}</td>
											<td>{item.partyname}
												<Input type="hidden" name="i__categoryId[]" value={item.catid} />
												<Input type="hidden" name="i__partyId[]" value={item.partyid} />
											</td>
											<td>
												<Input type="text" size="sm" onKeyPress={decimalKeyPress} style={{textAlign: 'right'}} name="i__forNew[]" defaultValue={this.getFee(item.partyid, 'new')} />
											</td>
											<td>
												<Input type="text" size="sm" onKeyPress={decimalKeyPress}  style={{textAlign: 'right'}} name="i__forRegular[]" defaultValue={this.getFee(item.partyid, 'regular')} />
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
								
							</Col>
						</Row>
						<Row>
							<Col md={12} >
								<div className="text-right" >
									<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Update'} >
										{this.state.btnText}
									</Button>
								</div>
							</Col>
						</Row>
								
						</form>
						
					</CardBody>
				</Card>
				
			</div>
		
		
		</div>
	  );
	}

}

export default HostelFeeSettings;
