import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Progress, CardHeader} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import API from '../../../config/API';
import {setls, getls, upperCase,capitalizeFirst, groupByMultiple, numberWithCommas} from '../../../config';


class FeeCollectorHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			paymentList: [],
			feeTotal: '0.00',
			breakageTotal: '0.00',
		}		
	}
	
	componentDidMount(){
		this.loadVouchers();
	}
	
	loadVouchers(){
		try{
			
			this.setState({showLoader:true, dataList: [], dataView: []});
			var form = new FormData();
			form.append('type', 'payment');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('breakageid', getls('currentacbusiness', 'breakageid'));
			form.append('groupby', 'voucherid');
			
			API.post('v1/fees/home', form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						paymentList: res['data'].vouchers, 
						feeTotal: res['data'].feetotal, 
						breakageTotal: res['data'].breakagetotal, 
						showLoader: false
					});	
				}
				else
				{
					//notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	loadVoucherIn(item){
		try{
			var rv = [];
			
			rv.push(<div style={{borderBottom:'1px solid #e4e5e6', marginBottom:'5px', paddingBottom:'10px'}} >
				<div style={{color:'#888585'}} >{item.name}</div>
				<div style={{color:'#888585'}} >{item.voucherid}</div>
				<div style={{fontSize: '19px',fontWeight: '800',color:'grey',float: 'right',marginTop: '-18px'}} >{numberWithCommas(parseFloat(item.netamount).toFixed(0))}</div>
			</div>);
			
			return rv;
		}
		catch(error){
			console.log(error);
			return '';
		}
	}

	render(){
		
	
	
	  return (
		<div className="mt-20" >
		
			<Row>
				<Col md={9} >
					
					<Row>
						
						 <Col md={6} xs={12}>
							<Card>
							  <CardHeader>
								Semester / Other Fees Collection
							  </CardHeader>
							  <CardBody>
								<h2 className="m-b-20 inline-block">
								  <span>Rs. {numberWithCommas(parseFloat(this.state.feeTotal).toFixed(0))}</span>
								</h2>{' '}
								<i className="ion-android-dropdown text-danger" aria-hidden="true" />
								<Progress value={80} color="success" />
							  </CardBody>
							</Card>
						  </Col>
						  
						  <Col md={6} xs={12}>
							<Card>
							  <CardHeader>
								Breakage Fees Collection
							  </CardHeader>
							  <CardBody>
								<h2 className="m-b-20 inline-block">
								  <span>Rs. {numberWithCommas(parseFloat(this.state.breakageTotal).toFixed(0))}</span>
								</h2>{' '}
								<i className="ion-android-dropdown text-danger" aria-hidden="true" />
								<Progress value={80} color="info" />
							  </CardBody>
							</Card>
						  </Col>
						  
					</Row>
					
				</Col>
				
				<Col md={3} >
					<Card>
						<CardBody className="pb-0"  >
							<CardTitle>Today Payments</CardTitle>
							<hr />
						</CardBody>
						<CardBody className="pt-0" >
						
						{this.state.showLoader ? <Loader /> : <div>
							{Object.keys(this.state.paymentList).length>0 ? <div>
								{this.state.paymentList.map((item,i)=>{
									return this.loadVoucherIn(item);
								})
								}
							</div> : <div>No payments on today..</div>}
						</div>}
						
						</CardBody>
						<CardBody  >
							<hr />
							<span style={{float:'left'}} >
								<Link to="/fees/category/payments" >View all</Link>
							</span>
						</CardBody>
					</Card>
				</Col>
			</Row>
			
			
		</div>
	  );
	}

}

export default FeeCollectorHome;
