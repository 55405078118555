import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Collapse} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, getls, makeUrl} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import JoditEditor from "jodit-react";
import "jodit/build/jodit.css";

import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';


import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

import Menu from './menu';

const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '21px',
		top: '69px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class EditPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update Page',
		  showStudentModal: false,
		  showFileManager: false,
		  showEditModal: false,
		  isActive: true,
		  editSlug: false,
		  pageData: '',
		  inTab: false,
		  inFeatureImage: false,
		  selectedFilename: '',
		  selectedUrl: '',
		  pageUrl: '',
		  pid: this.props.match.params.id,
		  data: [],
		}
		
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.removeSelectedFeatureImage = this.removeSelectedFeatureImage.bind(this);	
		this.handleTitleChange = this.handleTitleChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);	
		this.handleJoditChange = this.handleJoditChange.bind(this);
	}
	
	componentDidMount(){
	
		//page-heading
		try{
			
			this.loadPage();
			var h = document.getElementsByClassName("page-heading")[0];
			if(h!=null && h!=undefined)
				h.innerHTML = "Edit Page";
		}
		catch(error){
			
		}
	}
	
	loadPage(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/page/'+this.state.pid).then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					this.setState({
						isActive: d.activestatus==1,
						pageUrl: d.pageurl,
						pageData: d.pagecontent,
						inFeatureImage: (d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3) ? true : false,
						selectedFilename: '',
						selectedUrl: d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3 ? d.featureimage : '',
						data: d,
						showLoader: false
					});
					//console.log(this.state.inFeatureImage);
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	removeSelectedFeatureImage(){
		this.setState({ selectedFilename: '',selectedUrl: '' });
	}
	
	
	handleTitleChange(e){
		try{
			var str = e.target.value;
			var newString = makeUrl(str); //str.replace(/[^A-Z0-9]/ig, "-");
			this.setState({pageUrl: newString.toLowerCase()});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/page/update/', $("#frmAdd_Page").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update Page', showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update Page', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update Page', showLoader: false});
		}
	}
	
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	setField(fieldName,e){
		try{
			
			var d = this.state.data;
			d[fieldName] = e.target.valuel
			this.setState({data: d});
		}
		catch(error){
			
		}
	}
	
	
	handleJoditChange(content){
		
		this.setState({pageData: content});
	}
	
	render(){
		
	let sno = 1;
		
	  return (
	  <div>
			
			<Menu />
			
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<div className="pt-50" >
			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd_Page" onSubmit={this.handleFormSubmit} >
				
				<input type="hidden" name="i__id" value={this.getField('id')} />
			
				<Row>
					<Col md={9} >
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<Label>Title <span className="required">*</span></Label>
										<Input type="text" name="i__pageTitle" size="sm" required placeholder="Page Title" onChange={this.handleTitleChange} defaultValue={this.getField('pagetitle')} />
									</Col>
								</Row>
								<Row className="mt-20" >
									<Col md={12} >
										<div className="input-group">
											<span className="input-group-addon" >
												{Api.SiteUrl}
											</span>
											<Input type="text" name="i__pageUrl" size="sm" required placeholder="Page URL" readOnly={!this.state.editSlug} value={this.state.pageUrl} onChange={(e)=>{this.setState({pageUrl: e.target.value})}} />
											<span className="input-group-addon"  >
												<a href="javascript:;" onClick={()=>{this.setState({editSlug: !this.state.editSlug})}} >
													<span className="ion-edit"></span>
												</a>
											</span>
										</div>
									</Col>
								</Row>
								<Row className="mt-20" >
									<Col md={12} >
										<Label>Page Content <span className="required">*</span>
											<div style={{position: 'absolute',top: '-1px',left: '150px'}} >									
											<Button size="xs" color="info" onClick={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
												Open Filemanager
											</Button>
											</div>
										</Label>
										<div className="page-content-editor" >
										<JoditEditor
												value={this.state.pageData}
												onBlur={this.handleJoditChange}
												onChange={this.handleJoditChange}
										/>
										{/*<SunEditor 
											setOptions={{
													height: 500,
													katex: katex,
													  buttonList : [
														['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
														['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
														['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
														['math'],
														['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview',]
													],
											}} 
											setContents={this.state.pageData}  
											onChange={this.handleJoditChange}
										/>*/}
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
						
						<Card>
							<CardBody>
								<CardTitle>Social Meta Details</CardTitle>
								<hr />								
								<Row>
									<Col md={12} >
										<Label>Meta Title</Label>
										<textarea  name="i__metaTitle" className="form-control form-control-sm" rows="2" placeholder="Meta Title" value={this.getField('metatitle')} onChange={this.setField.bind(this,'metatitle')} ></textarea>
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={12} >
										<Label>Meta Description</Label>
										<Input type="textarea" name="i__metaDescription" size="sm" rows="3" placeholder="Meta Description" value={this.getField('metadescription')} onChange={this.setField.bind(this,'metadescription')}  />
									</Col>
								</Row>
							</CardBody>
						</Card>
						
					</Col>
					
					<Col md={3} >
						
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<Label>Page Template</Label>
										<Input type="select" size="sm" name="i__pageTemplate" >
											<option value="withsidebar" selected={this.getField('pagetemplate')=='withsidebar' ? 'selected' : ''} > Page With Sidebar </option>
											<option value="content" selected={this.getField('pagetemplate')=='content' ? 'selected' : ''}  > Content Only </option>
										</Input>
									</Col>
								</Row>
								{/*<Row className="mt-10" >
									<Col md={12} >
										<Label>Category</Label>
										<Input type="select" size="sm" name="i__pageCategory" >
											<option value="withsidebar" > Page With Sidebar </option>
											<option value="content"> Content Only </option>
										</Input>
									</Col>
								</Row>*/}
								<Row className="mt-10" >
									<Col md={12} >
										<hr />
										<div className="float-left">
											 <div className="pretty p-icon p-smooth">
												<input type="checkbox" name="i__active" defaultChecked={this.state.isActive} />
												<div className="state p-success">
													<i className="icon ion-ios7-checkmark-empty"></i>
													<label>Active</label>
												</div>
											</div>
										</div>
										<div className="float-right" >
										<Button type="submit" size="sm" color="primary" >
											{this.state.btnSave=='Update Page' ? 
												<span className="ion-android-checkmark" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnSave}
										</Button>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
						
						{/*<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<div className="pretty p-icon p-smooth">
										<input type="checkbox" name="i__inTab" defaultChecked={this.state.inTab} onChange={()=>{this.setState({inTab: !this.state.inTab})}}  />
										<div className="state p-primary">
											<i className="icon ion-ios7-checkmark-empty"></i>
											<label>Display in Tab</label>
										</div>
									</div>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
							</CardBody>
							<Collapse isOpen={this.state.inTab}>
								<CardBody style={{paddingTop: '5px', marginTop: '0px' }} >
									<Row>
										<Col md={12} >
											<Label>Tab Title</Label>
											<Input type="text" name="i__tabTitle" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Tab Order</Label>
											<Input type="number" name="i__tabOrder" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Under Menu</Label>
											<select name="i__tabMenu" className="form-control form-control-sm" >
											
											</select>
										</Col>
									</Row>
								</CardBody>
							</Collapse>
						</Card>*/}
						
						<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<div className="pretty p-icon p-smooth">
									{this.state.inFeatureImage ?
										<input type="checkbox" name="i__inFeatureImage"  checked={true} onChange={()=>{this.setState({inFeatureImage: !this.state.inFeatureImage})}} />
										: <input type="checkbox" name="i__inFeatureImage"   checked={false} onChange={()=>{this.setState({inFeatureImage: !this.state.inFeatureImage})}} />
									}
										<div className="state p-primary">
											<i className="icon ion-ios7-checkmark-empty"></i>
											<label>Feature Image</label>
										</div>
									</div>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
								<Collapse isOpen={this.state.inFeatureImage}>
									<div style={{marginTop: '15px', marginBottom: '5px' }} >
										<img src={this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3 ? Api.ImageUploadPath+this.state.selectedUrl : NOIMAGE_WIDE} />
											{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3  ?
												<div style={styles.imgCloseBtn} onClick={this.removeSelectedFeatureImage} >
													<span className="ion-android-close"></span>
												</div>
											: null}
									</div>
									<hr style={{marginBottom:'10px'}} />
									<div className="text-center mb-10" >
									<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
													fileBrowserClick={this.handleFileBrowserClick}
												>
									<Button type="button" size="sm" color="secondary" block onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
										<span className="ion-android-folder"></span> Browse Image
									</Button>
									</ModalFileBrowser>
									</div>
								</Collapse>
							</CardBody>
						</Card>
						
					</Col>
				</Row>
				
				<input type="hidden" name="i__pageData" value={this.state.pageData} />
				<input type="hidden" name="i__featureImage" value={this.state.selectedUrl} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				</form>
				
			</div>
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
			</ModalFileBrowser>		
		
		</div>
	  );
	}

}

export default EditPage;
