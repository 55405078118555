import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version, ImgPath} from '../../../../variables';
import {capitalizeFirst, upperCase, yesorNo, isNullOrEmpty} from '../../../../config';
import AVATAR_MALE from '../../../../assets/images/avatar_male.png';
import AVATAR_FEMALE from '../../../../assets/images/avatar_female.png';

class View extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	getValue(field){
		try{
			
			var d = this.props.student;
			return d[field];
		}
		catch(error){
			console.log(error);
			return '';
		}
	}
	
	render(){
		
	let sno = 1;
		
	let PHOTO = this.props.student['gender'].toLowerCase()=='male' ? AVATAR_MALE : AVATAR_FEMALE;

	var userImage = this.getValue('profile_photo');
	if(userImage!='' && userImage!=null && userImage.length>4)
		PHOTO = ImgPath.StudentProfilePhoto+ userImage;
	
	  return (
		<Row>
			<Col md={12} >
				<Table className="table" stripped >
					<tbody>
						<tr>
							<td className="" >ENROLMENT NO</td>
							<td className="bold" >{this.getValue('temp_registerno')}</td>
							<td colSpan="2" rowSpan="6" align="center" >
								<img src={PHOTO} style={{width:'180px'}} />
							</td>							
						</tr>
						<tr>
							<td>REGISTER NO</td>
							<td className="bold" >{this.getValue('registerno')}</td>
						</tr>
						<tr>
							<td className="" >SMART ID</td>
							<td className="bold" >{this.getValue('smartid')}</td>
						</tr>
						<tr>
							<td className="" >AADHAR</td>
							<td className="bold" >{this.getValue('aadharno')}</td>
						</tr>
						<tr>
							<td className="" >COURSE</td>
							<td className="bold" >{this.props.course}</td>
						</tr>
						<tr>
							<td>BATCH</td>
							<td className="bold" >{this.props.batch}</td>
						</tr>
						<tr>
							<td className="" >STUDENT NAME</td>
							<td colSpan="3" className="bold" >{upperCase(this.getValue('name'))}</td>
						</tr>
						<tr>
							<td className="" >DOB</td>
							<td className="bold">{this.getValue('dob')}</td>
							<td>GENDER</td>
							<td className="bold" >{upperCase(this.getValue('gender'))}</td>
						</tr>
						<tr>
							<td className="" >RELIGION</td>
							<td className="bold">{capitalizeFirst(this.getValue('religion'))}</td>
							<td>NATIONALITY</td>
							<td className="bold">{capitalizeFirst(this.getValue('nationality'))}</td>
						</tr>
						<tr>
							<td>COMMUNITY</td>
							<td className="bold" >{upperCase(this.getValue('community'))}</td>
							<td className="" >CASTE</td>
							<td className="bold">{upperCase(this.getValue('caste'))}</td>							
						</tr>
						<tr>
							<td className="" >MOBILE</td>
							<td className="bold" >{this.getValue('mobile')}</td>
							<td>EMAIL</td>
							<td className="bold" >{this.getValue('email')}</td>
						</tr>
						<tr>
							<td className="" >FATHER NAME</td>
							<td className="bold">{upperCase(this.getValue('fathername'))}</td>
							<td>MOBILE</td>
							<td className="bold">{this.getValue('fatherphone')}</td>
						</tr>
						<tr>
							<td className="" >FATHER OCCUPATION</td>
							<td className="bold">{capitalizeFirst(this.getValue('fatheroccupation'))}</td>
							<td>INCOME</td>
							<td className="bold">{this.getValue('fatherincome')}</td>
						</tr>
						<tr>
							<td className="" >MOTHER NAME</td>
							<td className="bold">{upperCase(this.getValue('mothername'))}</td>
							<td>MOBILE</td>
							<td className="bold">{this.getValue('motherphone')}</td>
						</tr>
						<tr>
							<td className="" >MOTHER OCCUPATION</td>
							<td className="bold">{capitalizeFirst(this.getValue('motheroccupation'))}</td>
							<td>INCOME</td>
							<td className="bold">{this.getValue('motherincome')}</td>
						</tr>
						<tr>
							<td className="" >PHYSICALLY CHALLENGED</td>
							<td className="bold">{yesorNo(this.getValue('is_physicall_challenged'))}</td>
							<td>SCHOLARSHIP HOLDER</td>
							<td className="bold">{yesorNo(this.getValue('is_scholarship_holder'))}</td>
						</tr>
						<tr>
							<td className="" >DAYS SCHOLLAR</td>
							<td className="bold">{yesorNo(this.getValue('is_days_schollar'))}</td>
							<td>HOSTELLER</td>
							<td className="bold">{yesorNo(this.getValue('is_hosteller'))}</td>
						</tr>
						<tr>
							<td colSpan="2" className="bold" >PRESENT ADDRESS</td>
							<td colSpan="2" className="bold" >PERMANENT ADDRESS</td>
						</tr>
						{isNullOrEmpty(this.getValue('street_persent')) || isNullOrEmpty(this.getValue('street_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('street_persent'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('street_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('place_present')) || isNullOrEmpty(this.getValue('place_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('place_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('place_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('village_present')) || isNullOrEmpty(this.getValue('village_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('village_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('village_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('taluk_present')) || isNullOrEmpty(this.getValue('taluk_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('taluk_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('taluk_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('city_present')) || isNullOrEmpty(this.getValue('city_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('city_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('city_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('district_present')) || isNullOrEmpty(this.getValue('district_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('district_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('district_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('state_present')) || isNullOrEmpty(this.getValue('state_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('state_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('state_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('country_present')) || isNullOrEmpty(this.getValue('country_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('country_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('country_permanent'))}</td>
						</tr>
						: null}
						
						{isNullOrEmpty(this.getValue('pincode_present')) || isNullOrEmpty(this.getValue('pincode_permanent')) ? 
						<tr>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('pincode_present'))}</td>
							<td colSpan="2" className="" >{capitalizeFirst(this.getValue('pincode_permanent'))}</td>
						</tr>
						: null}
					</tbody>
				</Table>
			</Col>
		</Row>
	  );
	}

}

export default View;
