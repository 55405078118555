import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import {AutoComplete} from 'primereact/autocomplete';

class EditGroupTitle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Update',
			titles: [],
			titleSuggest: [],
			selectedTitle: [],
			selectedtitleId: '',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onSuggestTitleSelect = this.onSuggestTitleSelect.bind(this);
	}
	
	componentDidMount(){
		this.loadTitles();
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(Object.keys(this.state.selectedTitle).length<1){
				notify.show('Please select title','warning');
				return;
			}
			this.setState({showLoader: true, btnSave:'Updating..'});
			API.post('v1/naac/ssr/grouptitle/update', $("#frm_ssr_update_GroupTitle").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	loadTitles(){
		try{
			this.setState({showLoader: true, titles: [], titleSuggest: []});
			API.get('v1/naac/ssr/titles').then(res=>{
				if(res['data'].status=='1'){
					var $props = this.props;
					var d = res['data'].data.filter(function(obj){
						return(obj.id==$props.editData.titleid);
					});
					this.setState({titles: res['data'].data, titleSuggest: res['data'].data, selectedTitle: d[0], showLoader: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	makeTitleSuggestion(e){
		try{
			var v = upperCase(e.query);
			var d = this.state.titles.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({ titleSuggest: d });
		}
		catch(error){ }
	}
	
	onSuggestTitleSelect(e){
		try{
			this.setState({selectedTitle: e.value});
		}
		catch(error){ }
	}
	
	getField(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		
	  return (
	  <div>
			<form action="" method="post" encType="multipart/form-data" id="frm_ssr_update_GroupTitle" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__titleId" value={this.state.selectedTitle.id} />
				<input type="hidden" name="i__titleCode" value={this.state.selectedTitle.code} />
				
				<Row  >
					<Col md={12} >
						<Label>Title <span className="required">*</span> </Label>
						<AutoComplete 
							placeholder="Title" 
							field="title" 
							inputClassName="form-control form-control-sm" 
							value={this.state.selectedTitle}
							onChange={(e)=>{this.setState({selectedTitle: e.value})}} 
							suggestions={this.state.titleSuggest} 
							completeMethod={this.makeTitleSuggestion.bind(this)}
							onSelect={this.onSuggestTitleSelect}
							style={{width: '100%'}} 
						/>
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<Label>Code <span className="required">*</span> </Label>
						<Input type="text" size="sm" name="i__code" required defaultValue={this.getField('groupcode')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<Label>Title <span className="required">*</span> </Label>
						<Input type="text" size="sm" name="i__groupTitle" required defaultValue={this.getField('grouptitle')} />
					</Col>
				</Row>
				<Row className="mt-10" >
					<Col md={12} >
						<div className="text-right" >
							<Button type="reset" size="sm" color="danger" disabled={this.state.btnSave!='Update'} >
								Cancel
							</Button>
							&nbsp;
							<Button type="submit" size="sm" color="success" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave!='Update' ? <span className="ion-loading-a"></span> : null}
								{this.state.btnSave}
							</Button>
						</div>
					</Col>
				</Row>
			</form>			
		</div>
	  );
	}

}

export default EditGroupTitle;
