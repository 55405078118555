import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader, Switch } from '../../../vibe/';
import {AppName, Version, ClientName} from '../../../variables';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getls} from '../../../config';
import Select from 'react-select';

import Menu from './menu';

const styles = {
	ftCss: {
		borderTop: '1px solid rgba(34,36,38,.15)',
		padding: '10px 0px 0px 0px',
	}
}

class EditMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
			showLoader: false,
			linkList: [],
			selectedPage: [],
			defaultType: 'page',
		}
		this.handleUrlChange = this.handleUrlChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadAllMenus();
	}
	
	componentWillMount(){
		this.state.defaultType =  this.props.editData.linktype;
	}
	
	loadAllMenus(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/post/links/page').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({linkList: res['data'].data, showLoader: false});
					var id = this.getField('postid');
					
					if(id=='0')
					{
						var d = {pageurl: '', id: '', pagetitle: ''};
						this.setState({selectedPage: d});
					}
					else
					{
						var d = this.state.linkList.filter(function(obj){
							return (obj.id==id);
						});
						this.setState({selectedPage: d[0]});
					}
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleUrlChange(e){
		try{
			
		}
		catch(error){
			
		}
	}
	
	handleSelectChange(sel){
		try{
			
			var id = sel.value;
			var d = this.state.linkList.filter(function(obj){
				return (obj.id==id);
			});
			
			this.setState({selectedPage: d[0]});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/menus/update', $("#frm_addMenu").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSave: 'Update'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Update'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Update'});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	getDefaultSelected(){
		try{
			
			var id = this.getField('postid');
			
			var d = this.state.linkList.filter(function(obj){
				return (obj.id==id);
			});
			//this.setState({selectedPage: d[0]});
			return {label: d[0]['pagetitle'], value: d[0]['id']};
		}
		catch(error){
			
		}
	}
	
	render(){
		
		return(
			<div>
				<form action="" method="post" encType="multipart/form-data" id="frm_addMenu" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__id" value={this.getField('id')} />
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__pageId" value={this.state.selectedPage.id} />
					<input type="hidden" name="i__pageUrl" value={this.state.selectedPage.pageurl} />
					
					<Row>
						<Col md={12} >
							<Label>Title <span className="required">*</span></Label>
							<Input type="text" name="i__title" size="sm" required placeholder="Menu Title" defaultValue={this.getField('title')} />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<div className="pretty p-default p-round">
								<input type="radio" name="i__pagetype" defaultChecked={this.state.defaultType=='page'} onChange={()=>{this.setState({defaultType: 'page'})}}  value="page" />
								<div className="state">
									<label>From Page</label>
								</div>
							</div>

							<div className="pretty p-default p-round">
								<input type="radio" name="i__pagetype" defaultChecked={this.state.defaultType=='external'} onChange={()=>{this.setState({defaultType: 'external'})}} value="external" />
								<div className="state">
									<label>External Link</label>
								</div>
							</div>
						</Col>
					</Row>
					
					{this.state.defaultType=='page' ? 
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Page <span className="required">*</span></Label>
							<Select 
								onChange={this.handleSelectChange} 
								options={ this.state.linkList.map((item,i)=>{
										return {value:item.id, label:item.pagetitle};
									})
								}
								value={this.getDefaultSelected()}
							/>
						</Col>
					</Row>
						:
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Page Link <span className="required">*</span></Label>
							<Input type="url" size="sm" name="i__externalUrl" defaultValue={this.getField('menulink')}  />
						</Col>
					</Row>
					}
				
					
					<div style={styles.ftCss} className="mt-20" >
						<div className="float-left" >
							<div className="pretty p-default">
								<input type="checkbox" name="i__active" defaultChecked={this.getField('activestatus')=='1'} />
								<div className="state p-success">
									<label>Active</label>
								</div>
							</div>
						</div>
						<div className="float-right" >
							<Button type="submit" color="success" size="sm" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave=='Update' ? 
								<span className="ion-checkmark"></span> : 
								<span className="ion-loading-a"></span>}
								&nbsp; {this.state.btnSave}
							</Button>
							&nbsp;
							<Button type="reset" color="danger" size="sm" onClick={this.props.atClose} >
								Cancel 
							</Button>
							
						</div>
					</div>
				
				</form>
			</div>
		);
	}

}

export default EditMenu;
