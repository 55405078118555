import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';

const styles = {
	parentDiv: {
		    position: 'absolute',
			width: '97%',
			height: '450px',
			background: 'rgba(4, 0, 0, 0.36)',
			zIndex: '999',
			color: '#fff',
			fontSize: '50px',
	},
	loaderDiv: {
		width: 'auto',
		position: 'absolute',
		top: '30%',
		left: '47%',
	}
}
class FileBrowserLoader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
		}
		
	}
	
	
	
	render(){
		
		if(this.props.loading)
		{
		  return (
			<div style={styles.parentDiv}  >
				<div style={styles.loaderDiv} >
					<span className="ion-loading-a"></span>
				</div>
			</div>
		  );
		}
		else
		{
			return(<div></div>);
		}
	}

}

export default FileBrowserLoader;
