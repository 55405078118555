import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { BlockLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import Select from 'react-select';


class AddUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save',
			deptList: [],
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.loadDepartments = this.loadDepartments.bind(this);
	}
	
	componentDidMount(){
		if(this.props.department)
			this.loadDepartments();
	}

	loadDepartments(){
		try{
			this.setState({showLoader: true});
			API.get('v1/course/departments/all').then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({showLoader: false, deptList: res['data'].data});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
		
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/user/save', $("#frmAddUser").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnText: 'Save'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Save'});
				}
			});
		}
		catch(error){ 
			this.setState({showLoader: false, btnText: 'Save'});
		}
	}

	render() {

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />
				<BlockLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frmAddUser" autocomplete="off" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__role" value={this.props.role} />
					<input type="hidden" name="i__company" value={getls('company','id')} />
					
					{this.props.department ? <Row className="mt-10" >
						<Col md={12} >
							<Label>Department <span className="text-danger">*</span></Label>
							<Input type="select" size="sm" name="i__department" required  >
								<option value=""> -Select- </option>
								{this.state.deptList.map((item,i)=>{
									return <option value={item.id} > {item.departmentname} </option>;
								})
								}
							</Input>
						</Col>
					</Row> : null}
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Username <span className="text-danger">*</span></Label>
							<Input type="text" size="sm" name="i__userName" required  />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Name <span className="text-danger">*</span></Label>
							<Input type="text" size="sm" required name="i__name" autoComplete="off"  />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Mobile</Label>
							<Input type="text" size="sm" name="i__mobile"  />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Email</Label>
							<Input type="email" size="sm" name="i__email" />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="reset" size="sm" color="danger" >
									<i className="ion-close" /> Reset
								</Button>
								&nbsp;
								<Button type="submit" size="sm" color="success" disabled={this.state.btnText!='Save'} >
									{this.state.btnText=='Save' ?  <i className="ion-checkmark" />  : <i className="ion-loading-a" /> } 
									&nbsp; {this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
				
				</form>

			</div>
		);
	}

}

export default AddUser;
