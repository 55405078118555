import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, Collapse, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../../variables';
import {LineLoader, PageLoader} from '../../../../elements';
import API from '../../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls} from '../../../../config';
import CKEditor from 'ckeditor4-react';
import ReactFileReader from 'react-file-reader';

import ModalFileBrowser from '../../filebrowser/ModalFileBrowser';

class TeachingPlan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSubmit: 'Update Page',
			visionData: '',
			data: [],
			activeToggleId: '',
			selectedBatch: [],
			addFrmBtn: 'Upload Content',
			dataList: [],
			selectedFilename: '',
			selectedUrl : '',
			selectedFile: '',
			pagecategory: 'teachingplan',
		}
		this.onContentEditorChange = this.onContentEditorChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
		this.handleContentFormSubmit = this.handleContentFormSubmit.bind(this);		
		this.handleFiles = this.handleFiles.bind(this);		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);		
	}
	
	
	componentDidMount(){
		
		/*var $this = this;
		setTimeout(function() {
			$this.loadPageContent();	
		}, 100);*/
		
		this.loadFiles();
		this.setState({data: this.props.content, visionData: this.props.content.pagecontent});
		
	}

	componentWillReceiveProps(){
		
		this.setState({data: this.props.content, visionData: this.props.content.pagecontent});
	}
	
	handleFiles(files){
		try{
			
			this.setState({
				selectedFilename: files.fileList[0]['name'],  selectedFile: files.base64, selectedUrl: ''
			});
		}
		catch(error){
			
		}
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader: true, data: []});
			API.get('v1/academics/page/'+this.props.deptId+'/'+this.state.pagecategory).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, data: res['data'].data, visionData: res['data'].data.pagecontent});
				}
				else
				{
					//notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getFilesByBatch(batch){
		try{
			
			var d = this.state.dataList.filter(function(obj){
				return( (obj.academicyear==batch.value && obj.academicyearid==batch.id));
			});
			
			return d;
		}
		catch(error){
			return [];
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			 
			this.setState({ visionData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }
	
	loadFiles(){
		try{
			this.setState({showLoader: true, dataList: []});
			
			var form = new FormData();
			form.append('courseid', this.props.deptId);
			form.append('category', this.state.pagecategory);
			var data = form;
			
			API.post('v1/academics/files',data).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	


	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedUrl.length<1 && this.state.selectedFile.length < 3)
			{
				notify.show('Select Files to Upload','warning');
				return false;
			}
			
			this.setState({showLoader: true, addFrmBtn: 'Uploading..'});
			API.post('v1/academics/files/save',$("#frm_academic_TeachingPlan_Files").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, addFrmBtn: 'Upload Content', showAddSyllabus: false, selectedFilename: '', selectedUrl : '', selectedFile: ''});
					this.loadFiles();
					
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, addFrmBtn: 'Upload Content'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, addFrmBtn: 'Upload Content'});
			console.log(error);
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			if(d[fieldName])
			{
				return d[fieldName];
			}
			else return '';
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	getFilesCount(item){
		try{
			 var b = this.getFilesByBatch(item);
			 return Object.keys(b).length;
		}
		catch(error){
			return '0';
		}
	}
	
	loadFilesListbyBatch(batch){
		try{
			
			 var b = this.getFilesByBatch(batch);
			 
			 var rv = [];
			 var sno = 1;
			 if(Object.keys(b).length>0)
			 {
				 b.map((item,i)=>{
					rv.push(
						<tr key={i} >
							<td>{sno++}</td>
							<td>{item.filetitle}</td>
							<td>{capitalizeFirst(item.semtype)}</td>
							<td align="center" >
								<a href={Api.ImageUploadPath+item.filepath} target="_blank" className="btn btn-info btn-sm" >
									<span className="ion-ios7-eye" ></span>
								</a>
								&nbsp;
								<Button type="button" color="danger" size="sm" onClick={this.handlefileRemove.bind(this,item)} >
									<span className="ion-close-round"></span>
								</Button>
							</td>
						</tr>
					);
				 });					 
			 }
			 
			 return rv;
		}
		catch(error){
			
		}
	}
	
	handlefileRemove(item){
		try{
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to Delete?',
				buttons: {
					Yes: function(){
						$this.confirmFileRemove(item);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	confirmFileRemove(item){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/academics/files/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var index = this.state.dataList.findIndex(function(obj){
						return((obj.academicyear==item.value && obj.academicyearid==item.id));
					});
					var s = this.state.dataList;
					s.splice(index,1);
					this.setState({dataList: s, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleContentFormSubmit(e){
		e.preventDefault();
		try{
			
			if(!this.state.visionData  || !this.state.visionData ==null || this.state.visionData.length<1)
			{
				notify.show('Please enter Page Content','warning');
				return false;
			}
			
			this.setState({showLoader: true, btnSubmit: 'Updating..'});
			API.post('v1/academics/page/update',$("#frm_TeachingPlan_content").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					var d = this.state.data;
					d['id'] = res['data'].pageid;
					this.setState({showLoader: false, btnSubmit: 'Update Page', data: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSubmit: 'Update Page'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnSubmit: 'Update Page'});
			console.log(error);
		}
	}

	
	render(){
		
		
		return(
			<div>
				
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_TeachingPlan_content" onSubmit={this.handleContentFormSubmit} >
				
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__category" value={this.state.pagecategory} />
				<input type="hidden" name="i__deptId" value={this.props.deptId} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				<Row>	
					<Col md={12} >
						<Card>
							<CardBody>
							
								<Row className="mt-10" >
									<Col md={12} >
										<Card>
											<CardBody>
											{this.props.batch.map((item,i)=>{
												return <div>
													<CardTitle className="toggle-title" onClick={()=>{this.setState({activeToggleId: item.id, selectedBatch: item})}} >
													{item.value}	 - ({this.getFilesCount(item)}) Files												
													</CardTitle>
													<Collapse isOpen={this.state.activeToggleId==item.id} >
														<div style={{float: 'right', marginTop: '-47px' }} >
															<Button type="button" size="sm" color="secondary" onClick={()=>{this.setState({showAddSyllabus: true})}} >
																<span className="ion-android-add"></span> Add Content
															</Button>
														</div>
														<Table className="tbl" >
															<thead>
																<tr>
																	<th width="80" >S.No</th>
																	<th>File Name</th>
																	<th width="120" >Sem Type</th>
																	<th width="120" >Manage</th>
																</tr>
															</thead>
															<tbody>
															{this.loadFilesListbyBatch(item)}
															</tbody>
														</Table>
													</Collapse>
												</div>;
											})												
											}
											
											</CardBody>
										</Card>
									</Col>
									<Col md={12} ><hr /></Col>
								</Row>
								
								<Row>
									<Col md={12} >
										<div className="text-left" >
										<div className="pretty p-icon p-smooth">
											{this.getField('showinpage')=='1' ? 
												<input type="checkbox" name="i__about_ShowInPage" defaultChecked={true} /> :
												<input type="checkbox" name="i__about_ShowInPage"  />
											}
											<div className="state p-success">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show In Page</label>
											</div>
										</div>
										</div>
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
								</Row>
									
								<Row className="mt-20" >

									<Col md={12} >
										<Label>Teaching Plan Page Content <span className="required">*</span>
											<div style={{position: 'absolute', top: '0px', left: '255px' }} >
												<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
												>
													<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
														Open File Manager
													</Button>
												</ModalFileBrowser>
											</div>
										</Label>										
											<CKEditor 
											name='editor_teachingplan' 
											config={{ extraPlugins: 'print,font', 
											height: '700', 
											startupShowBorders: 'false', 
											allowedContent: true, 
											extraAllowedContent: '*(*);*{*}' }} 
											data={this.state.visionData} 
											onChange={this.onContentEditorChange} />
									</Col>
									
									<Col md={12} className="mt-15" >
										<div className="text-right" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnSubmit!='Update Page'} >
												{this.state.btnSubmit=='Update Page' ? 
													<span className="ion-android-checkmark" ></span> :
													<span className="ion-loading-a" ></span>} &nbsp;
												{this.state.btnSubmit}
											</Button>
										</div>
									</Col>
									
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				
				<input type="hidden" name="i__about_Pagecontent" value={this.state.visionData} />
				
			</form>
				
				<Modal isOpen={this.state.showAddSyllabus} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({selectedFilename: '', selectedFile: '', selectedUrl: '', showAddSyllabus: false})}} >
						Add Content
					</ModalHeader>
					<ModalBody>
						<form action="" method="post" encType="multipart/form-data"  id="frm_academic_TeachingPlan_Files" onSubmit={this.handleFormSubmit} >						
						
							<input type="hidden" name="i__id" value={this.getField('id')} />
							<input type="hidden" name="i__category" value={this.state.pagecategory} />
							<input type="hidden" name="i__deptId" value={this.props.deptId} />
							<input type="hidden" name="i__batchId" value={this.state.selectedBatch.id} />
							<input type="hidden" name="i__userId" value={getls('user','id')} />
				
							<input type="hidden" name="i__filename" value={this.state.selectedFilename} />
							<input type="hidden" name="i__fileurl" value={this.state.selectedUrl} />
							<input type="hidden" name="i__file" value={this.state.selectedFile} />
							<input type="hidden" name="i__degreeName" value={this.props.courseInfo.coursename} />
							<input type="hidden" name="i__degreeType" value={this.props.courseInfo.type} />
							<input type="hidden" name="i__courseType" value={this.props.courseInfo.coursetype} />
							<input type="hidden" name="i__courseName" value={this.props.courseInfo.name} />
						
							<Row>
								<Col md={6} >
									<Label>Academic Year</Label>
									<Input name="i__acyearName" size="sm" value={this.state.selectedBatch.value} />
								</Col>
								<Col md={6} >
									<Label>Sem Type</Label>
									<Input type="select" name="i__semtype" size="sm" >
										<option value="odd"> Odd </option>
										<option value="even"> Even </option>
									</Input>
								</Col>
								<Col md={12} className="mt-10" >
									<Label>File Title <span className="required">*</span></Label>
									<Input name="i__syllabusTitle" size="sm" placeholder="Title of the File" required defaultValue={this.state.selectedFilename} />
								</Col>
								<Col md={12}  className="mt-10"  >
									<Label>File <span className="required">*</span>
									<ModalFileBrowser isOpen={this.state.showFileManager} 
											atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
											fileBrowserClick={this.handleFileBrowserClick}
										>
										<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
											Open File Manager
										</Button>
									</ModalFileBrowser>
									</Label>
									
									<ReactFileReader fileTypes={[".pdf"]} base64={true} handleFiles={this.handleFiles} style={{width: '100%'}} >
									<Input name="i__syllab" size="sm" placeholder="Click here to Upload new File" readOnly value={this.state.selectedFilename}  />
									</ReactFileReader >
								</Col>
								<Col md={12}  className="mt-10"  >
									<hr />
									<div className="text-center" >
										<Button type="submit" size="sm" color="success" disabled={this.state.addFrmBtn!='Upload Content'} >
											{this.state.addFrmBtn=='Upload Content' ? 
												<span className="ion-arrow-up-a" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.addFrmBtn}
										</Button>
										&nbsp;
										<Button type="reset" color="danger" size="sm" disabled={this.state.addFrmBtn!='Upload Content'} onClick={()=>{this.setState({showAddSyllabus:false})}}>
											<span className="ion-android-close"></span> Close the Form
										</Button>
									</div>
								</Col>
							</Row>
							
						</form>
					</ModalBody>
				</Modal>
				  
			</div>
		);
	}

}

export default TeachingPlan;
