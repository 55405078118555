import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import HostelFeeSettings from './HostelFeeSettings';
import YearWiseFeeSettings from './YearWiseFeeSettings';

class CompanyFeeSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	render(){
		
		var billFormat = getls('currentacbusiness', 'billformat');
		
	  return (
		<div>
			
			{billFormat == 'hostel' ? <HostelFeeSettings /> 
			: billFormat == 'pta' ? <YearWiseFeeSettings /> 
			: <div style={{padding: '20px'}} >
				<h5>Sorry you do not having permission to access this page.!</h5>
			</div>
			}
		
		</div>
	  );
	}

}

export default CompanyFeeSettings;
