import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import API from '../../../config/API';
import {setls, getls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import MenuList from '../menulist';

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

import FeeCollectorHome from './FeeCollectorHome';


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFileBrowser: false,
		}		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
	}
	
	handleFileBrowserClick(files){
		try{
			console.log(files);
			/* var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			var d = this.state.fileList;
			d.push({
				url: Api.ImageUploadPath+ url,
				filepath: url,
				name: files[0].data.name				
			});
			this.setState({fileList: d}); */
			
		}
		catch(error){ }
	}
	

	render(){
		
	var role = getls('user','role');
	
	  return (
		<div>
			
			{role=='feecollector' ? <FeeCollectorHome /> : null}
			
		</div>
	  );
	}

}

export default Home;
