import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import API from '../../../../config/API';
import {AppName, Version, ImgPath} from '../../../../variables';
import {capitalizeFirst, upperCase, lowerCase, yesorNo, isNullOrEmpty} from '../../../../config';
import {Tabs} from '../../../../elements';
import ReactFileReader from 'react-file-reader';
import AVATAR_MALE from '../../../../assets/images/avatar_male.png';
import AVATAR_FEMALE from '../../../../assets/images/avatar_female.png';

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		  btnText: 'Update Profile',
		  newPhoto: '',
		  enablePicUpload: false,
		  picUploadText: 'Upload to server',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handlePhotoChange = this.handlePhotoChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	getValue(field){
		try{
			
			var d = this.props.student;
			return d[field];
		}
		catch(error){
			console.log(error);
			return '';
		}
	}
	
	handlePhotoChange(files){
		try{
			
			this.setState({newPhoto: files.base64, enablePicUpload: true});
		}
		catch(error){
		
		}
	}
	
	handleFormSubmit(formId, e){
		e.preventDefault();
		try{
			if(formId=='frmEdit_Student_Picupload')
			{
				this.setState({showLoader: true, picUploadText: 'Uploading...'});
			}
			else
			{
				this.setState({showLoader: true, btnText: 'Updating...'});
			}
			API.post('students/updateprofile', $("#"+formId).serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].msg,'success');
					this.setState({showLoader: false, btnText: 'Update Profile', picUploadText: 'Upload to server'});
					if(this.props.afterFinish)
						this.props.afterFinish();
						
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({showLoader: false, btnText: 'Update Profile', picUploadText: 'Upload to server'});
				}
			});			
		}
		catch(error){
			this.setState({showLoader:false, btnText: 'Update Profile', picUploadText: 'Upload to server'});
		}
	}
	
	render(){
		
	let sno = 1;
		
	let PHOTO = this.props.student['gender'].toLowerCase()=='male' ? AVATAR_MALE : AVATAR_FEMALE;

	var userImage = this.getValue('profile_photo');
	if(userImage!='' && userImage!=null && userImage.length>4)
		PHOTO = ImgPath.StudentProfilePhoto + userImage;
	
	if(this.state.newPhoto.length>5)
		PHOTO = this.state.newPhoto;
	
	  return (
		<div>
			
			<Row>
				<Col md={9} >
					<Tabs>
						
						<div label="Personal Info" >
							<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Personal" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Personal')} >
							
								<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
								<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
								<input type="hidden" name="i__formtype" value="personal" />
								
								<Row>
									<Col md={6} >
										
										<Row>
											<Col md={4} >
												<Label>Enrolment No.</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" readOnly value={this.getValue('temp_registerno')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Course</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" readOnly value={this.props.course} />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Smart ID</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__smartId" defaultValue={this.getValue('smartid')}  />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Student Name <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__name" defaultValue={upperCase(this.getValue('name'))} required style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Gender <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__gender" required size="sm" className="form-control form-control-sm"  >
													<option value="male" selected={lowerCase(this.getValue('gender'))=='male' ? 'selected' : ''} > Male </option>
													<option value="female" selected={lowerCase(this.getValue('gender'))=='female' ? 'selected' : ''}  > Female </option>
												</select>
											</Col>
										</Row>										
										<Row >
											<Col md={4} >
												<Label>Religion <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__religion" required size="sm" className="form-control form-control-sm" >
													<option value="hindu" selected={lowerCase(this.getValue('religion'))=='hindu' ? 'selected' : ''} > Hindu </option>
													<option value="christian" selected={lowerCase(this.getValue('religion'))=='christian' ? 'selected' : ''}  > Christian </option>
													<option value="muslim" selected={lowerCase(this.getValue('religion'))=='muslim' ? 'selected' : ''} > Muslim </option>
												</select>
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Community <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__community" required size="sm" className="form-control form-control-sm" >
													<option value="oc" selected={lowerCase(this.getValue('community'))=='oc' ? 'selected' : ''} > OC </option>
													<option value="bc" selected={lowerCase(this.getValue('community'))=='bc' ? 'selected' : ''} > BC </option>
													<option value="bcm" selected={lowerCase(this.getValue('community'))=='bcm' ? 'selected' : ''} > BCM </option>
													<option value="mbc" selected={lowerCase(this.getValue('community'))=='mbc' ? 'selected' : ''} > MBC </option>
													<option value="sc" selected={lowerCase(this.getValue('community'))=='sc' ? 'selected' : ''} > SC </option>
													<option value="sca" selected={lowerCase(this.getValue('community'))=='sca' ? 'selected' : ''} > SCA </option>
													<option value="st" selected={lowerCase(this.getValue('community'))=='st' ? 'selected' : ''} > ST </option>
													<option value="dnc" selected={lowerCase(this.getValue('community'))=='dnc' ? 'selected' : ''} > DNC </option>
												</select>
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Mobile <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<Input type="number" size="sm" name="i__mobile" defaultValue={(this.getValue('mobile'))} required />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Marital Status <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__maritalstatus" required size="sm" className="form-control form-control-sm" >
													<option value="single" selected={lowerCase(this.getValue('maritalstatus'))=='single' ? 'selected' : ''} > Single </option>
													<option value="married" selected={lowerCase(this.getValue('maritalstatus'))=='married' ? 'selected' : ''} > Married </option>
												</select>
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Residential <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__residential" required size="sm" className="form-control form-control-sm" >
													<option value="dayscholar" selected={lowerCase(this.getValue('residentialtype'))=='dayscholar' ? 'selected' : ''} > Dayscholar </option>
													<option value="hosteller" selected={lowerCase(this.getValue('residentialtype'))=='hosteller' ? 'selected' : ''} > Hosteller </option>
												</select>
											</Col>
										</Row>
									</Col>
									<Col md={6} >
										
										<Row>
											<Col md={4} >
												<Label>Register Number</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" readOnly value={this.getValue('registerno')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Batch</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" readOnly value={this.props.batch} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Aadhar</Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__aadhar" defaultValue={this.getValue('aadharno')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>DOB <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<Input type="date" size="sm" name="i__dob" required defaultValue={this.getValue('dob')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Quota <span className="required"></span></Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__quota" value={this.getValue('quota')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Denomination <span className="required"></span></Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__denomination" />
											</Col>
										</Row>
										<Row>
											<Col md={4} >
												<Label>Caste <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__caste" defaultValue={this.getValue('caste')} required style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Email <span className="required"></span></Label>
											</Col>
											<Col md={8} >
												<Input type="email" size="sm" name="i__email" defaultValue={(this.getValue('email'))}  />
											</Col>
										</Row>
										<Row >
											<Col md={4} >
												<Label>Nationality <span className="required"></span></Label>
											</Col>
											<Col md={8} >
												<Input type="text" size="sm" name="i__nationality" defaultValue={(this.getValue('nationality'))}  />
											</Col>
										</Row>
										{/*<Row >
											<Col md={4} >
												<Label>Blood Group <span className="required">*</span></Label>
											</Col>
											<Col md={8} >
												<select type="select" name="i__bloodgroup" required size="sm" className="form-control form-control-sm" >
													<option value="dayscholar" selected={lowerCase(this.getValue('residentialtype'))=='dayscholar' ? 'selected' : ''} > Dayscholar </option>
													
													<option value="A+" selected={lowerCase(this.getValue('residentialtype'))=='dayscholar' ? 'selected' : ''} >A+</option>
													<option value="B+">B+</option>
													<option value="A-">A-</option>
													<option value="B-">B-</option>
													<option value="B1+">B1+</option>
													<option value="O+">O+</option>
													<option value="O-">O-</option>
													<option value="AB+">AB+</option>
													<option value="AB-">AB-</option>
													<option value="A1+">A1+</option>
													<option value="A1-">A1-</option>
													<option value="A2+">A2+</option>
													<option value="A2-">A2-</option>
													<option value="A1B+">A1B+</option>
													<option value="A1B-">A1B-</option>
													<option value="A2B+">A2B+</option>
													<option value="A2B-">A2B-</option>
												</select>
											</Col>
										</Row>*/}
										
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={2} >
										<Label>Identification 1</Label>
									</Col>
									<Col md={10} >
										<Input type="text" name="i__identify1" size="sm" defaultValue={this.getValue('identify1')} />
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={2} >
										<Label>Identification 2</Label>
									</Col>
									<Col md={10} >
										<Input type="text" name="i__identify2" size="sm" defaultValue={this.getValue('identify2')} />
									</Col>
								</Row>
								<Row>
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Update Profile'} >
												<span className="ion-checkmark"></span> {this.state.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</form>
						</div>
						
						<div label="Family Info" >
							<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Family" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Family')} >
							
								<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
								<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
								<input type="hidden" name="i__formtype" value="family" />
								
								<Row>
									<Col md={6} >
										<Row>
											<Col md={12} >
												<b>Father's Details :</b>
												<hr />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Father Name <span className="required">*</span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__fathername" size="sm" required defaultValue={upperCase(this.getValue('fathername'))} style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Mobile <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__fathermobile" size="sm"  defaultValue={this.getValue('fatherphone')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Occupation <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__fatheroccupation" size="sm"  defaultValue={this.getValue('fatheroccupation')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Income <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="number" name="i__fatherincode" size="sm"  defaultValue={this.getValue('fatherincome')} />
											</Col>
										</Row>
										
									</Col>
									<Col md={6} >
										
										<Row>
											<Col md={12} >
												<b>Mother's Details :</b>
												<hr />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Mother Name <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__mothername" size="sm" defaultValue={upperCase(this.getValue('mothername'))} style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Mobile <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__mothermobile" size="sm"  defaultValue={this.getValue('motherphone')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Occupation <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="text" name="i__motheroccupation" size="sm"  defaultValue={this.getValue('motheroccupation')} />
											</Col>
										</Row>
										<Row>
											<Col md={4} ><Label>Income <span className="required"></span></Label> </Col>
											<Col md={8} >
												<Input type="number" name="i__motherincome" size="sm"  defaultValue={this.getValue('motherincome')} />
											</Col>
										</Row>
										
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={2} ><Label>Annual Income</Label></Col>
									<Col md={10} >
										<Input type="number" name="i__annualincome" size="sm"  defaultValue={this.getValue('annualincome')} />
									</Col>
								</Row>
								<Row>
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Update Profile'} >
												<span className="ion-checkmark"></span> {this.state.btnText}
											</Button>
										</div>
									</Col>
								</Row>
								
							</form>
						</div>
						
						<div label="Contact Info" >
							<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Address" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Address')} >
							
								<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
								<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
								<input type="hidden" name="i__formtype" value="address" />
								
								<Row>
									<Col md={6} >
										
										<Row>
											<Col md={12} >
												<b>Present Address :</b>
												<hr />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Street</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_street" defaultValue={this.getValue('street_persent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Place</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_place" defaultValue={this.getValue('place_present')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Taluk</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_taluk" defaultValue={this.getValue('taluk_present')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>City/District</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_city" defaultValue={this.getValue('city_present')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>State</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_state" defaultValue={this.getValue('state_present')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Country</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__present_country" defaultValue={this.getValue('country_present')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Pincode</Label></Col>
											<Col md={9} >
												<Input type="number" size="sm" name="i__present_pincode" defaultValue={this.getValue('pincode_present')} />
											</Col>
										</Row>
										
									</Col>
									<Col md={6} >
										
										<Row>
											<Col md={12} >
												<b>Permanent  Address :</b>
												<hr />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Street</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_street" defaultValue={this.getValue('street_permanent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Place</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_place" defaultValue={this.getValue('place_permanent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Taluk</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_taluk" defaultValue={this.getValue('taluk_permanent	')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>City/District</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_city" defaultValue={this.getValue('city_permanent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>State</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_state" defaultValue={this.getValue('state_permanent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Country</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__permenant_country" defaultValue={this.getValue('country_permanent')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Pincode</Label></Col>
											<Col md={9} >
												<Input type="number" size="sm" name="i__permenant_pincode" defaultValue={this.getValue('pincode_permanent')} />
											</Col>
										</Row>
										
									</Col>
									
								</Row>		

								<Row>
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Update Profile'} >
												<span className="ion-checkmark"></span> {this.state.btnText}
											</Button>
										</div>
									</Col>
								</Row>								
																
							</form>
						</div>
						
						<div label="Guardian Info" >
							<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Guardian" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Guardian')} >
							
								<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
								<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
								<input type="hidden" name="i__formtype" value="guardian" />
								
									<Row>									
									<Col md={6} >
										
										<Row>
											<Col md={3} ><Label>Name <span className="required">*</span></Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardianname" defaultValue={this.getValue('guardian_name')} required />
											</Col>
										</Row>										
										<Row>
											<Col md={3} ><Label>Mobile</Label></Col>
											<Col md={9} >
												<Input type="number" size="sm" name="i__guardianmobile" defaultValue={this.getValue('guardian_mobile')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Email</Label></Col>
											<Col md={9} >
												<Input type="email" size="sm" name="i__guardianmail" defaultValue={this.getValue('guardian_email')} />
											</Col>
										</Row>
										
									</Col>
									<Col md={6} >
										
										<Row>
											<Col md={3} ><Label>Relationship</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_relation" defaultValue={this.getValue('guardian_relation')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Occupation</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_occupation" defaultValue={this.getValue('guardian_occupation')} />
											</Col>
										</Row>
										
									</Col>									
								</Row>
								
								<Row className="mt-10" >
									<Col md={12} >
										<b>Guardian  Address :</b>
										<hr />
									</Col>
								</Row>
								
								<Row>
									<Col md={6} >
										<Row>
											<Col md={3} ><Label>Street</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_street" defaultValue={this.getValue('guardian_street')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Place</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_place" defaultValue={this.getValue('guardian_place')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Taluk</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_taluk" defaultValue={this.getValue('guardian_taluk	')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>City/District</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_city" defaultValue={this.getValue('guardian_city')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>State</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_state" defaultValue={this.getValue('guardian_state')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Country</Label></Col>
											<Col md={9} >
												<Input type="text" size="sm" name="i__guardian_country" defaultValue={this.getValue('guardian_country')} />
											</Col>
										</Row>
										<Row>
											<Col md={3} ><Label>Pincode</Label></Col>
											<Col md={9} >
												<Input type="number" size="sm" name="i__guardian_pincode" defaultValue={this.getValue('guardian_pincode')} />
											</Col>
										</Row>
										
									</Col>
								</Row>
								
								<Row>
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Update Profile'} >
												<span className="ion-checkmark"></span> {this.state.btnText}
											</Button>
										</div>
									</Col>
								</Row>
								
							</form>
						</div>
						
						
						<div label="Other Info" >
							<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Other" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Other')} >
							
								<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
								<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
								<input type="hidden" name="i__formtype" value="other" />
								
								<Row>
									<Col md={6} >
									
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__physicallychallenged" name="i__physicallychallenged" defaultChecked={this.getValue('is_physicall_challenged')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__physicallychallenged" >Physically Challenged</label>
											</Col>
										</Row>
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__scholorshipholder" name="i__scholorshipholder"  defaultChecked={this.getValue('is_scholarship_holder')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__scholorshipholder" >Scholarship Holder</label>
											</Col>
										</Row>
										
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__inncc" name="i__inncc"  defaultChecked={this.getValue('in_ncc')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__inncc" >NCC</label>
											</Col>
										</Row>
										
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__innss" name="i__innss"  defaultChecked={this.getValue('in_nss')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__innss" >NSS</label>
											</Col>
										</Row>
										
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__exservice" name="i__exservice"  defaultChecked={this.getValue('in_exservice_man')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__exservice" >Ex-Service Man</label>
											</Col>
										</Row>
										
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__srilankan" name="i__srilankan"  defaultChecked={this.getValue('in_srilankan')=='1'} />
												</div>
											</Col>
											<Col md={11} >
												<label for="i__srilankan" >Srilankan</label>
											</Col>
										</Row>
										
										<Row>
											<Col md={1} >
												<div className="text-center" >
													<input type="checkbox" className="regular-checkbox" id="i__sports" name="i__sports"  defaultChecked={this.getValue('in_sports')=='1'} />
												</div>
											</Col>
											<Col md={3} >
												<label for="i__sports" >Sports</label>
											</Col>
											<Col md={8} >
												<Input type="text" name="i__sportsname" size="sm" placeholder="Sports Name" />
											</Col>
										</Row>
									
									</Col>
									<Col md={6} >
									
									</Col>
								</Row>
								
								<Row>
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" color="success" size="sm" disabled={this.state.btnText!='Update Profile'} >
												<span className="ion-checkmark"></span> {this.state.btnText}
											</Button>
										</div>
									</Col>
								</Row>
								
							</form>
						</div>
						
					</Tabs>
				</Col>
				<Col md={3} >
					<div className="text-center" >
					<Row>
						<Col md={12} >
							<img src={PHOTO} style={{width:'180px', border: '1px solid #e4e4e4'}} />
						</Col>
						<Col md={12} className="mt-10" >
							{this.state.enablePicUpload ? 
								<div>
									<form action="" method="post" encType="multipart/form-data" id="frmEdit_Student_Picupload" onSubmit={this.handleFormSubmit.bind(this,'frmEdit_Student_Picupload')} >
							
										<input type="hidden" name="i__regno"  value={this.getValue('registerno')} />
										<input type="hidden" name="i__admno"  value={this.getValue('admissionno')} />
										<input type="hidden" name="i__formtype" value="picupload" />
										
										<input type="hidden" name="i__pic" value={this.state.newPhoto} />
										
										<Button type="submit" color="success" size="sm" disabled={this.state.picUploadText!='Upload to server'} >
											{this.state.picUploadText} 
										</Button>
								
								</form>
								</div>
								:
							<ReactFileReader handleFiles={this.handlePhotoChange} base64={true} fileTypes={[".png",".jpg",".jpeg"]} >
							  <Button size="sm"  color="info" ><span className="ion-upload"></span> Chagne Picture</Button>
							</ReactFileReader>
							}
						</Col>
					</Row>
					</div>
				</Col>	
			</Row>
		
	
		
		</div>
	  );
	}

}

export default Edit;
