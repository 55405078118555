import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName} from '../../../variables';
import {LineLoader} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import Menu from './menu';
import AddMenu from './AddMenu';
import EditMenu from './EditMenu';

class MenuList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddMenu: false,
			showEditMenu: false,
			dataList: [],
			dataView: [],
			editData: [],			
		}
		
		this.handleMenuSearch = this.handleMenuSearch.bind(this);
		this.loadAllMenus = this.loadAllMenus.bind(this);
	}
	
	componentDidMount(){
		
		this.loadAllMenus();
	}
	
	loadAllMenus(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/menus').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleMenuSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Menu?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.delete('v1/menus/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item){
		this.setState({editData: item, showEditMenu: true});
	}
	
	render(){
		
		let sno = 1;
		
		return(
			<div>
			
				<LineLoader loading={this.state.showLoader} />
				
				<Menu />
				
				<div className="pt-50" >
				
					<Card>
						<CardBody className="pb-0 pt-10" >
							<Row>
								<Col md={9} >
									<div className="input-group">
										<Input type="text" size="sm" placeholder="Search by Title.." onChange={this.handleMenuSearch} />
										<span className="input-group-addon" >
											<span className="ion-android-search"></span>
										</span>
									</div>
								</Col>
								<Col md={3} >
									<div className="text-right" >
										<Button type="button" size="sm" color="info" onClick={()=>{this.setState({showAddMenu: true})}} >
											<span className="ion-android-add"></span> Add Menu
										</Button>
										&nbsp;
										<Button color="warning" size="sm" title="Refresh" onClick={this.loadAllMenus} >
											<span className="ion-ios7-reload"></span>
										</Button>
									</div>
								</Col>
							</Row>
							<hr className="mt-10" />
							<Row>
								<Col md={12} >
									<Table className="tbl" >
										<thead>
											<tr>
												<th width="80" >S.No</th>
												<th>Menu Title</th>
												<th>URL</th>
												<th width="120" >Manage</th>
											</tr>
										</thead>
										{this.state.showLoader ? 
											<tbody><tr><td colSpan="4" > <Loader type="dot" /></td></tr></tbody> : 
											<tbody>
												{this.state.dataView.map((item,i)=>{
													return <tr key={i} className={item.activestatus=='0' ? 'bg-danger-light' : ''} >
														<td>{sno++}</td>
														<td>{item.title}</td>
														<td>
														{item.menulink}
														</td>
														{item.editable!='1' ? <td></td> :
														<td align="center" >
															<Button type="button" size="sm" color="info" onClick={this.handleEdit.bind(this,item)}  >
																<span className="ion-edit"></span>
															</Button>
															&nbsp;
															<Button type="button" size="sm" color="danger" onClick={this.handleRemove.bind(this,item,i)}  >
																<span className="ion-close"></span>
															</Button>
														</td>
														}
													</tr>;
												})													
												}
											</tbody>
										}
									</Table>
								</Col>
							</Row>
							
						</CardBody>
					</Card>
					 
				  </div>
				  
				  <Modal isOpen={this.state.showAddMenu} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showAddMenu: false})}} >
						Add Menu
					</ModalHeader>
					<ModalBody>
						
						<AddMenu atClose={()=>{this.setState({showAddMenu: false})}} atFinish={this.loadAllMenus} />
						
					</ModalBody>
				</Modal>
				
				<Modal isOpen={this.state.showEditMenu} size="" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showEditMenu: false})}} >
						Update Menu
					</ModalHeader>
					<ModalBody>
						
						<EditMenu atClose={()=>{this.setState({showEditMenu: false})}} editData={this.state.editData} atFinish={this.loadAllMenus} />
						
					</ModalBody>
				</Modal>
			</div>
		);
	}

}

export default MenuList;
