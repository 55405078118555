import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import LoginLogo from '../../assets/images/user_200.png';
import {AppName, Version, ClientName, ClientPlace} from '../../variables';
import API from '../../config/API';
import {setls, getCookie, getls} from '../../config';

const styles = {
	nameCss: {
		paddingRight: '10px',
		paddingTop: '4px',
		textAlign: 'right',
	},
	placeCss: {
		lineHeight: '0px',
		color: 'rgb(6, 98, 111)',
	}
}

class AccountingBusiness extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  loginText: 'Login',
		  isRedirect: false,
		}
		
	}
	
	componentDidMount(){
		
		
	}
	

	render(){
	
	const ROLE = getls('user','role');
	
	  return (
			<div  style={styles.nameCss} >
				{ROLE=='accountant' || ROLE=='feecollector' ? <div>
					<h5 style={{color: '#ea0202',fontWeight: '600'}}  >{getls('currentacbusiness','businessname')}</h5>
					<div style={styles.placeCss} >{getls('currentacyear', 'value')}</div>
				</div> : null}
			</div>
	  );
	}

}

export default AccountingBusiness;
