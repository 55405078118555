import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { notify } from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import { AppName, Version, Api } from '../../../variables';
import API from '../../../config/API';
import { groupByMultiple, capitalizeFirst, upperCase, momentDate, getlsItem, getls, getAscSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';

import HostelA5BillPrint from './HostelA5BillPrint';
import PTAA5BillPrint from './PTAA5BillPrint';

class HostelPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Save Payment',
			showAddModal: false,
			showEditModal: false,
			typeLoader: false,
			showPrint: false,
			selectedYear: 'Others',
			printData: [],
			categoryList: [],
			dataList: [],
			dataView: [],
			editData: [],
			selectedCategoryId: '',
			selectedAmount: '',
			currentPage: 1,
			todosPerPage: 20,
			activePage: 1,
			today: '',
			paymentDate: '',
			maxDate: '',
			vId: '',
			vNo: '',
			studId: '',
			selectedStudent: [],
			paidCategory: [],
			assignedFee: [],
			voucherId: '',
			feeType: '',
			netAmount: '0',
		}
		this.handleStudentSearch = this.handleStudentSearch.bind(this);
		this.loadCategory = this.loadCategory.bind(this);
		this.loadAssignedFees = this.loadAssignedFees.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleFeeTypeChange = this.handleFeeTypeChange.bind(this);
		this.calculateTotal = this.calculateTotal.bind(this);
		this.updatePaidCategoryDefaultAmount = this.updatePaidCategoryDefaultAmount.bind(this);
	}

	componentDidMount() {
		var today = new Date();
		var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		//this.setState({today: date, paymentDate: date});
		this.setState({ today: momentDate(today, 'YYYY-MM-DD'), paymentDate: momentDate(today, 'YYYY-MM-DD'), maxDate: momentDate(today, 'YYYY-MM-DD') });
		
		this.loadVoucherInfo();
		this.loadCategory();
		this.loadAssignedFees();
		
	}
	
	
	loadAssignedFees() {
		try {

			this.setState({ showLoader: true, assignedFee: [] });
			var cid = getls('user', 'company');
			var bid = getls('currentacbusiness', 'id');
			var aid = getls('currentacyear', 'id');
			var form = new FormData();
			form.append('i__companyId', cid);
			form.append('i__businessId', bid);
			form.append('i__acyearId', aid);
			
			API.post('v1/fees/feeassign/all', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ assignedFee: res['data'].data, showLoader: false });
					//this.updatePaidCategoryDefaultAmount();
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}
	
	handleFeeTypeChange(e){
		
		this.setState({feeType: e.target.value, netAmount: '0', paidCategory: []});
		this.updatePaidCategoryDefaultAmount(e.target.value);
	}

	loadCategory() {
		try {

			this.setState({ typeLoader: true });
			var cid = getls('currentacbusiness', 'id');
			API.get('v1/fees/category/' + cid).then(res => {
				if (res['data'].status == '1') {
					this.setState({ categoryList: res['data'].data, typeLoader: false });
				}
				else {
					this.setState({ typeLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ typeLoader: false });
		}
	}

	loadVoucherInfo() {
		try {
			this.setState({ showLoader: true });
			var form = new FormData();
			form.append('type', 'receipt');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));

			API.post('v1/fees/voucher/no', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ vNo: res['data'].vno, vId: res['data'].vid, showLoader: false });
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}

	handleStudentSearch(e) {
		try {
			if (this.state.studId.length < 1) {
				notify.show('Please enter Student No', 'warning');
				return;
			}
			this.setState({ showLoader: true });
			axios.get(Api.AcademicPath + 'students/getstudentbyadmissionno/' + this.state.studId).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data[0];
					var ye = 'Others';
					if (d['semester'] == '1' || d['semester'] == '2') ye = 'I Year';
					else if (d['semester'] == '3' || d['semester'] == '4') ye = 'II Year';
					else if (d['semester'] == '5' || d['semester'] == '6') ye = 'III Year';

					this.setState({ selectedStudent: res['data'].data[0], selectedYear: ye, showLoader: false });
				}
				else {
					notify.show(res['data'].msg, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}


	handleCategoryChange(e) {
		try {
			var v = e.value;
			var d = this.state.categoryList.filter(function (obj) {
				return (obj.id == v);
			});
			this.setState({ selectedCategoryId: v, selectedAmount: d[0].amount });
		}
		catch (error) { }
	}

	handleFormSubmit(e) {
		e.preventDefault();
		try {

			if (Object.keys(this.state.paidCategory).length <= 0) {
				notify.show('Please enter amount', 'warning');
				return;
			}

			this.setState({ showLoader: true, btnSave: 'Updating..' });
			API.post('v1/fees/voucher/multisave', $("#frm_category_Payment_New").serialize()).then(res => {
				if (res['data'].status == '1') {
					notify.show(res['data'].message, 'success');
					document.getElementById("frm_category_Payment_New").reset();
					this.setState({ showLoader: false, voucherId: res['data'].insid, selectedCategoryId: '', selectedToId: '', selectedFromId: '', selectedTypeId: '', vId: res['data'].vid, vNo: res['data'].vno, payMethod: '', btnSave: 'Save Payment', selectedStudent: [], studId: '', selectedYear: 'Others', paidCategory: [], showPrint: true });
					var insId = res['data'].insid;
					//this.showPrintView(insId);
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false, btnSave: 'Save Payment' });
				}
			});
		}
		catch (error) {
			notify.show('Error', 'error');
			this.setState({ showLoader: false, btnSave: 'Save Payment' });
		}
	}

	showPrintView(id) {
		try {
			this.setState({ showLoader: true });
			var form = new FormData();
			form.append('type', 'payment');
			form.append('businessid', getls('currentacbusiness', 'id'));
			form.append('companyid', getls('user', 'company'));
			form.append('acyearid', getls('currentacyear', 'id'));
			form.append('voucherid', id);

			API.post('v1/fees/vouchers', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ printData: res['data'].data[0], showPrint: true, showLoader: false });
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}

	getStudentData(fieldName) {
		try {
			var d = this.state.selectedStudent;
			return d[fieldName];
		}
		catch (error) { }
	}
	
	updatePaidCategoryDefaultAmount(feeType){
		try{
			
			var d = this.state.paidCategory;
			var aF = this.state.assignedFee;
			d = [];
			aF.map((item,i)=>{
				var index = d.findIndex(function (obj) {
					return (obj.partyid == item.partyid);
				});
				
				if (index > -1) {
					if(feeType=='New')
						d[index]['amount'] = item.fornew;
					else if(feeType=='Regular')
						d[index]['amount'] = item.forregular;
					else d[index]['amount'] = '0';
				}
				else {
					var amt = 0;
					if(feeType=='New')
						amt = item.fornew;
					else if(feeType=='Regular')
						amt = item.forregular;
					else amt = '0';
					
					d.push({ partyid: item.partyid, amount: amt });
				}
			});
			
			this.setState({ paidCategory: d });
		}
		catch(error){
			console.log(error);
		}
	}
	
	updatePaidCategory(partyId, e) {
		try {
			var d = this.state.paidCategory;
			var index = d.findIndex(function (obj) {
				return (obj.partyid == partyId);
			});

			if (index > -1) {
				
				d[index]['amount'] = e.target.value;
			}
			else {
				d.push({ partyid: partyId, amount: e.target.value });
			}

			this.setState({ paidCategory: d });
		}
		catch (error) { }
	}

	getPaidCategoryAmount(partyId) {
		try {
			var d = this.state.paidCategory;
			
			var index = d.filter(function (obj) {
				return (obj.partyid == partyId);
			});

			return (Object.keys(index).length > 0) ? index[0]['amount'] : '';
		}
		catch (error) {
			return '';
		}
	}

	calculateTotal() {
		try {
			var total = 0;
			this.state.paidCategory.map((item, i) => {
				if (item.amount != '' && item.amount != null && item.amount != NaN) {
					total = parseFloat(total) + parseFloat(item.amount);
				}
			});
			//this.setState({netAmount: parseFloat(total).toFixed(0)});
			return parseFloat(total).toFixed(0);
		}
		catch (error) {
			return '0.00';
		}
	}
	
	getAssignedFee(partyId){
		try{
			var rv = '';
			var d = this.state.assignedFee;
			var f = d.filter(function(obj){
				return (obj.partyid==partyId);
			});
			
			if(Object.keys(f).length>0){

				if(this.state.feeType=='New')
					rv = f[0]['fornew'];
				else if(this.state.feeType=='Regular') 
					rv = f[0]['forregular'];
				else rv = '0';
				
				var paid = this.state.paidCategory;
				var index = paid.findIndex(function(obj){
					return(obj.partyid==partyId);
				});
				if(index>-1){
					paid[index]['amount'] = rv;
				}
				else{
					paid.push({partyid: partyId, amount: rv});
				}
				//this.setState({paidCategory: paid});
			}
			//this.state.netAmount = this.state.netAmount + rv;
			return rv;
		}
		catch(error){
			console.log(error);
			return '2';
		}
	}

	render() {


		var courseName = this.getStudentData('coursetype');
		if (courseName != undefined)
			if (this.getStudentData('admisiontype') == 'self') {
				courseName = courseName + " - " + this.getStudentData('coursename') + ' (SF)';
			}
			else {
				courseName = courseName + " - " + this.getStudentData('coursename');
			}

		let sno = 1;
		var billFormat = getls('currentacbusiness', 'billformat');
		
		//this.calculateTotal();
		//this.updatePaidCategoryDefaultAmount();
		
		return (
			<div>


				<LineLoader loading={this.state.showLoader} />

				<div className="pt-10" >

					<Card>
						<CardBody className="pb-0 mb-0" >
							<Row>
								<Col md={5} >
									<div className="input-group">
										<Input type="text" size="sm" placeholder="Student Adm.No" onChange={(e) => { this.setState({ studId: e.target.value }) }} value={this.state.studId} />
										<span className="input-group-addon" style={{ padding: '0px' }} >
											<Button size="sm" type="button" onClick={this.handleStudentSearch} >
												VIEW
											</Button>
										</span>
									</div>
								</Col>
								<Col md={7} >
									<div className="text-right" >
									<span>
											<Input type="radio" name="i__feeType" value="New" id="i__ftNew" onChange={this.handleFeeTypeChange} checked={this.state.feeType=='New'} />
											<label for="i__ftNew" >New</label>
										</span>
										&emsp; <span>
											<Input type="radio" name="i__feeType" value="Regular" id="i__ftRegular" onChange={this.handleFeeTypeChange} checked={this.state.feeType=='Regular'}  />
											<label for="i__ftRegular" >Regular</label>
										</span>
										&emsp; <span>
											<Input type="radio" name="i__feeType" value="Mess/Others" id="i__ftOthers" onChange={this.handleFeeTypeChange}  />
											<label for="i__ftOthers" >Mess/Others</label>
									</span>
									{/*<Input type="select" size="sm" onChange={this.handleFeeTypeChange}  >
										<option value="New" > NEW </option>
										<option value="Regular" > REGULAR </option>
										<option value="Mess/Others" > MESS / OTHERS </option>
									</Input> */}
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardBody>
							<hr className="mt-0" />

							<form action="" method="post" id="frm_category_Payment_New" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >

								<input type="hidden" name="i__userId" value={getls('user', 'id')} />
								<input type="hidden" name="i__companyId" value={getls('user', 'company')} />
								<input type="hidden" name="i__businessId" value={getls('currentacbusiness', 'id')} />
								<input type="hidden" name="breakageid" value={getls('currentacbusiness', 'breakageid')} />
								<input type="hidden" name="i__acyearId" value={getls('currentacyear', 'id')} />
								<input type="hidden" name="i__transactionType" value="receipt" />

								<input type="hidden" name="i__categoryId" value={this.state.selectedCategoryId} />
								<input type="hidden" name="i__admNo" value={this.state.studId} />
								<input type="hidden" name="i__courseId" value={this.getStudentData('course')} />


								<Row>
									<Col md={9} >
										<Row>
											<Col md={1}><Label className="required" >Year</Label></Col>
											<Col md={3} >
												<Input type="select" name="i__year" size="sm" required >
													<option value="I Year" selected={this.state.selectedYear == "I Year" ? 'selected' : ''} > I Year </option>
													<option value="II Year" selected={this.state.selectedYear == "II Year" ? 'selected' : ''} > II Year </option>
													<option value="III Year" selected={this.state.selectedYear == "III Year" ? 'selected' : ''} > III Year </option>
													<option value="Final Year" selected={this.state.selectedYear == "Final Year" ? 'selected' : ''} > Final Year </option>
													<option value="Old" selected={this.state.selectedYear == "Old" ? 'selected' : ''} > Old </option>
													<option value="Others" selected={this.state.selectedYear == "Others" ? 'selected' : ''} > Others </option>
												</Input>
											</Col>
											<Col md={1}><Label className="required" >Name</Label></Col>
											<Col md={6} >
												<Input type="text" size="sm" required name="i__name" placeholder="Name" defaultValue={this.getStudentData('name')} />
											</Col>
										</Row>
										<Row className="mt-10" >
											<Col md={1}><Label>Mobile</Label></Col>
											<Col md={3} >
												<Input type="text" size="sm" name="i__mobile" placeholder="Mobile" defaultValue={this.getStudentData('mobile')} />
											</Col>
											<Col md={1}><Label>Course</Label></Col>
											<Col md={6} >
												<Input type="text" size="sm" name="i__course" placeholder="Course" defaultValue={courseName} />
											</Col>
										</Row>

										<Row className="mt-10" >
											<Col md={1}><Label>Year/Sem</Label></Col>
											<Col md={3} >
												<Input type="text" size="sm" name="i__batch" placeholder="Year" defaultValue={this.getStudentData('batch')} />
											</Col>
											<Col md={3} >
												<Input type="text" size="sm" name="i__sem" placeholder="SEM" defaultValue={this.getStudentData('semester')} />
											</Col>
										</Row>
									</Col>

									<Col md={3} >
										<Row>
											<Col md={3} >
												<Label>No</Label>
											</Col>
											<Col md={8} className="px-0" >
												<Input type="text" readOnly size="sm" value={this.state.vNo} />
											</Col>
										</Row>
										<Row className="mt-10" >
											<Col md={3} >
												<Label>Date</Label>
											</Col>
											<Col md={8} className="px-0" >
												<Input type="date" size="sm" name="i__paymentDate" required defaultValue={this.state.paymentDate} max={this.state.maxDate} />
											</Col>
										</Row>
										<Row className="mt-10" >
											<Col md={3} >
												<Label></Label>
											</Col>
											<Col md={8} className="px-0" >
												<Input type="text" size="md" name="i__netAmount" value={this.calculateTotal()} style={{ textAlign: 'right', fontWeight: '600' }} />
											</Col>
										</Row>
										{/*<Row className="mt-10 text-right pl-10" >
										<div className="pretty p-icon p-smooth">
											<input type="checkbox" name="i__inTab" />
											<div className="state p-primary">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show all category</label>
											</div>
										</div>
										
									</Row>*/}
									</Col>

								</Row>

								<Row className="mt-20" >
									<Col md={12} >
										<Table className="tbl" >
											<thead>
												<tr>
													<th width="80" >S.No</th>
													<th>Category Name</th>
													<th align="right" width="200" style={{ textAlign: 'right' }} >Amount</th>
												</tr>
											</thead>
											<tbody>
												{this.state.categoryList.map((item, i) => {
													return <tr key={i} >
														<td align="center" >{sno++}</td>
														<td>{item.partyname}</td>
														<td>
															<Input size="sm" style={{ textAlign: 'right' }} onChange={this.updatePaidCategory.bind(this, item.partyid)}  value={this.getPaidCategoryAmount(item.partyid)} onKeyPress={decimalKeyPress} />
														</td>
													</tr>;
												})
												}
											</tbody>
										</Table>
									</Col>
								</Row>

								<Row className="mt-10" >
									<Col md={1} >

									</Col>
									<Col md={6} className="pl-45" >

									</Col>
									<Col md={5} >
										<div className="text-right mt-40 " >
											<Button type="reset" color="danger" size="sm" disabled={this.state.btnSave != 'Save Payment'} onClick={() => { this.setState({ selectedCategoryId: '', selectedToId: '', selectedFromId: '', selectedTypeId: '', selectedStudent: [], studId: '', selectedYear: 'Others' }) }} >
												Reset
										</Button>
											&nbsp;
										<Button type="submit" color="primary" size="sm" disabled={this.state.btnSave != 'Save Payment'} >
												{this.state.btnSave != 'Save Payment' ? <i className="ion-loading-a"></i> : null}
												&nbsp; {this.state.btnSave}
											</Button>
										</div>
									</Col>
								</Row>

								<input type="hidden" name="i__catList" value={JSON.stringify(this.state.paidCategory)} />

							</form>

						</CardBody>
					</Card>

				</div>

				{this.state.showPrint ? <div style={{ display: 'none' }} >
					{billFormat == 'hostel' ?
						<HostelA5BillPrint vId={this.state.voucherId} autoPrint atClose={() => { this.setState({ printData: [], showPrint: false }) }} /> :
						billFormat == 'pta' ?
							<PTAA5BillPrint vId={this.state.voucherId} autoPrint atClose={() => { this.setState({ printData: [], showPrint: false }) }} /> : ''}
				</div> : null}

			</div>
		);
	}

}

export default HostelPayment;
