import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, Chat, PageAlert, Page } from '../vibe';
import Notifications, {notify} from 'react-notify-toast';
import Logo from '../assets/images/vibe-logo.svg';
import avatar1 from '../assets/images/avatar1.png';
import nav from '../_nav';
import routes from '../views';
import {CompanyName, AccountingBusiness} from '../elements';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import {AppName,AppHeaderText,Support} from '../variables';
import {getlsItem, setlsItem, setls, getls, getAcronym} from '../config';
import {reactLocalStorage} from 'reactjs-localstorage';
import swal from 'sweetalert';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
	  redirectToLogin:false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: false,
	  showLoader: true,
    }
	this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
	  
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };


	handleLogOut(){
		swal({
			title: 'Confirm',
			text: 'Do you want to logout?',
			buttons: ["No","Yes"]
		}).then((value)=>{
			if(value){
				setlsItem('logged','no');
				setls('company',[]);
				setls('user',[]);
				setlsItem('logintoken','');
				setls('courselist',[]);
				setls('acbusiness',[]);
				setls('acyears',[]);
				setls('currentacyear', null);
				setls('currentacbusiness', null);
				
				reactLocalStorage.remove('batchlist');
				reactLocalStorage.remove('courselist');
				reactLocalStorage.remove('employeelist');
				reactLocalStorage.remove('acbusiness');
				reactLocalStorage.remove('acyears');
				reactLocalStorage.remove('logintoken');
				//window.location.href="/#/login";
				this.setState({redirectToLogin: true});
			}
		});
	}
		
  render() {


	if(getlsItem('logged')!=='yes')
	{
		return(<Redirect to="/login" />);
	}
	if(this.state.redirectToLogin)
	{
		return(<Redirect to="/login" />);
	}
	
	var role = getls('user','role');
	var acroNym = getAcronym( getls('user','name'), 2);
	
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
		  <Notifications />
          <div className="app-body">
            <SidebarNav
              nav={nav}
              logo={Logo}
              logoText={AppHeaderText}
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
			  role={role}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
			  {/* <HeaderNav />*/}
			   <NavItem>
					<AccountingBusiness />
			   </NavItem>
			   <NavItem>
				<CompanyName />
			  </NavItem>
			  <UncontrolledDropdown nav inNavbar >
				<DropdownToggle nav caret >
				  <Avatar size="small" color="blue" initials={acroNym} />
				</DropdownToggle>
				<DropdownMenu right>
				  <DropdownItem >
					<Link to="/app/settings/myprofile" > Profile </Link>
				  </DropdownItem>
				  <DropdownItem divider />
				  <DropdownItem onClick={this.handleLogOut} >Log out</DropdownItem>
				</DropdownMenu>
			  </UncontrolledDropdown>
			  
              </Header>
              <PageContent>
               
			    <Switch>
                  {routes.map((page, key) => {
					  if(page.allowed)
					  {
						  if(page.allowed.indexOf(role)>-1)
							return  <Route path={page.path} exact component={page.component} key={key} />;
					  }
					  else
						  return  <Route path={page.path} exact component={page.component} key={key} />;
                  })}
                  <Redirect from="/" to="/app/404" />
                </Switch>
				
              </PageContent>
            </Page>
          </div>
          <Footer>
            <span>Copyright © 2019 {AppName}. All rights reserved.</span>
            <span>
              <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
            </span>
            <span className="ml-auto hidden-xs">
				For Help : {Support.Mobile} (OR) {Support.Email}
            </span>
          </Footer>
		  
		  <iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
		  
          <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container>
        </div>
      </ContextProviders>
    );
  }
}

function HeaderNav() {


  return (
    <React.Fragment>
      {/* <NavItem>
       <form className="form-inline">
          <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-search" />
          </Button>
        </form>
      </NavItem>*/}
	  {/* <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          New
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Project</DropdownItem>
          <DropdownItem>User</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            Message <Badge color="primary">10</Badge>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>*/}
	  <NavItem>
		<a href="javascript:;" className="top-header-right-link" >
			Documentation
		</a>
	  </NavItem>
	   <NavItem>
		<a href="javascript:;" className="top-header-right-link text-pink" >
			Help
		</a>
	  </NavItem>
      <UncontrolledDropdown nav inNavbar >
        <DropdownToggle nav caret >
          <Avatar size="small" color="blue" initials="JS" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Profile</DropdownItem>
          <DropdownItem divider />
          <DropdownItem >Log out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}
