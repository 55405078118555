import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import {LineLoader, PageLoader} from '../../../elements';
import API from '../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls} from '../../../config';
import {reactLocalStorage} from 'reactjs-localstorage';

import Menu from './menu';

class Academics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			batchList: [],
			courseList: [],
			courseView: [],
			deptId: getls('user','deptid')
		}
		
		this.handleCourseSearch = this.handleCourseSearch.bind(this);
	}
	
	componentDidMount(){
		
		//console.log(reactLocalStorage.getObject('courselist'));
		this.loadBatchAndCourse();
	}
	
	loadBatchAndCourse(){
		try{
			
			this.setState({showLoader: true, batchList: [], courseList: [], courseView: []});
			//axios.post(Api.AcademicPath+'course/coursebydept/'+this.state.deptId).then(res=>{
				API.get('v1/course/coursebydept/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.courseOrder(res['data'].data);
					this.setState({
						batchList: res['data'].batch,
						courseList: d,
						courseView: d,
						showLoader: false
					});
					
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	courseOrder(course){
		try{
			
			var rv = [];
			
			var corder= [
				{type: 'UG', coursetype: 'aided'},
				{type: 'UG', coursetype: 'un-aided'},
				{type: 'PG', coursetype: 'aided'},
				{type: 'PG', coursetype: 'un-aided'},
				{type: 'MPhil', coursetype: 'aided'},
				{type: 'MPhil', coursetype: 'un-aided'},
				{type: 'PhD', coursetype: 'aided'},
				{type: 'PhD', coursetype: 'un-aided'},
			];
			
			corder.map((item,i)=>{
				var d = course.filter(function(obj){
					return (upperCase(obj.type)==upperCase(item.type) && obj.coursetype==item.coursetype);
				});
				
				//rv.push(d);
				d.map((m,k)=>{
					rv.push(m);
				});
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	handleCourseSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.courseList.filter(function(obj){
				return( (upperCase(obj.coursename).indexOf(v)>-1) ||
						(upperCase(obj.name).indexOf(v)>-1) );
			});
			
			this.setState({courseView: this.courseOrder(d)});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		let courseSno = 1;
		
		return(
			<div>
				
				<LineLoader loading={this.state.showLoader} />
				
				<Menu />
				
				<div className="pt-50" >
					
					<Row>
						
						<Col md={9} >
							<Card>
								<CardBody>
									<CardTitle>Course List</CardTitle>
									<div className="float-right" style={{ marginTop: '-30px' }} >
										<div className="input-group">
											<Input type="text" size="sm" placeholder="Search by Course Name.." onChange={this.handleCourseSearch} />
											<span className="input-group-addon" >
												<span className="ion-android-search"></span>
											</span>
										</div>
									</div>
									<hr style={{marginBottom: '0px'}} />
								</CardBody>
								<CardBody style={{ paddingTop: '0px' }} >
									{this.state.showLoader ? <Loader type="dot" /> : 
										<Table className="tbl" >
											<thead>
												<tr>
													<th>S.No</th>
													<th>Category</th>
													<th>Course Name</th>
													<th>Type</th>
													<th>Medium</th>
													
												</tr>
											</thead>
											<tbody>
											{this.state.courseView.map((item,i)=>{
												return <tr key={i} >
													<td>{courseSno++}</td>
													<td>{upperCase(item.deptype)}</td>
													<td>{upperCase(item.degree)} - {(item.name)}</td>
													<td>{upperCase(item.type)} - {upperCase(item.coursetype)}</td>
													<td>{capitalizeFirst(item.medium)}</td>
												</tr>;
											})
											}
											</tbody>
										</Table>
									}
								</CardBody>
							</Card>
						</Col>
						
						<Col md={3} >
							<Card>
								<CardBody>
									<CardTitle>Batch List</CardTitle>
									<hr style={{marginBottom: '0px'}} />
								</CardBody>
								<CardBody style={{ paddingTop: '0px' }} >
									{this.state.showLoader ? <Loader type="dot" /> : 
										<Table width="100" align="center" >
											<tbody>
											{this.state.batchList.map((item,i)=>{ 
												return <tr key={i} >
													<td>{item.batch} - {item.coursetype}</td>
												</tr>;
											})
											}
											</tbody>
										</Table>
									}
								</CardBody>
							</Card>
						</Col>
						
					</Row>	
					 
				  </div>
				  
			</div>
		);
	}

}

export default Academics;
