import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, Collapse, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../../variables';
import {LineLoader, PageLoader} from '../../../../elements';
import API from '../../../../config/API';
import axios from 'axios';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getSortOrder, getls} from '../../../../config';
import CKEditor from 'ckeditor4-react';
import ReactFileReader from 'react-file-reader';

import ModalFileBrowser from '../../filebrowser/ModalFileBrowser';

class Ematerials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			aboutData: '',
			activeToggleId: '',
			selectedBatch: [],
			dataList: [],
			dataView: [],
			addFrmBtn: 'Upload to server',
		}
		
		this.onContentEditorChange = this.onContentEditorChange.bind(this);		
		this.handleFiles = this.handleFiles.bind(this);		
		this.hadleFormFileRemove = this.hadleFormFileRemove.bind(this);		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
	}
	
	componentDidMount(){
		try{
			
			this.loadEMaterials();
			
			var bt = this.props.batch[0];
			this.setState({activeToggleId: bt.id, selectedBatch: bt });
			
			
		}
		catch(error){
			console.log(error);
		}		
	}
	
	loadEMaterials(){
		try{
			
			this.setState({showLoader: true, dataList: [],dataView:[]});
			
			API.get('v1/academics/ematerials/'+this.props.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader:false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ aboutData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }

		
	handleFiles(files){
		try{
			
			this.setState({
				selectedFilename: files.fileList[0]['name'],  selectedFile: files.base64, selectedUrl: ''
			});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handlefileRemove(item){
		try{
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to Delete?',
				buttons: {
					Yes: function(){
						$this.confirmFileRemove(item);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	hadleFormFileRemove(){
		try{
			
			if(!window.confirm('Do you want to remove file?')) return false;
			
			this.setState({
				selectedFile: '',
				selectedFilename: '',
				selectedUrl: ''
			});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
				
			this.setState({showLoader: true, addFrmBtn: 'Uploading...'});
			API.post('v1/academics/ematerials/save',$("#frm_Add_Ematerial").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_Add_Ematerial").reset();
					this.setState({addFrmBtn: 'Upload to server', showLoader: false, showAddSyllabus: false, selectedFilename: '', selectedUrl : '', selectedFile: '', aboutData: '' });
					this.loadEMaterials();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({addFrmBtn: 'Upload to server', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({addFrmBtn: 'Upload to server', showLoader: false});
		}
	}
	
	handlefileRemove(item){
		try{
			var $this = this;
			$.confirm({
				title: 'Confirm',
				content: 'Do you want to Delete?',
				buttons: {
					Yes: function(){
						$this.confirmFileRemove(item);
					},
					No: function(){
						
					}
				}
			});
		}
		catch(error){
			
		}
	}
	
	confirmFileRemove(item){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/academics/ematerials/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var index = this.state.dataList.findIndex(function(obj){
						return((obj.id==item.id));
					});
					var s = this.state.dataList;
					s.splice(index,1);
					this.setState({dataList: s, dataView: s, showLoader: false});
					notify.show(res['data'].message,'success');
				}
				else
				{
					this.setState({showLoader: false});
					notify.show(res['data'].message,'error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		let sno=1;
		
		return(
			<div>
				
				<LineLoader loading={this.state.showLoader} />
				
			<Card>
				<CardBody>
				<Row>
					<Col md={12} >
						<div className="text-right" >
							<Button type="button" color="secondary" size="sm" onClick={()=>{this.setState({showAddSyllabus: true})}}  >
								<span className="ion-android-add"></span> Add E-Material
							</Button>
						</div>		
						<hr />
					</Col>
				</Row>
				
				<Table className="tbl" >
					<thead>
						<tr>
							<th width="80" >S.No</th>
							<th>File Title</th>
							<th>File Link</th>
							<th>External Link</th>
							<th width="120" >Manage</th>
						</tr>
					</thead>
					<tbody>
					{this.state.dataView.map((item,i)=>{
						return <tr key={i} >
							<td>{sno++}</td>
							<td>{item.filetitle}</td>
							<td>
							{item.filepath!='' && item.filepath.length >0 ?
							<a href={Api.ImageUploadPath+item.filepath} target="_blank" >
								View File
							</a> : null}
							</td>
							<td>
							{item.externallink!='' && item.externallink.length >0 ?
							<a href={item.externallink} target="_blank" >
								View File
							</a> : null}
							</td>
							<td>
								<Button type="button" color="danger" size="sm" onClick={this.handlefileRemove.bind(this,item)} >
									<span className="ion-close-round"></span>
								</Button>
							</td>
						</tr>;
					})
					}
					</tbody>
				</Table>
				
				</CardBody>
			</Card>
				
				<Modal isOpen={this.state.showAddSyllabus} size="large" backdrop={true} >
					<ModalHeader toggle={()=>{this.setState({showAddSyllabus: false, selectedFilename: '', selectedUrl : '', selectedFile: '', aboutData: ''})}} >
						Add E-Material
					</ModalHeader>
					<ModalBody>
						<form action="" method="post" encType="multipart/form-data" id="frm_Add_Ematerial" onSubmit={this.handleFormSubmit} >			
								
							<input type="hidden" name="i__category" value="ematerials" />
							<input type="hidden" name="i__deptId" value={this.props.deptId} />
							<input type="hidden" name="i__batchId" value={this.state.selectedBatch.id} />
							<input type="hidden" name="i__userId" value={getls('user','id')} />
							
							<input type="hidden" name="i__filename" value={this.state.selectedFilename} />
							<input type="hidden" name="i__fileurl" value={this.state.selectedUrl} />
							<input type="hidden" name="i__file" value={this.state.selectedFile} />
							<input type="hidden" name="i__degreeName" value={this.props.courseInfo.coursename} />
							<input type="hidden" name="i__degreeType" value={this.props.courseInfo.type} />
							<input type="hidden" name="i__courseType" value={this.props.courseInfo.coursetype} />
							<input type="hidden" name="i__courseName" value={this.props.courseInfo.name} />
								
							<Row>
								<Col md={8} >
									<Label>File Title <span className="required">*</span></Label>
									<Input name="i__syllabusTitle" size="sm" placeholder="Title of the File" required />
								</Col>
								<Col md={4}   >
									<Label>File <span className="required"></span></Label>
									<ReactFileReader fileTypes={["*"]} base64={true} handleFiles={this.handleFiles} style={{width: '100%'}} >
										<Input name="i__syllabusFile" size="sm" placeholder="Select Your File" readOnly value={this.state.selectedFilename} />
									</ReactFileReader>
									{this.state.selectedFile!== undefined && this.state.selectedFile!=null && this.state.selectedFile.length>5 ?
									<Button type="button" size="sm" color="secondary" style={{float:'right', marginTop: '-31px' }} onClick={this.hadleFormFileRemove} >
										<span className="ion-close"></span>
									</Button> : null}
								</Col>
								
								<Col md={8} className="mt-20"  >
									<Label>
										External URL &emsp;
										<div style={{position: 'absolute', top: '0px', left: '175px' }} >
												<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
												>
													<Button type="button" size="xs" color="info" onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
														Open File Manager
													</Button>
												</ModalFileBrowser>
											</div>
									</Label>
									<Input name="i__externalUrl" size="sm" placeholder="Paste your External Url here" />
								</Col>
								<Col md={4} className="mt-20"  >
									<Label>File Type (or) External Url Type</Label>
									<Input type="select" name="i__externalType" size="sm" >
										<option value=""> </option>
										<option value="image"> Image </option>
										<option value="video"> Video </option>
										<option value="audio"> Audio </option>
									</Input>
								</Col>
								<Col md={12} className="mt-20" >
										<Label>E-Material Content <span className="required"></span></Label>
										<CKEditor 
										name='editor_Ematerial_add' 
										config={{ extraPlugins: 'print,font', 
										height: '300', 
										startupShowBorders: 'false', 
										allowedContent: true, 
										extraAllowedContent: '*(*);*{*}' }} 
										data={this.state.aboutData} 
										onChange={this.onContentEditorChange} />
									</Col>
								<Col md={12}  className="mt-10"  >
									<hr />
									<div className="text-center" >
										<Button type="submit" size="sm" color="success" disabled={this.state.addFrmBtn!='Upload to server'} >
											{this.state.addFrmBtn=='Upload to server' ? 
												<span className="ion-arrow-up-a" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.addFrmBtn}
										</Button>
										&nbsp;
										<Button type="reset" color="danger" size="sm" disabled={this.state.addFrmBtn!='Upload to server'} onClick={()=>{this.setState({showAddSyllabus:false})}}>
											<span className="ion-android-close"></span> Close the Form
										</Button>
									</div>
								</Col>
							</Row>
							
							<input type="hidden" name="i__about_Pagecontent" value={this.state.aboutData} />
							
						</form>
					</ModalBody>
				</Modal>
				  
			</div>
		);
	}

}

export default Ematerials;
