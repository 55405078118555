import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, upperCase, capitalizeFirst, groupByMultiple } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';

import Menu from './Menu';

class Subject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showEditSubject: false,
			dataList: [],
			dataView: [],
			editData: [],
		}
	}

	componentDidMount() {
		this.loadSubject();
	}

	loadSubject() {
		try {

			this.setState({ showLoader: true });
			var userid = getls('user', 'id');
			API.get('v1/getpsosubject/' + userid).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ dataList: d, dataView: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	handleEdit(item, id, e) {
		try {

			this.setState({
				editData: item,
				editId: id,
				showEditSubject: true
			});
		}
		catch (error) {

		}
	}

	handleRemove(item, index) {
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Subject?',
			buttons: {
				Yes: function () {
					$this.confirmRemove(item, index);
				},
				No: function () {

				}
			}
		});
	}

	confirmRemove(item, i) {
		try {

			this.setState({ showLoader: true });
			API.delete('v1/subject/remove/' + item.id).then(res => {
				if (res['data'].status == '1') {
					var d = this.state.dataList;
					var index = d.findIndex(function (obj) {
						return (obj.id == item.id);
					});
					d.splice(index, 1);
					notify.show(res['data'].message, 'success');
					this.setState({ showLoader: false, dataList: d, dataView: d });
				}
				else {
					notify.show(res['data'].message, 'error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
			alert('Unexpected Error');
		}
	}

	render() {

		if (this.state.showEditSubject) {
			return (<Redirect to={"/app/subject/edit/" + this.state.editId} />);
		}

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >
					<Table>
						<thead>
							<tr>
								<th>S.No</th>
								<th>Subject Code</th>
								<th>Subject Name</th>
								<th>Manage</th>
							</tr>
						</thead>
						<tbody>
							{this.state.dataView.map((item, i) =>
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{item.subcode}</td>
									<td>{item.subname}</td>
									<td align="center" >
										<Button type="button" size="sm" color="primary" title="Edit" onClick={this.handleEdit.bind(this, item, item.id)} >
											<span className="ion-edit"></span>
										</Button>
										&nbsp;
										<Button type="button" size="sm" color="danger" title="Remove" onClick={this.handleRemove.bind(this, item, i)} >
											<span className="ion-close-round"></span>
										</Button>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

			</div>
		);
	}

}

export default Subject;
