import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../../vibe/';
import {AppName, Version} from '../../../../variables';
import API from '../../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../../config';

import BG from '../../../../assets/images/print_bg.png';

const styles = {
	tdStyle: {
		borderBottom: '1px solid #f3f3f3',
		padding: '8px',
		cursor: 'pointer',
	}
}

class Print extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  btnText: 'Save',
		}
	}
	
	componentDidMount(){
		
		if(this.props.autoPrint)
			this.printDocument("print_att_certificate");
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame_att_cer").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	getValue(fieldName){
		try{
			var d = this.props.printData;
			return d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
	  return (
	  <div>
				<div id="print_att_certificate" >
					<div style={{width:'790px',height:'510px', background:'#fff' }} >
						<div style={{marginTop: '150px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px', fontWeight: '800'}} >
							{this.getValue('name')}
						</div>
						<div style={{marginTop: '180px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px'}} >
							{this.getValue('classname')}
						</div>
						<div style={{marginTop: '225px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px'}} >
							{this.getValue('courseduration')}
						</div>
						<div style={{marginTop: '280px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px'}} >
							{this.getValue('totalworkingday')}
						</div>
						<div style={{marginTop: '340px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px'}} >
							{this.getValue('attday')}
						</div>
						<div style={{marginTop: '376px', textTransform: 'uppercase', position: 'absolute', marginLeft: '370px', fontSize: '14px'}} >
							{this.getValue('percent')}
						</div>
						<div style={{marginTop: '438px', textTransform: 'uppercase', position: 'absolute', marginLeft: '30px', fontSize: '14px'}} >
							{this.getValue('applydate')}
						</div>
					</div>
				</div>
				
				<iframe name="print_frame_att_cer" id="print_frame_att_cer" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
	  </div>
	  );
	}

}

export default Print;
