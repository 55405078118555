import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'reactstrap';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	  return (
		<div className="page-sub-nav page-sub-nav-fixed">
		  <Nav pills>
			<NavItem>
				<NavLink to="/app/academic" exact >
					Course
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink to="/app/academic/staffs" exact >
					Staffs
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink to="/app/academic/batchlist" exact >
					Academic Years
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/app/academic/departments" exact >
					Departments
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to="/app/academic/coursecategory"  >
					Course Category
				</NavLink>
			</NavItem>	
		  </Nav>
		</div>
	  );
	}

}
export default Menu;
